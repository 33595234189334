import { Spinner } from '@bindystreet/bindystreet.kit.react';
import { IAnalytics } from 'Colugo/interfaces/common/IAnalytics';
import AnalyticsOperations from 'Colugo/operations/analytics/AnalyticsOperations';
import AnalyticsSnapshot from 'components/dashboard/AnalyticsSnapshot';
import ImprovedFlyoutMenu, {
  FlyoutMenuItem
} from 'components/shared/ImprovedFlyoutMenu';
import { ManagerContext } from 'provider/manager/managerProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import Analytics from './Analytics';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import {
  dateRangeFlyoutMenuItems,
  getUpdateDiff,
  analyticsErrorComponent,
  renderRetryAnalyticsComponent
} from 'utility/analytics/analyticsUtils';

const analyticsOperations = container.resolve(AnalyticsOperations);

type Props = {
  providedListing?: IListing;
  providedListingAnalytics?: IAnalytics;
  isLoadigProvidedListingAnalytics?: boolean;
  isErrorPovidedListingAnalytics?: boolean;
  revalidateProvidedListingAnalyticsAsync?: () => Promise<boolean>;
  isValidatingProvidedListingAnalytics?: boolean;
};

function BusinessAnalytics(props: Props) {
  const {
    providedListing,
    providedListingAnalytics,
    isLoadigProvidedListingAnalytics,
    isErrorPovidedListingAnalytics,
    revalidateProvidedListingAnalyticsAsync,
    isValidatingProvidedListingAnalytics
  } = props;
  const [selectedNOfDaysBusiness, setSelectedNOfDaysBusiness] =
    useState<FlyoutMenuItem>(dateRangeFlyoutMenuItems[0]);
  const [selectedNOfDaysPromotion, setSelectedNOfDaysPromotion] =
    useState<FlyoutMenuItem>(dateRangeFlyoutMenuItems[0]);
  const [businessAnalytics, setBusinessAnalytics] = useState<
    IAnalytics | undefined
  >();
  const [promotionAnalytics, setPromotionAnalytics] = useState<
    IAnalytics | undefined
  >();
  const [isBusinessLoading, setIsBusinessLoading] = useState<boolean>(false);
  const [isPromotionLoading, setIsPromotionLoading] = useState<boolean>(false);

  const {
    isPremiumOrPlusBusiness: managerContextIsPremiumOrPlusBusiness,
    listingAnalytics,
    isErrorListingAnalytics,
    isLoadingListingAnalytics,
    isValidatingListingAnalytics,
    revalidateListingAnalyticsAsync
  } = useContext(ManagerContext);

  const { listing: managerContextListing } = useContext(ManagerContext);

  const listing = providedListing || managerContextListing;
  const analytics = providedListing
    ? providedListingAnalytics
    : listingAnalytics;

  const isLoadingAnalytics = providedListing
    ? isLoadigProvidedListingAnalytics
    : isLoadingListingAnalytics;
  const isErrorAnalytics = providedListing
    ? isErrorPovidedListingAnalytics
    : isErrorListingAnalytics;
  const isValidatingAnalytics = providedListing
    ? isValidatingProvidedListingAnalytics
    : isValidatingListingAnalytics;
  const revalidateAnalyticsAsync = providedListing
    ? revalidateProvidedListingAnalyticsAsync
    : revalidateListingAnalyticsAsync;

  const isPremiumOrPlusBusiness =
    !!providedListing || managerContextIsPremiumOrPlusBusiness;

  const promotionFlyoutMenuItems: FlyoutMenuItem[] =
    listing?.promotions?.map(
      (p) =>
        ({
          label: p.title,
          value: p.id
        } as FlyoutMenuItem)
    ) || [];

  const [selectedPromotion, setSelectedPromotion] = useState<FlyoutMenuItem>(
    promotionFlyoutMenuItems[0]
  );

  async function getBusinessAnalyticsAsync(fmi: FlyoutMenuItem) {
    setSelectedNOfDaysBusiness(fmi);
    setIsBusinessLoading(true);
    const { data: localAnalytics, error } =
      await analyticsOperations.getAnalyticsAsync(listing?.id, fmi.value);
    setIsBusinessLoading(false);
    if (error || !localAnalytics) {
      toast.error('Unable to fetch analytics, please refresh and try again.');
      return;
    }
    setBusinessAnalytics(localAnalytics);
  }

  const getPromotionAnalyticsAsync = useCallback(
    async (fmiPromotion: FlyoutMenuItem, fmiDate: FlyoutMenuItem) => {
      if (!fmiPromotion?.value) {
        return;
      }
      setIsPromotionLoading(true);
      const { data: localAnalytics, error } =
        await analyticsOperations.getAnalyticsAsync(
          fmiPromotion.value,
          fmiDate.value
        );
      setIsPromotionLoading(false);
      if (error || !localAnalytics) {
        toast.error('Unable to fetch analytics, please try again.');
        return;
      }
      setPromotionAnalytics(localAnalytics);
    },
    []
  );

  useEffect(() => {
    getPromotionAnalyticsAsync(selectedPromotion, selectedNOfDaysPromotion);
  }, [selectedPromotion, selectedNOfDaysPromotion, getPromotionAnalyticsAsync]);

  const isLoadingAnalyticsSnapshot =
    isLoadingAnalytics || isValidatingAnalytics;

  const businessDiff = getUpdateDiff(
    businessAnalytics?.timeComputed || analytics?.timeComputed
  );
  const promotionsDiff = getUpdateDiff(promotionAnalytics?.timeComputed);

  const topBusinessComponent = (
    <div className="flex flex-row py-4 px-6 border-b border-outline font-inter">
      <div className="text-xl font-bold mt-3">Overall Analytics</div>
      <div className="flex items-center ml-2 mt-2">
        {isBusinessLoading ? (
          <div className="mr-auto ml-3 pt-3">
            <Spinner />
          </div>
        ) : (
          <div className="flex gap-2">
            <span className="text-sm text-onSurfaceVariant">
              {businessDiff}
            </span>
            {isErrorAnalytics &&
              !isValidatingListingAnalytics &&
              renderRetryAnalyticsComponent(revalidateAnalyticsAsync!)}
          </div>
        )}
      </div>

      <div className="flex-grow" />
      <ImprovedFlyoutMenu
        flyoutMenuItems={dateRangeFlyoutMenuItems}
        onChange={getBusinessAnalyticsAsync}
        selectedItem={selectedNOfDaysBusiness}
        wrapperClassName="rounded-lg"
      />
    </div>
  );

  const topPromotionComponent = (
    <div className="flex flex-row py-4 px-6 border-b border-outline font-inter">
      <div className="text-xl font-bold mt-3">Promotions</div>
      {isPromotionLoading ? (
        <div className="mr-auto ml-3 pt-3">
          <Spinner />
        </div>
      ) : (
        <span className="mt-4 ml-4 pt-0.5 text-sm text-onSurfaceVariant">
          {promotionsDiff}
        </span>
      )}
      <div className="flex-grow" />
      <ImprovedFlyoutMenu
        flyoutMenuItems={promotionFlyoutMenuItems}
        onChange={(fmiPromotion) => setSelectedPromotion(fmiPromotion)}
        selectedItem={selectedPromotion || { label: 'Select a promotion' }}
        wrapperClassName="rounded-lg"
      />
      <div className="w-4" />
      <ImprovedFlyoutMenu
        flyoutMenuItems={dateRangeFlyoutMenuItems}
        onChange={(fmiDate) => setSelectedNOfDaysPromotion(fmiDate)}
        selectedItem={selectedNOfDaysPromotion}
        wrapperClassName="rounded-lg"
      />
    </div>
  );

  return (
    <div className="flex flex-row w-full overflow-y-auto">
      <div className="mt-10 mx-auto">
        <div className="text-2xl font-bold">Analytics</div>
        {isErrorAnalytics &&
          !isValidatingListingAnalytics &&
          analyticsErrorComponent}
        {listing && (
          <AnalyticsSnapshot
            analytics={businessAnalytics ?? analytics}
            topComponent={topBusinessComponent}
            nOfDays={Number(selectedNOfDaysBusiness.value)}
            isPremiumOrPlusBusiness={
              !!providedListing || isPremiumOrPlusBusiness
            }
            listingId={listing.id}
            isLoading={isLoadingAnalyticsSnapshot}
          />
        )}
        <Analytics
          entity={listing}
          title={'Business Listing'}
          isPremiumOrPlusBusiness={isPremiumOrPlusBusiness}
        />
        {listing && listing.events && (
          <Analytics
            events={listing.events}
            title={'Events'}
            isPremiumOrPlusBusiness={isPremiumOrPlusBusiness}
          />
        )}
        {selectedPromotion && listing && (
          <AnalyticsSnapshot
            analytics={promotionAnalytics}
            topComponent={topPromotionComponent}
            nOfDays={Number(selectedNOfDaysPromotion.value)}
            isWithCollected={false}
            isPremiumOrPlusBusiness={isPremiumOrPlusBusiness}
            listingId={listing.id}
            showUpgradeOption={false}
          />
        )}
        <div className="h-10" />
      </div>
    </div>
  );
}

export default BusinessAnalytics;
