import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import emailBounce from 'animations/emailBounce.json';
import DashboardHeader from 'components/shared/DashboardHeader';
import lottie, { AnimationItem } from 'lottie-web';
import { useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  type: 'Request' | 'Claim';
};

export default function ThankYou(props: Props) {
  const { type } = props;
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const animationInstance = useRef<AnimationItem | null>(null);

  //NOTE: This ensures that the first frame of the animation displays
  //once the animation completes, rather than an empty white space
  useLayoutEffect(() => {
    animationInstance.current = lottie.loadAnimation({
      container: containerRef.current!,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: emailBounce,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    });

    animationInstance.current?.addEventListener('complete', () => {
      animationInstance.current?.goToAndStop(0, true);
    });

    return () => {
      animationInstance.current?.removeEventListener('complete');
      animationInstance.current?.destroy();
    };
  }, []);

  return (
    <div>
      <DashboardHeader isDropdownVisible={false} />
      <div className="w-full flex flex-row">
        <div
          className="flex flex-col h-full text-center font-inter mx-auto mt-24"
          style={{ width: '1300px' }}
        >
          <div className="flex flex-row w-full">
            <div
              ref={containerRef}
              style={{
                width: '100%',
                height: '100%'
              }}
            />
          </div>
          <div className="mx-auto flex flex-col" style={{ width: '660px' }}>
            <span className="font-bold text-2xl mt-4">
              Thank you for submitting your business {type}, we'll get back to
              you by email as soon as possible.
            </span>
            <span className="mt-4">
              In the meantime, discover other ways in which we can work together
              and further promote your business{' '}
              <a
                className="text-primary hover:underline cursor-pointer"
                href="https://www.ember.london/business"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            </span>
            <div
              className="cursor-pointer bg-primary p-2 text-white rounded-lg mx-auto flex flex-row font-semibold mt-4"
              onClick={() => navigate(PuffinRoutes.ManageBusiness)}
              style={{ width: '164px' }}
            >
              <span className="mx-auto">Return home</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
