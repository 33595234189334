import { IIoiValidationError } from 'Colugo/interfaces/IIoiValidationError';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { BlockEntityType } from 'Colugo/interfaces/lobby/discover/enums/EntityType';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import VideoOperations from 'Colugo/operations/video/VideoOperations';
import { ManagerContext } from 'provider/manager/managerProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext } from 'react';
import { useAsyncDebounce } from 'react-table';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { categories } from 'utility/constants/constants';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import DangerZone from './DangerZone';
import GeneralSettings from './GeneralSettings';
import ImageSettings from './ImageSettings';
import VideoSettings from './VideoSettings';

const listingOperations = container.resolve(ListingOperations);
const videoOperations = container.resolve(VideoOperations);

type Props = {
  localListing: IListing;
  setLocalListing: (localListing: IListing) => void;
  updateListingAsync: (updatedListing: IListing) => Promise<boolean>;
  validationErrors: IIoiValidationError[];
  updateIsActiveForInvalidListingAsync: () => Promise<boolean>;
  mutate: (listing: IListing, revalidate?: boolean) => void;
};

function GeneralTabContainer(props: Props) {
  const {
    localListing,
    setLocalListing,
    updateListingAsync,
    validationErrors,
    updateIsActiveForInvalidListingAsync,
    mutate
  } = props;
  const { isSuperAdmin } = useContext(UserContext);
  const { mutateListing, isPremiumOrPlusBusiness } = useContext(ManagerContext);
  const { errorToast } = useErrorToast();

  const debouncedAddCategoryAsync = useAsyncDebounce(
    async (localListingId: string, categoryId: string) => {
      return await listingOperations.addCategoryAsync(
        localListingId,
        categoryId
      );
    },
    200
  );

  const debouncedRemoveCategoryAsync = useAsyncDebounce(
    async (localListingId: string, categoryId: string) => {
      return await listingOperations.removeCategoryAsync(
        localListingId,
        categoryId
      );
    },
    200
  );

  async function handleAddCategoryAsync(categoryId: string) {
    const categoryToAdd = categories.find((c) => c.id === categoryId);

    if (!categoryToAdd) {
      toast.warn('No category selected');
      return false;
    }
    const updatedListing = {
      ...localListing,
      categories: [...(localListing?.categories || []), categoryToAdd]
    };
    setLocalListing(updatedListing);

    const { error } = await debouncedAddCategoryAsync(
      localListing.id!,
      categoryId
    );

    if (error) {
      errorToast('Failed to add category, try again later');
      setLocalListing(localListing);
      return false;
    }

    mutate(updatedListing);
    mutateListing(updatedListing);
    return true;
  }

  async function handleRemoveCategoryAsync(index: number, categoryId: string) {
    if (!categories || !localListing.categories) {
      toast.warn('No category selected');
      return;
    }
    const categoryToRemove = categories[index];
    if (!categoryToRemove) {
      errorToast('No category found, please refresh.');
      return;
    }

    const newCategories = localListing.categories!.filter(
      (c) => c.id !== categoryToRemove.id
    );
    let updatedListing = {
      ...localListing,
      categories: newCategories
    };
    setLocalListing(updatedListing);

    const { error } = await debouncedRemoveCategoryAsync(
      localListing.id!,
      categoryId
    );

    if (error) {
      errorToast('Failed to remove category, try again later');
      setLocalListing(localListing);
      return;
    }

    if (newCategories.length === 0) {
      updatedListing = {
        ...localListing,
        isActive: false,
        categories: newCategories
      };
      setLocalListing(updatedListing);
      await updateIsActiveForInvalidListingAsync();
    }
    mutate(updatedListing);
    mutateListing(updatedListing);
  }

  async function addListingImageAsync(imageUrl: string) {
    let newListingImages: string[];
    if (!imageUrl) {
      errorToast('No Image was selected, Please try again');
      return;
    }
    if (localListing.images) {
      newListingImages = [...localListing.images, imageUrl];
    } else {
      newListingImages = [imageUrl];
    }
    setLocalListing({ ...localListing, images: newListingImages });
    const isSuccessful = await updateListingAsync({
      ...localListing,
      images: newListingImages
    });

    if (!isSuccessful) {
      setLocalListing(localListing);
    }
  }

  async function updateListingImageOrderAsync(images: string[]) {
    const isSuccessful = await updateListingAsync({
      ...localListing,
      images
    });
    if (!isSuccessful) {
      setLocalListing(localListing);
    }
  }

  async function deleteImageAsync(index: number) {
    const newListingImages =
      localListing.images?.filter((_, i) => i !== index) || [];
    setLocalListing({ ...localListing, images: newListingImages });
    const isSuccessful = await updateListingAsync({
      ...localListing,
      images: newListingImages
    });
    if (!isSuccessful) {
      setLocalListing(localListing);
    }
  }

  async function deleteVideoAsync() {
    const videoId = localListing?.video?.id;
    if (!videoId) {
      errorToast('No video to delete, Please try again');
      return;
    }

    const { error } = await videoOperations.deleteAsync(videoId);
    if (error) {
      errorToast('Failed to delete video, Please try again');
      return;
    }

    const updatedListing = { ...localListing, video: undefined };
    setLocalListing(updatedListing);
    const isSuccessful = await updateListingAsync(updatedListing);

    mutateListing(updatedListing);
    if (!isSuccessful) {
      setLocalListing(localListing);
    }
  }

  return (
    <div className="flex flex-col ml-auto mr-auto" style={{ width: '80vw' }}>
      <div className="h-auto pb-10 w-full mt-8 flex flex-col items-start px-8 border-b-2 border-gray">
        <GeneralSettings
          localListing={localListing}
          setLocalListing={setLocalListing}
          handleRemoveCategoryAsync={handleRemoveCategoryAsync}
          handleAddCategoryAsync={handleAddCategoryAsync}
          updateListingAsync={updateListingAsync}
          validationErrors={validationErrors}
        />
      </div>
      <div className="h-auto pb-10 w-full mt-8 flex flex-col items-start px-8 border-b-2 border-gray">
        <VideoSettings
          localEntity={localListing}
          mutateEntityVideo={(video) =>
            setLocalListing({ ...localListing, video: video })
          }
          deleteVideoAsync={deleteVideoAsync}
          entityType={BlockEntityType.Listing}
          hasAccess={isSuperAdmin || isPremiumOrPlusBusiness}
        />
      </div>
      <div className="h-auto pb-10 w-full mt-8 flex flex-col items-start px-8 border-b-2 border-gray">
        <ImageSettings
          localEntity={localListing}
          addImageAsync={addListingImageAsync}
          updateImagesOrderAsync={updateListingImageOrderAsync}
          deleteImageAsync={deleteImageAsync}
          validationErrors={validationErrors}
          label="Listings"
        />
      </div>

      {isSuperAdmin && (
        <div className="h-auto pb-8 w-full mt-4 flex flex-col items-start px-8">
          <DangerZone
            localEntity={localListing}
            label="Business"
            onDeleteAsync={async () =>
              await listingOperations.deleteAsync(localListing.id!)
            }
          />
        </div>
      )}
    </div>
  );
}

export default GeneralTabContainer;
