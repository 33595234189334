import {
  ClaimRequestStatus,
  ClaimStatusText
} from 'Colugo/interfaces/business/IBaseListingRequest';
import {
  IListing,
  IListingFromSearch
} from 'Colugo/interfaces/listing/IListing';
import { ReactComponent as SmallFadedAvatar } from 'images/smallAvatarCircle.svg';
import { ReactComponent as Trash } from 'images/trash.svg';
import { UserContext } from 'provider/user/userProvider';
import { useContext } from 'react';
import { getEmailLink } from 'utility/constants/constants';
import { formatAddress } from 'utility/general/formatHelpers';
import EntityImage from './EntityImage';
import ListingAddressOrPlan from './ListingAddressOrPlan';
import { SubscriptionType } from 'Colugo/interfaces/subscriptions/ISubscription';

export enum ListingCardType {
  None = 'None',
  Search = 'Search',
  ClaimRequest = 'ClaimRequest',
  BusinessRequest = 'BusinessRequest'
}

type Props = {
  listing: IListing | IListingFromSearch;
  isBusinessCard?: boolean;
  styles?: React.CSSProperties;
  containerClassNames?: string;
  type?: ListingCardType;
  claimRequestStatus?: ClaimRequestStatus;
  rejectionReason?: string;
  isSelected?: boolean;
  onClickBusinessBtn?: () => void;
  removeClaimOrBusinessRequest?: () => void;
  shouldShowBusinessName?: boolean;
  isManageMode?: boolean;
};

function ListingCard(props: Props) {
  const {
    listing,
    isBusinessCard,
    styles,
    containerClassNames,
    type = 'None',
    claimRequestStatus,
    rejectionReason,
    isSelected,
    onClickBusinessBtn,
    removeClaimOrBusinessRequest,
    shouldShowBusinessName = true,
    isManageMode
  } = props;

  const { isSuperAdmin } = useContext(UserContext);

  function getClaimBtn() {
    let claimBtnClassname = '';
    let claimBtnText: string | ClaimStatusText = '';
    let claimBtnTextClassname = '';
    let shouldClaim = false;

    switch ((listing as IListingFromSearch).claimStatusText) {
      case ClaimStatusText.Owned:
        claimBtnClassname = 'bg-greenContainer cursor-default';
        claimBtnText = 'Owned';
        claimBtnTextClassname = 'text-green';
        break;
      case ClaimStatusText.AlreadyClaimed:
        claimBtnClassname = 'bg-surfaceDim cursor-default';
        claimBtnText = 'Claimed';
        claimBtnTextClassname = 'text-onSurface';
        break;
      case ClaimStatusText.Pending:
        claimBtnClassname = 'bg-surfaceDim cursor-default';
        claimBtnText = 'Pending';
        claimBtnTextClassname = 'text-onSurface';
        break;
      case ClaimStatusText.Claim:
      default:
        shouldClaim = true;
        claimBtnClassname = 'bg-primary hover:opacity-80 cursor-pointer';
        claimBtnText = 'Claim';
        claimBtnTextClassname = 'text-white';
        break;
    }
    return (
      <button
        name="Claim"
        onClick={() => shouldClaim && onClickBusinessBtn?.()}
        className={`rounded-lg px-4 py-2 ${claimBtnClassname}`}
      >
        <p className={`text-white font-bold ${claimBtnTextClassname}`}>
          {claimBtnText}
        </p>
      </button>
    );
  }

  function getRequestStatusBtn() {
    let claimBtnClassname = '';
    let claimBtnText: string | ClaimStatusText = '';
    let claimBtnTextClassname = '';
    let shouldDisplaySelect = false;

    switch (claimRequestStatus) {
      case ClaimRequestStatus.Approved:
        claimBtnClassname = 'bg-greenContainer cursor-default';
        claimBtnText = 'Approved';
        claimBtnTextClassname = 'text-green';
        break;
      case ClaimRequestStatus.Rejected:
        claimBtnClassname = 'bg-redContainer cursor-default';
        claimBtnText = 'More Information Required';
        claimBtnTextClassname = 'text-red';
        break;
      case ClaimRequestStatus.None:
      case ClaimRequestStatus.Requested:
        claimBtnClassname = 'bg-surfaceDim border-outline cursor-default';
        claimBtnText = 'Pending';
        claimBtnTextClassname = 'text-onSurface';
        break;
      default:
        shouldDisplaySelect = true;
        claimBtnClassname = `${
          isSelected ? 'bg-grayBorder' : 'bg-onSurface'
        } border-outline cursor-pointer`;
        claimBtnText = isSelected ? 'Selected' : 'Select';
        claimBtnTextClassname = `${
          isSelected ? 'text-onSurface' : 'text-white'
        }`;
        break;
    }

    return (
      <button
        name="RequestStatus"
        className={`rounded-lg px-4 py-2  ${claimBtnClassname}`}
        onClick={() => shouldDisplaySelect && onClickBusinessBtn?.()}
      >
        <p
          className={`text-white font-bold font-nunito ${claimBtnTextClassname}`}
        >
          {claimBtnText}
        </p>
      </button>
    );
  }

  function getBusinessBtn() {
    switch (type) {
      case ListingCardType.BusinessRequest:
      case ListingCardType.ClaimRequest:
        return getRequestStatusBtn();
      case ListingCardType.Search:
      default:
        return getClaimBtn();
    }
  }
  function renderListingBottom() {
    if (rejectionReason) {
      return (
        <ListingBottom
          mainText={rejectionReason}
          contactUs={() =>
            (window.location.href = getEmailLink(
              'RE:Claim Submission Rejected'
            ))
          }
          removeClaimOrRequest={removeClaimOrBusinessRequest}
          showDelete
        />
      );
    }
    if (
      (listing as IListingFromSearch).claimStatusText ===
      ClaimStatusText.AlreadyClaimed
    ) {
      return (
        <ListingBottom
          mainText={
            'This business has already been claimed on our platform. To dispute this, please contact us.'
          }
          contactUs={() =>
            (window.location.href = getEmailLink('RE:Claimed business dispute'))
          }
        />
      );
    }
  }

  const updatedAt = listing.updatedAt ?? listing.createdAt;
  const businessOwnerName = listing.businessOwner
    ? `${listing.businessOwner.firstName || ''} ${
        listing.businessOwner.lastName || ''
      }`
    : 'Ember Business';
  const hasBottom =
    rejectionReason ||
    ((listing as IListingFromSearch).claimStatusText ===
      ClaimStatusText.AlreadyClaimed &&
      !isSuperAdmin);

  return (
    <div>
      <div
        className={`flex flex-row px-4 py-4 bg-white border border-grayBorder hover:shadow-lg items-center transition-all duration-200 ${
          hasBottom ? 'rounded-t-lg' : 'rounded-xl'
        }
        ${containerClassNames}`}
        style={{ width: '452px', ...styles }}
      >
        <div className="flex">
          <div className="mr-4">
            <EntityImage
              entity={listing}
              imageProportion={isSuperAdmin ? 92 : 80}
            />
          </div>
          <div
            className="flex flex-col justify-center"
            style={{
              width: type === ListingCardType.None ? '322px' : '100%'
            }}
          >
            {isSuperAdmin && shouldShowBusinessName && (
              <div className="flex gap-1 items-center">
                <SmallFadedAvatar height={16} />
                <p className="text-onSurfaceVariant font-normal text-sm">
                  {businessOwnerName}
                </p>
              </div>
            )}
            <div className="font-bold text-lg font-inter truncate text-onSurface w-full">
              {listing.name}
            </div>
            <div className="text-base font-normal mb-1 font-nunito text-secondaryText truncate">
              {listing.address && formatAddress(listing.address)}
            </div>
            <ListingAddressOrPlan
              listing={listing}
              updatedAt={updatedAt}
              isManageMode={isManageMode}
              subscriptionType={
                listing.subscription?.subscriptionType ??
                SubscriptionType.Essential
              }
            />
          </div>
        </div>
        <div className="justify-items-end">
          {isBusinessCard && getBusinessBtn()}
        </div>
      </div>
      {hasBottom && renderListingBottom()}
    </div>
  );
}

export default ListingCard;

type ListingBottomProps = {
  mainText: string;
  showDelete?: boolean;
  contactUs: () => void;
  removeClaimOrRequest?: () => void;
};
export const ListingBottom = ({
  mainText,
  contactUs,
  showDelete,
  removeClaimOrRequest
}: ListingBottomProps) => {
  return (
    <div className="flex items-center justify-between bg-white -mt-3 mb-3 rounded-b-xl p-4 border-grayBorder border border-t-0">
      <p className="text-onSurface max-w-xl text-sm">{mainText}</p>
      <div className="items-center flex gap-4">
        <button
          name="contact"
          className={`rounded-lg px-4 py-2 hover:opacity-80 border border-purple bg-transparent h-10`}
          onClick={contactUs}
        >
          <p className={`text-onSurface font-bold`}>Contact Us</p>
        </button>
        {showDelete && (
          <button
            className="border border-primary h-10 w-10 rounded-lg items-center justify-center flex"
            onClick={removeClaimOrRequest}
          >
            <Trash />
          </button>
        )}
      </div>
    </div>
  );
};
