import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { useReqListSuggestionsAsync } from 'Colugo/operations/suggestions/SuggestionOperations';
import SuggestionBucketsComponent from './SuggestionBucketsComponent';

function SuggestionBucketsContainer() {
  const {
    data: suggestionBuckets,
    isLoading: isSuggestionsLoading,
    isError: isSuggestionsError
  } = useReqListSuggestionsAsync();

  if (isSuggestionsLoading) {
    return (
      <div className="absolute w-full h-screen flex flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isSuggestionsError) {
    return (
      <ErrorPage>
        <span>{'Unable to load suggestions from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <SuggestionBucketsComponent
      suggestionBuckets={suggestionBuckets ?? []}
      isListingSpecific={false}
    />
  );
}

export default SuggestionBucketsContainer;
