import { IVideo } from 'Colugo/interfaces/video/IVideo';
import { requestResponse } from 'Colugo/provider/HttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';
import { AuthOrigin } from 'Colugo/interfaces/identity/IAuthOrigin';

@scoped(Lifecycle.ContainerScoped)
class VideoOperations extends AbstractOperations {
  protected basePath: string = 'video';

  public async createAsync(
    video: IVideo,
    authOrigin: AuthOrigin = AuthOrigin.Puffin
  ): requestResponse<IVideo> {
    return await this.executePost(`?authOrigin=${authOrigin}`, video, {}, true);
  }

  public async updateAsync(
    id: string,
    video: IVideo,
    authOrigin: AuthOrigin = AuthOrigin.Puffin
  ): requestResponse {
    return await this.executePut(
      `${id}?authOrigin=${authOrigin}`,
      video,
      {},
      true
    );
  }

  public async listAsync(): requestResponse<IVideo[]> {
    return await this.executeGet('list', {}, true);
  }

  public async deleteAsync(
    id: string,
    authOrigin: AuthOrigin = AuthOrigin.Puffin
  ) {
    return await this.executeDelete(`${id}?authOrigin=${authOrigin}`);
  }
}

const useReqListVideosAsync = (
  shouldFetchVideos?: boolean
): swrRequestResponse<IVideo[]> => {
  return useGetHttpClient(
    shouldFetchVideos ? `video/list` : null,
    {
      revalidateOnFocus: false
    },
    false
  );
};

export { useReqListVideosAsync };
export default VideoOperations;
