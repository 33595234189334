import ky, { Options } from 'ky';
import {
  BUDGIE_API_URL,
  newTokenHeaderKeyValueFail,
  newTokenHeaderKeyValue,
  budgieUserErrorCode,
  budgieErrorCodes
} from '../utility/constants/constants';
import localStore from '../utility/general/localStore';
import { toast } from 'react-toastify';

export const authOptions: Options = {
  prefixUrl: BUDGIE_API_URL
};

export const authHttpClient = ky.create(authOptions);

export type requestResponse<T = any> = Promise<
  | {
      error: Response;
      data: undefined;
      errorMessage: string | undefined;
    }
  | {
      data: T;
      error: undefined;
      errorMessage: string | undefined;
    }
>;

class HttpClient {
  public budgieHttpClientFactory() {
    const token = localStore.getItem('token');
    const defaultOptions: Options = {
      prefixUrl: BUDGIE_API_URL,
      headers: {
        Authorization: `bearer ${token}`,
        TransformableImage: 'False'
      }
    };

    return ky.create(defaultOptions);
  }

  public getEmptyOptions(): Options {
    const headers = {
      'content-type': 'application/json'
    };
    return {
      timeout: false,
      prefixUrl: BUDGIE_API_URL,
      headers
    };
  }

  public checkHeaders(headers: Record<string, string | null | undefined>) {
    if (!headers) {
      console.error('No headers returned');
      return;
    }

    const budgieError = headers[budgieUserErrorCode];
    if (
      budgieError &&
      budgieError === budgieErrorCodes.UpdatedAtMismatchErrorCode
    ) {
      toast.error(
        'A recently edited entity was unable to save data. Please refresh the page to edit it if the issue persists.'
      );
    }

    const newToken = headers[newTokenHeaderKeyValue];
    if (!newToken) {
      return;
    }

    console.trace();

    if (newToken?.toLowerCase() === newTokenHeaderKeyValueFail.toLowerCase()) {
      console.error(
        'The failure case was hit correctly. Please copy this message and stack trace into KW-726'
      );
      window.location.reload();
      return;
    }

    localStore.setItem('token', newToken);
  }

  public getHeaders(
    response: Response
  ): Record<string, string | null | undefined> {
    return {
      NewToken: response?.headers.get(newTokenHeaderKeyValue),
      BudgieUserErrorCode: response?.headers.get(budgieUserErrorCode)
    };
  }

  public async parseJson(response: Response) {
    const text = await response.text();
    try {
      if (text === '') {
        return;
      }
      const json = JSON.parse(text);
      return json;
    } catch (err) {
      throw new Error('Did not receive JSON, instead received: ' + text);
    }
  }
}

export default HttpClient;
