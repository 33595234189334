import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import Sidebar, { ISidebarItem } from 'components/shared/Sidebar';
import { ReactComponent as BusinessDetails } from 'images/sidebar/business/businessDetails.svg';
import { ReactComponent as Events } from 'images/sidebar/business/events.svg';
import { ReactComponent as Home } from 'images/sidebar/business/home.svg';
import { ReactComponent as ManageBusiness } from 'images/sidebar/business/manageBusiness.svg';
import { ReactComponent as Analytics } from 'images/sidebar/business/analytics.svg';
import { ReactComponent as ContactUs } from 'images/sidebar/business/contactUs.svg';
import { ManagerContext } from 'provider/manager/managerProvider';
import { useContext } from 'react';

export enum BusinessSidebarNames {
  Home = 'Home',
  BusinessDetails = 'BusinessDetails',
  Events = 'Events',
  Analytics = 'Analytics',
  ManageBusiness = 'ManageBusiness',
  ContactUs = 'ContactUs'
}

type Props = {
  onClickSignout: (e: React.MouseEvent) => void;
};

function BusinessSidebar(props: Props) {
  const { onClickSignout } = props;
  const { listing } = useContext(ManagerContext);

  const businesSideBarItems: ISidebarItem[] = [
    { name: BusinessSidebarNames.Home, Icon: Home, path: '/' },
    {
      name: BusinessSidebarNames.BusinessDetails,
      Icon: BusinessDetails,
      path:
        PuffinRoutes.BusinessDetails.split(':')[0] + listing?.id + '/general'
    },
    {
      name: BusinessSidebarNames.Events,
      Icon: Events,
      path: PuffinRoutes.ManageEvents
    },
    {
      name: BusinessSidebarNames.Analytics,
      Icon: Analytics,
      path: PuffinRoutes.Analytics
    },
    {
      name: BusinessSidebarNames.ManageBusiness,
      Icon: ManageBusiness,
      path: PuffinRoutes.ManageBusiness
    },
    {
      name: BusinessSidebarNames.ContactUs,
      Icon: ContactUs
    }
  ];

  return (
    <Sidebar
      onClickSignout={onClickSignout}
      sideBarItems={businesSideBarItems}
      shouldDisplay={!!listing}
    />
  );
}

export default BusinessSidebar;
