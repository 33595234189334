import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { IPromotion } from 'Colugo/interfaces/promotions/IPromotion';
import PromotionOperations from 'Colugo/operations/promotions/PromotionOperations';
import { useReqListTagsByFilterGroup } from 'Colugo/operations/tags';
import { promotionsFilterGroupName } from 'components/dashboard/RecentPromotionsEditor';
import CreateOrEditPromotionModal from 'components/promotions/CreateOrEditPromotionModal';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import { FilterGroupContext } from 'provider/filterGroups/filterGroupsProvider';
import { ManagerContext } from 'provider/manager/managerProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import DraggablePromotion from './DraggablePromotion';
import { SubscriptionType } from 'Colugo/interfaces/subscriptions/ISubscription';
import { maximumEventAndPromotionCount } from 'components/business/dashboard/ManageEvents';
import InfoUpgrade from 'components/business/common/InfoUpgrade';

const promotionOperations = container.resolve(PromotionOperations);

type Props = {
  localEntity: IListing | IEvent;
  setLocalEntity: (localEntity: IListing | IEvent) => void;
};

function PromotionTabContainer(props: Props) {
  const { localEntity, setLocalEntity } = props;
  const location = useLocation();
  const { errorToast } = useErrorToast();
  const { isSuperAdmin } = useContext(UserContext);
  const { promotionsFilterGroup } = useContext(FilterGroupContext);
  const { listing, mutateListing } = useContext(ManagerContext);
  const [isCreatePromotionModalOpen, setIsCreatePromotionModalOpen] =
    useState<boolean>(false);
  const [promotionIdToDelete, setPromotionIdToDelete] = useState<
    string | undefined
  >(undefined);
  const [promotionToBeEdited, setPromotionToBeEdited] = useState<
    IPromotion | undefined
  >(undefined);

  const isEventPromotionsTabs = location.pathname.includes('event');

  const {
    data: promotionTags,
    isLoading: isTagsLoading,
    isError: isTagsError
  } = useReqListTagsByFilterGroup(promotionsFilterGroup?.id || '');

  if (isTagsError) {
    return (
      <ErrorPage>
        <span>{`Error retrieving tags.`}</span>
      </ErrorPage>
    );
  }

  if (isTagsLoading) {
    return (
      <div className="absolute w-full h-full">
        <Spinner expand />
      </div>
    );
  }

  if (!promotionsFilterGroup || !promotionsFilterGroup.id) {
    return (
      <ErrorPage>
        <span>{`There is no filter group named "${promotionsFilterGroupName}". This is required to create a promotion.`}</span>
      </ErrorPage>
    );
  }
  if (!promotionTags || promotionTags.length === 0) {
    return (
      <ErrorPage>
        <span>{`Unable to load tags for filter group named "${promotionsFilterGroupName}".`}</span>
      </ErrorPage>
    );
  }

  async function handleDeletePromotionAsync() {
    const promotionToBeRemoved = localEntity.promotions!.find(
      (p) => p.id === promotionIdToDelete
    );
    if (!promotionIdToDelete || !promotionToBeRemoved) {
      errorToast('Cannot find promotion, please try again.');
      return;
    }

    setLocalEntity({
      ...localEntity,
      promotions: localEntity.promotions!.filter(
        (p) => p.id !== promotionIdToDelete
      )
    });

    setPromotionIdToDelete(undefined);
    setIsCreatePromotionModalOpen(false);

    const { error } = await promotionOperations.deleteAsync(
      promotionIdToDelete
    );

    if (error) {
      errorToast('Failed to delete promotion, please try again');
      setLocalEntity({
        ...localEntity,
        promotions: [
          ...(localEntity.promotions || []),
          promotionToBeRemoved
        ].sort((a, b) => (a.order || 0) - (b.order || 0))
      });
      return false;
    }

    if (listing) {
      if (isEventPromotionsTabs) {
        if (!localEntity) {
          errorToast('Unable to get event to update, please try again.');
          return;
        }
        mutateListing({
          ...listing,
          events: [
            ...(listing.events?.filter((e) => e.id !== localEntity.id) || []),
            {
              ...localEntity,
              promotions: localEntity.promotions?.filter(
                (p) => p.id !== promotionIdToDelete
              )
            }
          ]
        });
      } else {
        mutateListing({
          ...localEntity,
          promotions: localEntity.promotions?.filter(
            (p) => p.id !== promotionIdToDelete
          )
        });
      }
    }
  }

  async function handleUpdatePromotionsOrderAsync(
    fromIndex: number,
    toIndex: number
  ) {
    if (toIndex === fromIndex) {
      errorToast('Intended order same as current order');
      return;
    }
    if (!localEntity.promotions) {
      errorToast('Promotions not found');
      return;
    }
    const promotionToReorder = localEntity.promotions[fromIndex];
    if (!promotionToReorder) {
      errorToast(
        'Promotion not found. Cannot reorder, please refresh the page.'
      );
      return;
    }

    reorderResults(fromIndex, toIndex);

    const { data: updatedPromotionsOrders, error } = isEventPromotionsTabs
      ? await promotionOperations.updateEventPromotionsOrderAsync(
          localEntity.id!,
          promotionToReorder.id!,
          toIndex
        )
      : await promotionOperations.updateListingPromotionsOrderAsync(
          localEntity.id!,
          promotionToReorder.id!,
          toIndex
        );

    if (!updatedPromotionsOrders || error) {
      errorToast('Failed to update order. Please refresh the page.');
      setLocalEntity(localEntity);
      return;
    }
  }

  function reorderResults(fromIndex: number, toIndex: number) {
    if (!localEntity.promotions) {
      return;
    }

    const updatedPromotions = localEntity.promotions.map((promotion) => {
      const order = localEntity.promotions!.findIndex(
        (p) => p.id === promotion.id
      );
      if (order === fromIndex) {
        promotion.order = toIndex;
      } else if (order > fromIndex && order <= toIndex) {
        promotion.order = order - 1;
      } else if (order < fromIndex && order >= toIndex) {
        promotion.order = order + 1;
      }
      return promotion;
    });

    setLocalEntity({
      ...localEntity,
      promotions: updatedPromotions.sort(
        (a, b) => (a.order || 0) - (b.order || 0)
      )
    });
  }
  const allPromotionsLength =
    (listing?.promotions?.length || 0) +
    (listing?.events?.reduce((acc, event) => {
      return acc + (event.promotions?.length ?? 0);
    }, 0) || 0);

  const subscriptionType =
    listing?.subscription?.subscriptionType ?? SubscriptionType.Essential;

  const isWithinPromotionLimit =
    isSuperAdmin ||
    allPromotionsLength < maximumEventAndPromotionCount[subscriptionType];

  const showHintBox =
    subscriptionType !== SubscriptionType.Premium ||
    allPromotionsLength === maximumEventAndPromotionCount[subscriptionType];

  const conditionalLetterS =
    maximumEventAndPromotionCount[subscriptionType] > 1 ? 's' : '';

  return (
    <div className="text-inter flex flex-col rounded-lg w-full font-inter relative">
      {!!promotionIdToDelete && (
        <ConfirmationPopup
          isModalOpen={!!promotionIdToDelete}
          handleClickConfirmButton={handleDeletePromotionAsync}
          closeModal={() => {
            setPromotionIdToDelete(undefined);
            setIsCreatePromotionModalOpen(false);
          }}
          popupLabel={`Delete this Promotion?`}
          confirmButtonText="Delete Promotion"
          isErrorButton={true}
        >
          <div className="font-nunito text-primaryCharcoal font-normal text-base">
            If a Promotion is permanently deleted, it cannot be undone. Are you
            sure you want to delete this Promotion?
          </div>
        </ConfirmationPopup>
      )}
      {!promotionIdToDelete && isCreatePromotionModalOpen && (
        <CreateOrEditPromotionModal
          isCreateModalOpen={isCreatePromotionModalOpen}
          setIsCreateModalOpen={setIsCreatePromotionModalOpen}
          listing={localEntity}
          modalTitle={
            !!promotionToBeEdited ? 'Edit Promotion' : 'Create a Promotion'
          }
          onCreatePromotion={(newPromotion) => {
            setLocalEntity({
              ...localEntity,
              promotions: [...(localEntity.promotions || []), newPromotion]
            });
          }}
          onEditPromotion={(updatedPromotion) =>
            setLocalEntity({
              ...localEntity,
              promotions: [
                ...(localEntity.promotions!.filter(
                  (p) => p.id !== updatedPromotion.id
                ) || []),
                updatedPromotion
              ].sort((a, b) => (a.order || 0) - (b.order || 0))
            })
          }
          promotion={promotionToBeEdited}
          isEventPromotionsTabs={isEventPromotionsTabs}
        />
      )}
      <div className="pb-4 border-b-2 px-6 py-8 ">
        <div className="flex flex-row w-full">
          <span className="text-2xl font-bold leading-9 text-left">
            Promotions
          </span>
          <div className="flex-grow flex" />
          <div
            className={`bg-primary text-white pt-2 pb-2 pl-2 pr-4 rounded-lg flex flex-row font-nunito font-bold cursor-pointer`}
            onClick={() => {
              toast.dismiss();
              if (isWithinPromotionLimit) {
                setPromotionToBeEdited(undefined);
                setIsCreatePromotionModalOpen(true);
              } else {
                errorToast(
                  `You have reached the maximum of ${
                    maximumEventAndPromotionCount[subscriptionType]
                  } promotion${conditionalLetterS}. ${
                    subscriptionType === SubscriptionType.Premium
                      ? 'Contact us'
                      : 'Upgrade your plan'
                  } for more.`
                );
              }
            }}
          >
            <span className="mx-2">Create Promotion</span>
            <AiFillPlusCircle size={24} className={`text-xl`} />
          </div>
        </div>
        <div className="text-base font-normal text-left text-primaryCharcoal flex-col flex font-nunito">
          <span>
            {`You can add promotions to your ${
              isEventPromotionsTabs ? 'Event' : 'business'
            } so users can easily find deals or `}
          </span>
          <span>discounts on the app.</span>
        </div>
      </div>
      <div className="pt-4 px-6 rounded-lg ">
        <span className="text-2xl font-bold leading-9 text-left ">
          Current promotions
        </span>
        {!isSuperAdmin && showHintBox && (
          <InfoUpgrade
            beforeText={`Maximum of ${maximumEventAndPromotionCount[subscriptionType]} Promotion${conditionalLetterS} across your Business Listing and Event${conditionalLetterS}: `}
            linkText="Upgrade"
            afterText=" your plan for more. "
            listingId={listing!.id!}
            className="mt-2 bg-dimNavyConatiner"
          />
        )}
        {!localEntity.promotions || localEntity.promotions.length === 0 ? (
          <div className="mt-4">
            <div
              className="w-full bg-surface border border-black rounded-lg border-dashed flex flex-row"
              style={{ height: 371 }}
            >
              <div className="flex mx-auto flex-col h-full">
                <div className="flex my-auto flex-row">
                  <span className="text-base font-normal flex my-auto">
                    No promotions found,
                  </span>
                  <span
                    className="text-base flex my-auto underline ml-1 cursor-pointer font-bold"
                    onClick={() => setIsCreatePromotionModalOpen(true)}
                  >
                    Create a promotion?
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-6"></div>
          </div>
        ) : (
          <div className="flex-col flex mb-4">
            {localEntity.promotions.map((promotion, index) => (
              <div key={index}>
                <DraggablePromotion
                  promotion={promotion}
                  index={index}
                  onClickDelete={() => {
                    setIsCreatePromotionModalOpen(false);
                    setPromotionIdToDelete(promotion.id);
                  }}
                  onClickEdit={() => {
                    setIsCreatePromotionModalOpen(true);
                    setPromotionToBeEdited(promotion);
                  }}
                  movePromotionAsync={handleUpdatePromotionsOrderAsync}
                  tags={promotionTags}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default PromotionTabContainer;
