import React, { createContext } from 'react';
import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { MutatorCallback } from 'swr/dist/types';
import { useReqListPromotionsAsync } from 'Colugo/operations/promotions/PromotionOperations';
import { IPromotion } from 'Colugo/interfaces/promotions/IPromotion';

type Props = {
  children: React.ReactNode;
};

type PromotionsContextType = {
  promotions: IPromotion[];
  mutatePromotions: (
    data?:
      | IPromotion[]
      | Promise<IPromotion[]>
      | MutatorCallback<IPromotion[]>
      | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<IPromotion[] | undefined>;
};

export const PromotionsContext = createContext<PromotionsContextType>({
  promotions: [],
  mutatePromotions: async (
    data?: any,
    shouldRevalidate?: boolean | undefined
  ) => undefined
});

//NOTE: use the userReducer once the state becomes complex
function PromotionsProvider(props: Props) {
  const { children } = props;

  const {
    data: promotions,
    isLoading: isPromotionsLoading,
    isError: isPromotionsError,
    mutate: mutatePromotions
  } = useReqListPromotionsAsync();

  if (isPromotionsLoading) {
    return (
      <div className="absolute w-full h-screen flex flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isPromotionsError) {
    return (
      <ErrorPage>
        <span>{'Unable to load promotions from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <PromotionsContext.Provider
      value={{
        promotions: promotions || [],
        mutatePromotions
      }}
    >
      {children}
    </PromotionsContext.Provider>
  );
}

export default PromotionsProvider;
