import FileUploadOperations from 'Colugo/operations/file/FileUploadOperations';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';

const fileUploadOperations = container.resolve(FileUploadOperations);

export const uploadFileOnDrop =
  (
    callback: (uploadedFileUrl: string) => void,
    setIsLoading?: (isLoading: boolean) => void,
    setProgress?: (progress: number) => void,
    isVideoUpload: boolean = false
  ) =>
  async (acceptedFiles: File[]) => {
    const setLoading = (isLoading: boolean) =>
      setIsLoading && setIsLoading(isLoading);

    if (!callback) {
      console.error('callback is undefined');
      return;
    }

    setLoading(true);
    let imageUrl: string, error: any;
    if (isVideoUpload) {
      ({ data: imageUrl, error } = await fileUploadOperations.uploadVideoAsync(
        acceptedFiles[0],
        setProgress
      ));
    } else {
      ({ data: imageUrl, error } = await fileUploadOperations.uploadImageAsync(
        acceptedFiles[0],
        setProgress
      ));
    }

    if (error) {
      console.error(error);
      toast.error('Unable to upload the file.');
      setLoading(false);
      return;
    }

    setLoading(false);
    callback(imageUrl);
  };
