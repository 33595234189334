import { IUser } from 'Colugo/interfaces/identity';
import ProfileModal from 'components/dashboard/ProfileModal';
import AccountNameProfileButton from 'components/shared/AccountNameProfileButton';
import {
  ErrorPage,
  Spinner,
  useOutsideClick
} from '@bindystreet/bindystreet.kit.react';
import EventOperations, {
  useReqGetEventAsync
} from 'Colugo/operations/events/EventOperations';
import DashboardFooter from 'components/dashboard/DashboardFooter';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import CustomFormHeader from 'components/shared/CustomFormHeader';
import { ManagerContext } from 'provider/manager/managerProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useRef, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { FaCog } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import EditEventEditor from './EditEventEditor';

type EventRouteParams = {
  eventId: string;
};

const eventOperations = container.resolve(EventOperations);

function EditEventContainer() {
  const { errorToast } = useErrorToast();
  const { eventId } = useParams<EventRouteParams>();
  const navigate = useNavigate();
  const [tabClickCount, setTabClickCount] = useState<number>(1);
  const [businessUser, setBusinessUser] = useState<IUser | undefined>(
    undefined
  );
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { isSuperAdmin, isEventNewlyCreated, setIsEventNewlyCreated } =
    useContext(UserContext);
  const { mutateListing, listing } = useContext(ManagerContext);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setIsSettingsOpen(false));

  const {
    data: event,
    isLoading,
    isError,
    mutate
  } = useReqGetEventAsync(eventId);

  async function handleDeleteEntityAsync() {
    if (!event?.id) {
      errorToast('No event found, Please try again');
      return;
    }

    const { error } = await eventOperations.deleteAsync(event.id!);

    if (error) {
      errorToast(`Failed to delete Event, please try again`);
      return false;
    }

    if (listing) {
      mutateListing({
        ...listing,
        events: listing.events?.filter((e) => e.id !== event.id)
      });
    }

    closeEventContainer();
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!event || isError) {
    return (
      <ErrorPage>
        <span>{'Unable to load event from server.'}</span>
      </ErrorPage>
    );
  }

  //NOTE: We can't access the history of previous sites, so have to track
  //the number of times the tab switches then go back that many times.
  //We have to do this rather than just redirecting to the home screen
  //because there are different starting points to return to.
  function closeEventContainer() {
    const counter = isEventNewlyCreated ? tabClickCount + 1 : tabClickCount;
    setIsEventNewlyCreated(false);
    navigate(-counter);
    setTabClickCount(1);
  }

  const deleteEventModal = isSettingsOpen && (
    <div
      className="absolute right-5 top-12 h-12 rounded-lg z-50 bg-white cursor-pointer"
      style={{
        width: '208px',
        boxShadow:
          '0 4px 6px rgba(0, 0, 0, 0.05), 0 -4px 6px rgba(0, 0, 0, 0.05), 0 10px 15px rgba(0, 0, 0, 0.05), 0 -10px 15px rgba(0, 0, 0, 0.05)'
      }}
      onClick={() => setIsDeleteModalOpen(true)}
      ref={ref}
    >
      <div className="flex flex-row mt-2 px-2">
        <div
          className="flex flex-row w-full hover:bg-error5 hover:text-white px-1 rounded pb-1 cursor-pointer"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={() => {
            setIsSettingsOpen(false);
          }}
        >
          <AiFillDelete
            className={`ml-2 ${
              isHover ? 'text-white' : 'text-primaryCharcoal'
            } mt-2`}
            size={14}
          />
          <div className="ml-2 pt-0.5">Delete</div>
        </div>
      </div>
    </div>
  );

  const settingsCog = (
    <div
      className="cursor-pointer p-2"
      onClick={() => setIsSettingsOpen(!isSettingsOpen)}
    >
      <FaCog />
    </div>
  );

  return (
    <div className="flex flex-col h-screen w-screen bg-theme1 relative">
      <CustomFormHeader
        title={event.name}
        onPressClose={closeEventContainer}
        onPressComponent={
          isSuperAdmin ? (
            <AccountNameProfileButton
              item={event}
              setBusinessUser={setBusinessUser}
            />
          ) : (
            settingsCog
          )
        }
      />
      {deleteEventModal}
      <ConfirmationPopup
        isModalOpen={isDeleteModalOpen}
        handleClickConfirmButton={handleDeleteEntityAsync}
        closeModal={() => setIsDeleteModalOpen(false)}
        popupLabel={`Delete ${event.name}`}
        confirmButtonText={`Yes, Delete my Event`}
        isErrorButton={true}
        height="230px"
      >
        <div className="font-nunito text-lg font-normal">
          Deleting this Event is permanent, and will remove this Event from our
          database. Are you sure that you want to delete?
        </div>
      </ConfirmationPopup>
      <div className="flex flex-col w-screen h-screen overflow-y-auto">
        {!!businessUser && (
          <ProfileModal
            businessUser={businessUser}
            onCloseModal={() => setBusinessUser(undefined)}
          />
        )}
        <div>
          <EditEventEditor
            event={event}
            mutate={mutate}
            incrementTabClickCount={() => setTabClickCount(tabClickCount + 1)}
          />
        </div>
        <DashboardFooter />
      </div>
    </div>
  );
}

export default EditEventContainer;
