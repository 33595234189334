import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { ReactComponent as ReceiptIcon } from 'images/receipt.svg';
import { formatDateWithTime } from 'utility/general/formatHelpers';
import ToggleIsActiveComponent from './ToggleIsActiveComponent';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { SubscriptionType } from 'Colugo/interfaces/subscriptions/ISubscription';

type ListingAddressOrPlanProps = {
  listing: IListing;
  isManageMode?: boolean;
  updatedAt?: string;
  subscriptionType?: SubscriptionType;
};

const ListingAddressOrPlan = ({
  listing,
  isManageMode,
  updatedAt,
  subscriptionType = SubscriptionType.Essential
}: ListingAddressOrPlanProps) => {
  const updatePlanRoute = `${
    PuffinRoutes.UpdateBusinessPlan.split(':')[0] + listing.id
  }`;

  function getEmberPlan() {
    switch (subscriptionType) {
      case SubscriptionType.Essential:
        return (
          <div className="flex gap-1 items-center">
            <ReceiptIcon className="text-onSurface" />
            <p className="text-onSurface">Ember Essentials</p>
            <span />
            <a href={updatePlanRoute}>
              <u>Upgrade</u>
            </a>
          </div>
        );
      case SubscriptionType.Plus:
        return (
          <div className="flex gap-1 items-center">
            <ReceiptIcon className="text-blue" />
            <p className="text-blue">Ember Plus</p>
            <span />
            <a href={updatePlanRoute}>
              <u>Edit</u>
            </a>
          </div>
        );
      case SubscriptionType.Premium:
        return (
          <div className="flex gap-1 items-center">
            <ReceiptIcon className="text-navyLight" />
            <p className="text-navyLight">Ember Premium</p>
            <span />
            <a href={updatePlanRoute}>
              <u>Edit</u>
            </a>
          </div>
        );
    }
  }

  return isManageMode ? (
    getEmberPlan()
  ) : (
    <div
      className="flex flex-row items-center justify-start 400 px-0"
      style={{ height: '18px' }}
    >
      <ToggleIsActiveComponent isActive={!!listing.isActive} />
      {updatedAt && (
        <div className="font-normal text-xs font-inter ml-2 text-secondaryText">
          {`Last Edited: ${formatDateWithTime(updatedAt)}`}
        </div>
      )}
    </div>
  );
};

export default ListingAddressOrPlan;
