import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { EventStatus, IEvent } from 'Colugo/interfaces/event/IEvent';
import { useReqListEventsAsync } from 'Colugo/operations/events/EventOperations';
import dayjs from 'dayjs';
import React, { createContext, useState } from 'react';
import { MutatorCallback } from 'swr/dist/types';

type Props = {
  children: React.ReactNode;
};

type EventsContextType = {
  events: IEvent[];
  mutateEvents: (
    data?: IEvent[] | Promise<IEvent[]> | MutatorCallback<IEvent[]> | undefined,
    shouldRevalidate?: boolean | undefined
  ) => Promise<IEvent[] | undefined>;
  eventStatus: EventStatus;
  setEventStatus: (eventStatus: EventStatus) => void;
};

export const EventsContext = createContext<EventsContextType>({
  events: [],
  mutateEvents: async (data?: any, shouldRevalidate?: boolean | undefined) =>
    undefined,
  eventStatus: EventStatus.All,
  setEventStatus: () => {}
});

function EventsProvider(props: Props) {
  const { children } = props;

  const [eventStatus, setEventStatus] = useState<EventStatus>(EventStatus.All);

  const {
    data: events,
    isLoading: isEventsLoading,
    isError: isEventsError,
    mutate: mutateEvents
  } = useReqListEventsAsync(eventStatus);

  if (isEventsLoading) {
    return (
      <div className="absolute w-full h-screen flex flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isEventsError) {
    return (
      <ErrorPage>
        <span>{'Unable to load events from server.'}</span>
      </ErrorPage>
    );
  }

  return (
    <EventsContext.Provider
      value={{
        events:
          //NOTE: Ordering events with active and non-expired first
          events?.sort((a, b) => {
            const isAExpired = dayjs(a.endDate).isBefore(dayjs());
            const isBExpired = dayjs(b.endDate).isBefore(dayjs());
            if (a.isActive && !isAExpired && b.isActive && !isBExpired) {
              return 0;
            }

            if (a.isActive && !isAExpired) {
              return -1;
            }

            if (b.isActive && !isBExpired) {
              return 1;
            }

            return a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1;
          }) || [],
        mutateEvents: mutateEvents,
        eventStatus: eventStatus,
        setEventStatus: setEventStatus
      }}
    >
      {children}
    </EventsContext.Provider>
  );
}

export default EventsProvider;
