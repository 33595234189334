import { ReactComponent as SubcriptionLock } from 'images/subscriptionLock.svg';
type Props = {
  height: string;
};

export default function BlurComponent(props: Props) {
  const { height } = props;
  return (
    <div>
      <div
        className="rounded-lg border-outline border"
        style={{
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          position: 'absolute',
          zIndex: 30,
          width: '100%',
          height: height,
          backgroundColor: 'rgba(239, 239, 247, 0.75)'
        }}
      >
        <div className="flex flex-row w-full h-full">
          <div className="flex flex-col h-full mx-auto">
            <SubcriptionLock className="my-auto" />
          </div>
        </div>
      </div>
    </div>
  );
}
