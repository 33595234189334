import Skeleton from 'react-loading-skeleton';

type Props = {
  width: number;
  className: string;
};

export function AnalyticMetricSkeleton(props: Props) {
  const { width, className } = props;
  return (
    <div className={className}>
      <Skeleton height={22} width={0.9 * width} />
      <div className="flex flex-row mt-6 w-full">
        <Skeleton height={48} width={0.6 * width} />
        <div className="w-4" />
        <Skeleton height={48} width={0.6 * width} />
      </div>
    </div>
  );
}
