import { IAnalytics } from 'Colugo/interfaces/common/IAnalytics';
import BlurComponent from 'components/business/common/BlurComponent';
import InfoUpgrade from 'components/business/common/InfoUpgrade';
import {
  analyticsSnapshotSkeleton,
  renderMetric
} from 'utility/analytics/analyticsUtils';

type Props = {
  listingId?: string;
  analytics?: IAnalytics;
  topComponent?: JSX.Element;
  nOfDays?: number;
  isWithCollected?: boolean;
  onClick?: () => void;
  isPremiumOrPlusBusiness: boolean;
  showUpgradeOption?: boolean;
  isLoading?: boolean;
};

export default function AnalyticsSnapshot(props: Props) {
  const {
    analytics,
    topComponent,
    nOfDays = 7,
    isWithCollected = true,
    onClick,
    isPremiumOrPlusBusiness,
    listingId = '',
    showUpgradeOption = true,
    isLoading
  } = props;

  const verticalDivider = (
    <div className="border-l border-outline" style={{ height: '138px' }} />
  );

  return (
    <div className="relative">
      {!isPremiumOrPlusBusiness && showUpgradeOption && (
        <InfoUpgrade
          beforeText="Upgrade to "
          linkText="Ember Plus or Premium"
          afterText=" to access analytics"
          className="bg-primaryContainer mt-2"
          listingId={listingId}
        />
      )}
      <div
        className={`font-inter rounded-lg bg-white ${
          onClick && isPremiumOrPlusBusiness
            ? 'cursor-pointer hover:shadow-lg transition-all duration-200'
            : ''
        }`}
        onClick={() => isPremiumOrPlusBusiness && onClick && onClick()}
      >
        {!isPremiumOrPlusBusiness && (
          <BlurComponent height={topComponent ? '272px' : '200px'} />
        )}
        {isLoading ? (
          analyticsSnapshotSkeleton
        ) : (
          <div
            className={`flex flex-col rounded-lg border border-outline mt-4`}
          >
            {topComponent}
            <div className="flex flex-row px-2 py-6">
              {renderMetric(
                'Total Impressions',
                analytics?.impressions,
                nOfDays,
                isWithCollected ? '290px' : '390px'
              )}
              {verticalDivider}
              {renderMetric(
                'Total Clicks',
                analytics?.clicks,
                nOfDays,
                isWithCollected ? '290px' : '390px'
              )}
              {verticalDivider}
              {renderMetric(
                'Click-through rate',
                {
                  ...analytics?.clickThroughRate,
                  count: Number(
                    ((analytics?.clickThroughRate.count || 0) * 100).toFixed(0)
                  )
                },
                nOfDays,
                isWithCollected ? '290px' : '390px',
                true
              )}
              {isWithCollected && verticalDivider}
              {isWithCollected &&
                renderMetric(
                  'Total Saves',
                  analytics?.collects,
                  nOfDays,
                  '290px'
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
