import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { ReactComponent as InfoIcon } from 'images/dashboard/info.svg';
import { useNavigate } from 'react-router-dom';

type Props = {
  beforeText: string;
  linkText: string;
  afterText: string;
  className: string;
  listingId: string;
  onClick?: () => void;
};

export default function InfoUpgrade(props: Props) {
  const { beforeText, linkText, afterText, className, listingId, onClick } =
    props;
  const navigate = useNavigate();
  return (
    <div
      className={`${className} border border-outline w-full text-onSurface h-11 px-4 py-2 rounded-lg flex items-center gap-3`}
    >
      <InfoIcon />
      <p className="heading-regular-av">
        {beforeText}
        <span
          className="underline cursor-pointer"
          onClick={() =>
            onClick
              ? onClick()
              : navigate(
                  PuffinRoutes.UpdateBusinessPlan.replace(
                    ':listingId',
                    listingId
                  ).replace('/*', '')
                )
          }
        >
          {linkText}
        </span>
        {afterText}
      </p>
    </div>
  );
}
