import { ErrorPage } from '@bindystreet/bindystreet.kit.react';
import { IFilterGroup } from 'Colugo/interfaces/tags';
import { useReqListFilterGroups } from 'Colugo/operations/tags';
import { promotionsFilterGroupName } from 'components/promotions/CreateOrEditPromotionModal';
import React from 'react';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export type FilterGroupContextType = {
  filterGroups: IFilterGroup[] | undefined;
  promotionsFilterGroup: IFilterGroup | undefined;
  establishmentsFilterGroup: IFilterGroup | undefined;
};

export const FilterGroupContext = React.createContext<FilterGroupContextType>({
  filterGroups: undefined,
  promotionsFilterGroup: undefined,
  establishmentsFilterGroup: undefined
});

const establishmentFilterGroupName = 'Establishment';

function FilterGroupProvider(props: Props) {
  const { children } = props;

  const { data: filterGroups, isError: isFilterGroupsError } =
    useReqListFilterGroups();

  if (isFilterGroupsError) {
    return (
      <ErrorPage>
        <span>{`Error retrieving filter groups.`}</span>
      </ErrorPage>
    );
  }
  const promotionsFilterGroup = filterGroups?.find(
    (fg) => fg.name === promotionsFilterGroupName
  );

  const establishmentsFilterGroup = filterGroups?.find(
    (fg) => fg.name === establishmentFilterGroupName
  );

  return (
    <FilterGroupContext.Provider
      value={{
        filterGroups,
        promotionsFilterGroup,
        establishmentsFilterGroup
      }}
    >
      {children}
    </FilterGroupContext.Provider>
  );
}

export default FilterGroupProvider;
