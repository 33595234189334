import { ILocationDetails } from 'Colugo/interfaces/games/ILocationDetails';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { NameLocationResult } from 'Colugo/interfaces/NameLocationResult';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import CustomFormHeader from 'components/shared/CustomFormHeader';
import ImprovedInput from 'components/shared/ImprovedInput';
import ImprovedSwitch from 'components/shared/ImprovedSwitch';
import LocationModal from 'components/shared/LocationModal';
import LocationSnippet from 'components/shared/LocationSnippet';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';

const listingOperations = container.resolve(ListingOperations);

function CreateListing() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [addressErrorMessage, setAddressErrorMessage] = useState<
    string | undefined
  >(undefined);
  const [isNameError, setIsNameError] = useState<boolean>(false);
  const [isLocationModalOpen, setIsLocationModalOpen] =
    useState<boolean>(false);
  const [hasLocation, setHasLocation] = useState<boolean>(true);
  const { errorToast } = useErrorToast();

  const maxNameLength = 70;

  const emptyListing: IListing = {
    name: '',
    isActive: false,
    address: {}
  };
  const [localListing, setLocalListing] = useState<IListing>(emptyListing);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  // Modal has a form /w field for adding name.
  // On submit, upload request to server

  async function submitCreateListingAsync(listing?: IListing) {
    if (!localListing.name) {
      setErrorMessage(
        'No Business name provided, please enter a name for your Business'
      );
      setIsNameError(true);
      return;
    }
    if (localListing.name.length > maxNameLength) {
      return;
    }
    setIsNameError(false);
    if (
      hasLocation &&
      (!localListing.location || !localListing.location.latitude)
    ) {
      setAddressErrorMessage(
        'No Address Provided, please enter an address or toggle off business address.'
      );
      return;
    }

    const listingToCreate = listing || localListing;

    if (!hasLocation) {
      listingToCreate.location = undefined;
      listingToCreate.address = {};
    }

    if (!listingToCreate) {
      errorToast('Unable to create listing');
      return;
    }

    setIsLoading(true);

    const { data: nameLocationResult } =
      await listingOperations.checkNameLocationAsync(listingToCreate);

    if (nameLocationResult !== NameLocationResult.Unique) {
      setIsLoading(false);
      setErrorMessage(
        hasLocation
          ? 'Business already exists. Please check the name and address fields.'
          : 'Business already exists. Please check the name field.'
      );
      setIsNameError(true);
      return;
    }

    const { data: createdListing, error } = await listingOperations.createAsync(
      listingToCreate
    );

    setIsLoading(false);
    if (error) {
      errorToast('Failed to create business.');
      return;
    }

    if (error || !createdListing) {
      errorToast('Unable to create business');
      return;
    }

    navigate(`/listing/${createdListing.id}/general`);
  }

  async function handleUpdateLocationAsync(locationDetails: ILocationDetails) {
    setErrorMessage(undefined);
    const newListing: IListing = {
      ...localListing,
      location: {
        latitude: locationDetails.location?.latitude,
        longitude: locationDetails.location?.longitude
      },
      address: locationDetails.address
    };
    setLocalListing(newListing);
    await submitCreateListingAsync(newListing);
  }

  return (
    <div className="flex flex-col bg-pageColour h-screen max-h-screen overflow-y-auto">
      <CustomFormHeader
        title="Create New Business"
        actionTitle="Submit"
        onPressAction={async () => await submitCreateListingAsync()}
        isActionLoading={isLoading}
      />
      {isLocationModalOpen && (
        <LocationModal
          setIsModalOpen={setIsLocationModalOpen}
          onConfirmLocation={handleUpdateLocationAsync}
          initialLocationDetails={{
            address: localListing?.address || {},
            location: localListing?.location
          }}
        />
      )}
      <div
        className="flex-col items-center justify-center ml-auto mr-auto"
        style={{ width: '714px' }}
      >
        <div
          className="mt-10 text-lg font-inter font-normal text-onSurfaceVariant"
          style={{ lineHeight: '22px', width: '714px' }}
        >
          <div className="mt-6">
            <p className="font-inter text-onSurface font-bold text-base mb-2">
              Business Name
            </p>
            <ImprovedInput
              className="h-full py-4 focus:outline-none"
              wrapperClassName="border rounded-lg bg-white flex-row items-center focus:border-onSurface px-6"
              maxCharacterCount={70}
              onChange={(e) => {
                setLocalListing({
                  ...localListing,
                  name: e.currentTarget.value
                });
                setErrorMessage(undefined);
              }}
              placeHolder="Enter Business Name"
              value={localListing.name}
              error={errorMessage}
              isError={isNameError}
            />
          </div>
          <div
            className="flex flex-col text-left font-inter text-primaryCharcoal font-bold mt-4"
            style={{ fontSize: '16px', lineHeight: '24px' }}
          >
            <div className="flex items-center">
              <ImprovedSwitch
                className="mt-1"
                checked={hasLocation}
                onChange={() => {
                  setHasLocation(!hasLocation);
                  setAddressErrorMessage(undefined);
                }}
              />
              <div className="ml-4">Business Address*</div>
            </div>
            <div>
              <div
                className="font-inter font-normal text-sm text-primaryCharcoal mt-1 mb-1"
                style={{ lineHeight: '21px' }}
              >
                {hasLocation
                  ? 'Set location by searching keywords'
                  : 'Not having a location will make it harder for users to find your business on the map.'}
              </div>
            </div>
            {hasLocation && (
              <LocationSnippet
                latitude={localListing.location?.latitude}
                longitude={localListing.location?.longitude}
                address={localListing.address}
                openLocationModal={() => {
                  setIsLocationModalOpen(true);
                  setAddressErrorMessage(undefined);
                }}
              />
            )}
            {addressErrorMessage && (
              <div className="text-error1 font-inter font-bold text-sm mb-2 mt-1">
                {addressErrorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateListing;
