export default class Validator {
  static isAlphaNumericRegex(tag: string) {
    const re = /^[a-zA-Z0-9]+$/;
    return re.test(String(tag).toLowerCase());
  }
  static isEmailValid(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  static isPasswordValid(password: string) {
    const re = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=\S*).{8,}$/;
    return re.test(String(password));
  }

  static isUrlValid(url: string) {
    const re = /^(ftp|http|https):\/\/[^ "]+$/;
    return re.test(String(url));
  }

  static isFacebookLink(url: string) {
    const re =
      /(?:https?:\/\/)?(?:www\.)?(?:facebook\.com|fb\.com)\/(?:groups\/[a-zA-Z0-9.]+\/permalink\/\d+|(?:\w)*\/)*(?:\w)*(\.[a-zA-Z]{2,3})?(\/[a-zA-Z0-9.\-_]*)*/;
    return re.test(url);
  }

  static isTwitterLink(url: string) {
    const re =
      /(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|t\.co|x\.com)\/[A-Za-z0-9_]{1,15}\/?/;
    return re.test(url);
  }

  static isInstagramLink(url: string) {
    const re =
      /(?:https?:\/\/)?(?:www\.)?instagram\.com\/[a-zA-Z0-9_]{1,30}\/?/;
    return re.test(url);
  }

  static isTiktokLink(url: string) {
    const re =
      /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@?[a-zA-Z0-9_.-]{2,24}\/?/;
    return re.test(url);
  }

  static isPhoneNumberValid(phoneNumber: string) {
    const re = /^\+?[0-9\s]+$/;
    return re.test(phoneNumber);
  }
}
