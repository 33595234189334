import {
  FlyoutMenuItem,
  Input,
  InputKeyboardEvent,
  useOutsideClick
} from '@bindystreet/bindystreet.kit.react';
import {
  ErrorType,
  IIoiValidationError
} from 'Colugo/interfaces/IIoiValidationError';
import { ITag } from 'Colugo/interfaces/tags';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useMemo, useRef, useState } from 'react';
import { AiFillStar, AiOutlineClose, AiOutlineStar } from 'react-icons/ai';
import { FaTags } from 'react-icons/fa';
import useTagUtilities from 'utility/general/useTagUtilities';
import TagCard from './TagCard';

type Props = {
  tags: ITag[];
  entityTags: ITag[];
  onClickTagDeleteIconAsync: (tagId: string) => Promise<void> | void;
  onConfirmNewTagAsync: (tagId: string) => Promise<void> | void;
  onClickTagUpdatePriorityAsync?: (
    tagId: string,
    isPriority: boolean
  ) => Promise<void>;
  validationErrors?: IIoiValidationError[];
  isInModal?: boolean;
  customPlaceholder?: string;
  isDisabled?: boolean;
  primaryEstablishmentTagId?: string;
};

function TagSelector(props: Props) {
  const {
    tags,
    onClickTagDeleteIconAsync,
    onConfirmNewTagAsync,
    entityTags,
    onClickTagUpdatePriorityAsync,
    validationErrors,
    isInModal = false,
    customPlaceholder,
    isDisabled = false,
    primaryEstablishmentTagId
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  const taggingValidationError = validationErrors?.find(
    (e) => e.type === ErrorType.Tag
  );

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    setIsFlyoutOpen(false);
  });

  const { getCompleteTagDetails } = useTagUtilities();
  const { isSuperAdmin } = useContext(UserContext);

  const populatedTags = getCompleteTagDetails(tags, isSuperAdmin);
  const populatedEntityTags = getCompleteTagDetails(entityTags, isSuperAdmin);

  const flyoutItems: FlyoutMenuItem[] = useMemo(
    () =>
      populatedTags
        ?.filter(
          (tag) =>
            tag.name?.toLowerCase().startsWith(inputValue.toLowerCase()) &&
            !populatedEntityTags.some((entityTag) => tag.id === entityTag.id)
        )
        .map((tag) => {
          return {
            label: tag.name?.toLowerCase(),
            value: tag.id
          } as FlyoutMenuItem;
        }),
    [populatedTags, populatedEntityTags, inputValue]
  );

  async function handleFlyoutOnConfirmNewTagAsync(flyoutItem: {
    label: string;
    value?: string;
  }) {
    const item: ITag = {
      name: flyoutItem.label,
      id: flyoutItem.value
    };
    setInputValue('');
    await onConfirmNewTagAsync(item?.id!);
  }

  async function handleClickPriorityAsync(tag: ITag, isPriority: boolean) {
    await onClickTagUpdatePriorityAsync?.(tag.id!, isPriority);
  }

  async function handleOnKeyDownAsync(e: InputKeyboardEvent) {
    if (flyoutItems.length > 0 && e.key === 'Enter') {
      await handleFlyoutOnConfirmNewTagAsync(flyoutItems[0]);
    }
  }

  const tagsElements = populatedEntityTags
    .sort((a, b) => {
      if (a.isPriority !== b.isPriority) {
        return a.isPriority ? -1 : 1;
      }
      if (a.id === primaryEstablishmentTagId) {
        return -1;
      }
      if (b.id === primaryEstablishmentTagId) {
        return 1;
      }
      return 0;
    })
    .map((tag, i) => (
      <div
        className={`rounded-lg border ${
          tag.isPriority || primaryEstablishmentTagId === tag.id
            ? 'border-darkPurple bg-lightPurple text-darkPurple'
            : 'border-primaryTeal bg-lightGreen text-primaryTeal'
        }  flex flex-row justify-between px-4 items-center h-10 mr-3 mb-4`}
        key={i}
      >
        {onClickTagUpdatePriorityAsync && (
          <>
            {tag.isPriority || primaryEstablishmentTagId === tag.id ? (
              <AiFillStar
                size={20}
                className="cursor-pointer"
                onClick={() => handleClickPriorityAsync(tag, false)}
              />
            ) : (
              <AiOutlineStar
                size={20}
                className="cursor-pointer"
                onClick={() => handleClickPriorityAsync(tag, true)}
              />
            )}
          </>
        )}

        <div className="ml-2 flex justify-center">
          <span className="font-nunito text-base ">
            {tag.name?.toLowerCase()}
          </span>
        </div>

        <AiOutlineClose
          onClick={async () => await onClickTagDeleteIconAsync(tag.id!)}
          className="ml-2 cursor-pointer"
          size="20px"
        />
      </div>
    ));

  const flyout = (
    <div
      className={`-mt-3 bg-theme1 ${isInModal ? 'mr-0' : 'mr-6'}`}
      style={{
        scrollbarWidth: 'thin',
        height: 'auto',
        maxHeight: isInModal ? '172px' : 'auto',
        overflowY: isInModal ? 'hidden' : 'auto'
      }}
    >
      {isFlyoutOpen && (
        <div className="px-4 border rounded-lg flex-col flex h-72">
          <div className="flex flex-wrap py-4">
            {flyoutItems.length === 0 && inputValue.length > 0 ? (
              <div className="w-full flex flex-col items-center justify-items-center absolute top-48 -ml-2">
                <span>
                  Sorry, we couldn't find any tags for your search. Please try
                  searching again
                </span>
              </div>
            ) : (
              flyoutItems.map((tag, i) => (
                <TagCard
                  tag={tag}
                  isDisabled={isDisabled}
                  key={i}
                  onClick={handleFlyoutOnConfirmNewTagAsync}
                />
              ))
            )}
          </div>
          <div className="flex-grow flex" />
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row">
        <div className="relative w-full" ref={ref}>
          <div className="absolute top-4 left-6 z-10">
            <FaTags size={25} className="text-black" />
          </div>
          <Input
            className={`flex-grow rounded-tl-lg rounded-bl-lg rounded-br-none rounded-tr-none ${
              isInModal ? 'mr-0' : 'mr-6'
            } -mt-2`}
            placeholder={
              customPlaceholder
                ? customPlaceholder
                : 'Start typing to search tags'
            }
            color="text-black"
            size="lg"
            value={inputValue}
            skin="tagLabel"
            onChange={(e) => {
              setIsFlyoutOpen(true);
              setInputValue(e.currentTarget.value);
            }}
            onKeyDown={async (e) => await handleOnKeyDownAsync(e)}
            onFocus={() => {
              setIsFlyoutOpen(true);
            }}
            error={
              taggingValidationError &&
              !isFlyoutOpen &&
              taggingValidationError.tabMessage
            }
          />
          {flyout}
          <div className={`flex flex-wrap mt-3`}>
            {tagsElements.length > 0 && tagsElements}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TagSelector;
