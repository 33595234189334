import { Button, Spinner } from '@bindystreet/bindystreet.kit.react';
import { authHttpClient } from 'Colugo/provider/HttpClient';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from './Layout';

function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    async function verifyEmail() {
      if (oobCode) {
        try {
          setIsLoading(true);
          await authHttpClient.post('auth/confirmEmail', {
            json: { oobCode }
          });
        } catch (error) {
          setErrorMessage(
            'Email could not be verified. It may already be verified.'
          );
        } finally {
          setIsLoading(false);
        }
      }
    }

    verifyEmail();
  }, [oobCode]);

  function handleNavigation() {
    navigate('/');
  }

  return (
    <Layout>
      <div className="flex-col px-40 items-center justify-center">
        <div className="mt-4">
          <h2 className="heading-bold-2xl-onSurface">
            Thank you for verifying your account.
          </h2>
        </div>
        <div className="mt-2 heading-regular-m-onSurfaceVariant">
          Please continue to log in to Ember Business
        </div>
        <div className="flex flex-col items-start">
          <Button
            size="lg"
            type="submit"
            className="flex flex-row justify-center font-nunito items-center px-6 py-3 mt-6 mr-auto bg-primary w-60 rounded-md"
            onClick={handleNavigation}
          >
            <span className="heading-semibold-av-onPrimary w-full">
              {isLoading ? <Spinner color="white" /> : 'Continue to Ember'}
            </span>
          </Button>

          <div className="mt-2">
            {errorMessage && (
              <div className="text-red font-semibold text-base">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default VerifyEmail;
