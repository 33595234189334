import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import DashboardHeader from 'components/shared/DashboardHeader';
import { ReactComponent as PaymentCancelledIcon } from 'images/paymentCancelled.svg';
import { useNavigate } from 'react-router-dom';

function PaymentCanceled() {
  const navigate = useNavigate();

  return (
    <div className="bg-theme1 h-screen w-screen pt-56">
      <DashboardHeader isDropdownVisible={false} />
      <div className="flex-col justify-center items-center mx-auto text-center max-w-max">
        <PaymentCancelledIcon className="w-48 mx-auto" />
        <h3 className="font-inter font-bold text-lg mt-4">
          Changed Your Mind?
        </h3>
        <p className="text-onSurfaceVariant font-inter font-semibold text-lg mt-2 mx-auto w-4/5">
          Please visit our Business Plans page to continue with your selection.
        </p>
        <button
          className="bg-primary text-white rounded-lg px-8 py-2 text-base mt-4"
          onClick={() => navigate(PuffinRoutes.ManageBusiness)}
        >
          Return
        </button>
      </div>
    </div>
  );
}

export default PaymentCanceled;
