import { Button, Modal } from '@bindystreet/bindystreet.kit.react';
import { InputElement } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import { ILocationDetails } from 'Colugo/interfaces/games/ILocationDetails';
import { IAddress } from 'Colugo/interfaces/listing/IAddress';
import GoogleMapListingLocationContainer from 'components/listings/location/GoogleMapListingLocationContainer';
import { Location } from 'components/listings/location/Map';
import PlacesAutocomplete, {
  searchOpenCageAsync
} from 'components/listings/location/PlacesAutocomplete';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { formatAddress } from 'utility/general/formatHelpers';
import { validateFields } from 'utility/general/validators';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import ImprovedInput from './ImprovedInput';

type Props = {
  setIsModalOpen: (isModalOpen: boolean) => void;
  onConfirmLocation: (locationDetails: ILocationDetails) => void;
  initialLocationDetails?: ILocationDetails;
};

export interface AddressValidationErrors {
  houseName?: string;
  street?: string;
  postcode?: string;
}

enum TabIndex {
  One = 1,
  Two = 2,
  Three = 3
}

const addressRequiredErrors: Record<keyof IAddress, string> = {
  houseName: 'Please provide a name or number',
  street: 'Please provide a street name',
  postcode: 'Please provide a postcode',
  city: '',
  county: '',
  country: '',
  houseNumber: '',
  addressString: '',
  $type: '',
  id: '',
  createdAt: '',
  updatedAt: '',
  updatedBy: '',
  deletedAt: ''
};

function LocationModal(props: Props) {
  const { setIsModalOpen, onConfirmLocation, initialLocationDetails } = props;

  const { errorToast } = useErrorToast();
  const [currentTab, setCurrentTab] = useState<TabIndex>(
    !!initialLocationDetails?.address && !!initialLocationDetails.location
      ? TabIndex.Two
      : TabIndex.One
  );
  const [locationDetails, setLocationDetails] = useState<
    ILocationDetails | undefined
  >(initialLocationDetails);
  const [errors, setErrors] = useState<AddressValidationErrors | null>(null);

  const { isSuperAdmin } = useContext(UserContext);

  function onLocationChange(newLongitude: number, newLatitude: number) {
    if (locationDetails) {
      setLocationDetails({
        ...locationDetails,
        location: { latitude: newLatitude, longitude: newLongitude }
      });
    }
  }

  function setSelected(location: Location, address: IAddress) {
    setLocationDetails({
      address: address,
      location: { latitude: location.lat, longitude: location.lng }
    });
    setCurrentTab(TabIndex.Two);
  }

  function addressSectionInput(
    label: string,
    value: string | undefined,
    placeHolder: string,
    onChange: (e: React.FormEvent<InputElement>) => void,
    error?: string | undefined
  ) {
    return (
      <div className="mt-2 w-full">
        <ImprovedInput
          name={label}
          value={value}
          label={label}
          labelClassName={'heading-semibold-av-onSurface'}
          placeHolder={placeHolder}
          className={
            'body-regular-l-onSurface placeholder-surfaceContainerHighest focus:outline-none'
          }
          errorClassName={'heading-semibold-sm-red'}
          onChange={onChange}
          error={error}
          isError={Boolean(error)}
        />
      </div>
    );
  }

  const manualAddressInput = locationDetails && (
    <div>
      {addressSectionInput(
        'Name / Number*',
        locationDetails?.address?.houseName,
        '57',
        (e) =>
          setLocationDetails({
            ...locationDetails,
            address: {
              ...locationDetails?.address,
              houseName: e.currentTarget.value
            }
          }),
        errors?.houseName
      )}
      {addressSectionInput(
        'Street Name*',
        locationDetails?.address?.street,
        'Broadway Market',
        (e) =>
          setLocationDetails({
            ...locationDetails,
            address: {
              ...locationDetails?.address,
              street: e.currentTarget.value
            }
          }),
        errors?.street
      )}
      <div className="flex flex-row">
        {addressSectionInput(
          'County',
          locationDetails?.address?.county,
          'Hackney',
          (e) =>
            setLocationDetails({
              ...locationDetails,
              address: {
                ...locationDetails?.address,
                county: e.currentTarget.value
              }
            })
        )}
        <div className="w-8" />
        {addressSectionInput(
          'City',
          locationDetails?.address?.city,
          'London',
          (e) =>
            setLocationDetails({
              ...locationDetails,
              address: {
                ...locationDetails?.address,
                city: e.currentTarget.value
              }
            })
        )}
      </div>
      <div className="flex flex-row">
        {addressSectionInput(
          'Postcode *',
          locationDetails?.address?.postcode,
          'E8 4PH',
          (e) =>
            setLocationDetails({
              ...locationDetails,
              address: {
                ...locationDetails?.address,
                postcode: e.currentTarget.value
              }
            }),
          errors?.postcode
        )}
        <div className="w-8" />
        {addressSectionInput(
          'Country',
          locationDetails?.address?.country,
          'United Kingdom',
          (e) =>
            setLocationDetails({
              ...locationDetails,
              address: {
                ...locationDetails?.address,
                country: e.currentTarget.value
              }
            })
        )}
      </div>
    </div>
  );

  const tab1 = currentTab === TabIndex.One && (
    <div>
      {titleAndSubtitle(
        'Set Your Location',
        'Search for your address below. If it does not appear, please select the "Add Manually" option instead.'
      )}
      <div className="-ml-5">
        <PlacesAutocomplete
          setSelected={setSelected}
          additionalOption={'Add Address Manually'}
          onClickAdditionalOption={() => setCurrentTab(TabIndex.Two)}
        />
      </div>
    </div>
  );

  const tab2 = currentTab === TabIndex.Two && (
    <div>
      {titleAndSubtitle(
        'Confirm Address Details',
        'Please add and review your details before continuing.'
      )}
      <div className="mt-4">{manualAddressInput}</div>
    </div>
  );

  const tab3 = currentTab === TabIndex.Three && (
    <div className="mb-8 rounded-md h-full">
      {titleAndSubtitle(
        'Confirm Map Location',
        'Drag the map so the pin matches the exact location of the business.'
      )}
      <GoogleMapListingLocationContainer
        onLocationChange={onLocationChange}
        shouldShowAutocomplete={false}
        latitude={locationDetails?.location?.latitude}
        longitude={locationDetails?.location?.longitude}
      />
    </div>
  );

  function titleAndSubtitle(title: string, subtitle: string) {
    return (
      <div className="flex flex-col mb-4">
        <span className="text-lg font-bold mt-4">{title}</span>
        <span className="text-onSurfaceVariant">{subtitle}</span>
      </div>
    );
  }

  function getTopTab(index: TabIndex) {
    return (
      <div
        className={`flex flex-row items-start border-4 w-full rounded-full ${
          index <= currentTab ? 'border-primary' : 'border-primaryContainer'
        } ${index > 1 && 'ml-8'}`}
      />
    );
  }

  function handleClickBackButton() {
    setErrors({});
    switch (currentTab) {
      case TabIndex.One:
        setIsModalOpen(false);
        break;
      case TabIndex.Two:
        setLocationDetails({ address: {}, location: {} });
        setCurrentTab(TabIndex.One);
        break;
      case TabIndex.Three:
        setCurrentTab(TabIndex.Two);
        break;
    }
  }

  async function checkAndSearchLocationAsync() {
    if (locationDetails?.address) {
      const formattedAddress = formatAddress(locationDetails.address);
      const response = await searchOpenCageAsync(formattedAddress!);
      if (response.results && response.results.length > 0) {
        setLocationDetails({
          ...locationDetails,
          location: {
            latitude: response.results[0].geometry.lat,
            longitude: response.results[0].geometry.lng
          }
        });
      }
      const required = [
        'houseName',
        'street',
        'postcode'
      ] as (keyof IAddress)[];
      const validationErrors = validateFields(
        locationDetails.address,
        required,
        addressRequiredErrors
      );
      if (!isSuperAdmin && Object.keys(validationErrors).length > 0) {
        return setErrors(
          validationErrors as unknown as AddressValidationErrors
        );
      }
      setCurrentTab(currentTab + 1);
    }
  }

  async function handleClickNextButtonAsync() {
    switch (currentTab) {
      case TabIndex.Two:
        await checkAndSearchLocationAsync();
        break;
      case TabIndex.Three:
        if (!locationDetails) {
          errorToast('No location found, please try again.');
          return;
        }
        onConfirmLocation(locationDetails);
        setIsModalOpen(false);
        setCurrentTab(1);
        break;
    }
  }

  return (
    <div className="relative">
      <Modal
        overlay={true}
        padding="none"
        isMenuOpen={true}
        className="rounded-xl -mt-28"
        size="xl"
        position="fixedCenter"
        styles={{
          overflowY: 'hidden',
          height: '768px'
        }}
      >
        <div className="h-full">
          <div className="px-10 pt-10">
            <div className="flex flex-row w-full">
              <div className="text-2xl font-bold">Add Address</div>
              <IoClose
                size={22}
                className="ml-auto text-onSurfaceVariant cursor-pointer"
                onClick={() => setIsModalOpen(false)}
              />
            </div>
            <div className="flex flex-row mt-2">
              {getTopTab(TabIndex.One)}
              {getTopTab(TabIndex.Two)}
              {getTopTab(TabIndex.Three)}
            </div>
            {tab1}
            {tab2}
            {tab3}
          </div>
          <div className="absolute bottom-0 w-full bg-pageColour flex flex-row px-10 pb-4 pt-4 border-t border-outline">
            <div className="flex flex-row font-nunito text-base font-bold">
              <Button
                className="cursor-pointer px-4 bg-grayContainer text-onSurface border-onSurface border font-bold"
                onClick={handleClickBackButton}
              >
                {currentTab === TabIndex.One && 'Cancel'}
                {currentTab === TabIndex.Two && 'Reset'}
                {currentTab === TabIndex.Three && 'Back'}
              </Button>
            </div>
            <div className="flex flex-grow" />
            {currentTab !== TabIndex.One && (
              <div className="flex flex-row font-nunito text-base font-bold text-white">
                <Button
                  className=" cursor-pointer px-4 bg-primary"
                  onClick={async () => await handleClickNextButtonAsync()}
                >
                  {currentTab === TabIndex.Two && 'Next'}
                  {currentTab === TabIndex.Three && 'Confirm'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default LocationModal;
