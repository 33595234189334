import { ICategoryUserView } from 'Colugo/interfaces/common';

export const {
  NODE_ENV,
  REACT_APP_VERSION,
  REACT_APP_MOCKED: IS_APP_MOCKED,
  REACT_APP_API_URL: BUDGIE_API_URL = '',
  REACT_APP_IPSTACK_KEY: IPSTACK_KEY = '',
  REACT_APP_ENV: APP_ENV = 'LOCAL',
  REACT_APP_GOOGLEPLACES_KEY: GOOGLEPLACES_KEY = '',
  REACT_APP_OPENCAGE_KEY: APP_OPENCAGE_KEY = '',
  REACT_APP_GOOGLE_CLIENT_ID: GOOGLE_CLIENT_ID = ''
} = process.env;

export const localUser = 'me';
export const localToken = 'token';
export const newTokenHeaderKeyValueFail = 'FAIL';
export const newTokenHeaderKeyValue = 'NewToken';
export const budgieUserErrorCode = 'BudgieUserErrorCode';
export const MAXIMUM_FILE_SIZE = 5e7;
export const EmptyGuid = '00000000-0000-0000-0000-000000000000';
export const defaultDate = new Date('1970-01-01T00:00:00Z');
export const currentIsoDateString = new Date().toISOString().split('T')[0];
export const tryAgainLaterText = 'please try again later';

export const budgieErrorCodes = {
  UpdatedAtMismatchErrorCode: '2001'
};

export enum MapProvider {
  GoogleMaps
}

export const MAP_PROVIDER: MapProvider = MapProvider.GoogleMaps;
export const defaultLatitude: number = 51.530154739043354;
export const defaultLongitude: number = -0.07538089720926626;
export const DEFAULT_ZOOM_LEVEL = 14;

export function getEmailLink(subject: string) {
  return `mailto:hello@emberguides.com?subject=${encodeURIComponent(subject)}`;
}

export const categories: ICategoryUserView[] = [
  {
    id: '7be72c7c-cf0b-ec11-b563-281878c7a1a3',
    name: 'Sport & Health',
    key: 'SportHealth',
    colour: '#6AA6ED'
  },
  {
    id: '8f0db084-cf0b-ec11-b563-281878c7a1a3',
    name: 'Activities',
    key: 'Activities',
    colour: '#EF8088'
  },
  {
    id: 'a5d278de-4fae-eb11-a7ad-281878fba236',
    name: 'Culture',
    key: 'Culture',
    colour: '#AC7856'
  },
  {
    id: '38eb0dfb-4fae-eb11-a7ad-281878fba236',
    name: 'Animals & Nature',
    key: 'AnimalsNature',
    colour: '#A0AD3D'
  },
  {
    id: '267f9b02-50ae-eb11-a7ad-281878fba236',
    name: 'Food & Drink',
    key: 'FoodDrink',
    colour: '#EF864C'
  },
  {
    id: 'acfe9713-afa3-ec11-a99b-c8966570c90a',
    name: 'Hobbies & How-to',
    key: 'Hobbies',
    colour: '#51BECB'
  }
];
