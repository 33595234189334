import { IAnalytics } from 'Colugo/interfaces/common/IAnalytics';
import { requestResponse } from 'Colugo/provider/HttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';
import { IAnalyticsSeriesData } from 'Colugo/interfaces/common/IAnalyticsSeriesData';
import {
  swrRequestResponse,
  useGetHttpClient
} from 'Colugo/provider/useGetHttpClient';

@scoped(Lifecycle.ContainerScoped)
class AnalyticsOperations extends AbstractOperations {
  protected basePath: string = 'analytics';

  public async getAnalyticsAsync(
    entityId?: string,
    nOfDays?: string
  ): requestResponse<IAnalytics> {
    return await this.executeGet(`${entityId}?nOfDays=${nOfDays}`);
  }

  public async getSeriesDataAsync(
    entityId?: string,
    nOfDays?: string,
    videoId?: string
  ): requestResponse<IAnalyticsSeriesData> {
    return await this.executeGet(
      `seriesData/${entityId}?nOfDays=${nOfDays}${
        videoId ? '&videoId=' + videoId : ''
      }`
    );
  }
}

const useReqGetAnalyticsAsync = (
  entityId?: string,
  nOfDays: string = '7'
): swrRequestResponse<IAnalytics> => {
  const response = useGetHttpClient(
    entityId ? `analytics/${entityId}?nOfDays=${nOfDays}` : null,
    {
      revalidateOnFocus: false
    },
    false
  );
  return response;
};

export default AnalyticsOperations;
export { useReqGetAnalyticsAsync };
