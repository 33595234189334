import { IAnalytics } from 'Colugo/interfaces/common/IAnalytics';
import { IAnalyticsSeriesData } from 'Colugo/interfaces/common/IAnalyticsSeriesData';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import AnalyticsOperations from 'Colugo/operations/analytics/AnalyticsOperations';
import ImprovedFlyoutMenu, {
  FlyoutMenuItem
} from 'components/shared/ImprovedFlyoutMenu';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { container } from 'tsyringe';
import BlurComponent from '../common/BlurComponent';
import Skeleton from 'react-loading-skeleton';
import {
  dateRangeFlyoutMenuItems,
  getUpdateDiff,
  renderMetric
} from 'utility/analytics/analyticsUtils';

const analyticsOperations = container.resolve(AnalyticsOperations);

type SeriesData = { date: string; count: number; cumulative?: number };

enum ChartType {
  LineChart = 'Line Chart',
  BarChart = 'Bar Chart',
  Cumulative = 'Cumulative'
}

const chartTypeFlyoutMenuItems: FlyoutMenuItem[] = [
  { label: ChartType.LineChart, value: ChartType.LineChart },
  { label: ChartType.BarChart, value: ChartType.BarChart },
  { label: ChartType.Cumulative, value: ChartType.Cumulative }
];

type Props = {
  entity?: IListing | IEvent;
  initialAnalytics?: IAnalytics;
  title: string;
  events?: IEvent[];
  isPremiumOrPlusBusiness: boolean;
};

export default function Analytics(props: Props) {
  const { entity, initialAnalytics, title, events, isPremiumOrPlusBusiness } =
    props;
  const [selectedNOfDays, setSelectedNOfDays] = useState(
    dateRangeFlyoutMenuItems[0]
  );
  const [selectedChartType, setSelectedChartType] = useState<FlyoutMenuItem>(
    chartTypeFlyoutMenuItems[0]
  );
  const [analytics, setAnalytics] = useState<IAnalytics | undefined>();
  const [analyticsSeriesData, setAnalyticsSeriesData] = useState<
    IAnalyticsSeriesData | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedEvent, setSelectedEvent] = useState<
    FlyoutMenuItem | undefined
  >();

  const eventFlyoutMenuItems: FlyoutMenuItem[] =
    events?.map((e) => {
      return { label: e.name, value: e.id } as FlyoutMenuItem;
    }) || [];

  const getAnalyticsForFlyoutOptionAsync = useCallback(
    async (fmi) => {
      setSelectedNOfDays(fmi);
      setIsLoading(true);
      const entityId = entity?.id || selectedEvent?.value || events?.[0].id;
      const videoId =
        entity?.video?.id ||
        events?.find((e) => e.id === selectedEvent?.value)?.video?.id;
      const { data: localAnalytics, error: analyticsError } =
        await analyticsOperations.getAnalyticsAsync(entityId, fmi.value);
      const { data: seriesData, error: seriesError } =
        await analyticsOperations.getSeriesDataAsync(
          entityId,
          fmi.value,
          videoId
        );
      setIsLoading(false);
      if (analyticsError || !localAnalytics) {
        toast.error(
          'Unable to fetch ' +
            title +
            ' analytics, please refresh and try again.'
        );
        return;
      }
      if (seriesError || !seriesData) {
        toast.error(
          'Unable to fetch ' +
            title +
            ' series analytics, please refresh and try again.'
        );
        return;
      }
      setAnalytics(localAnalytics);
      setAnalyticsSeriesData(seriesData);
    },
    [events, entity?.id, entity?.video?.id, selectedEvent?.value, title]
  );

  useEffect(() => {
    getAnalyticsForFlyoutOptionAsync(dateRangeFlyoutMenuItems[0]);
  }, [getAnalyticsForFlyoutOptionAsync]);

  const analyticsToRender = analytics ?? initialAnalytics;

  const horizontalDivider = (
    <div className="border-b border-outline my-4" style={{ width: '224px' }} />
  );
  let seriesData: SeriesData[] | undefined;

  function parseDate(date?: string) {
    if (!date) {
      return '';
    }
    const dateComponents = date.split('-');
    return dateComponents[1] + '/' + dateComponents[2];
  }

  function getSeriesForAnalytic(analytic: number[] | undefined): SeriesData[] {
    if (!analytic) {
      return (
        analyticsSeriesData?.xLabels.map((xLabel) => {
          return {
            date: parseDate(xLabel),
            count: 0
          };
        }) || []
      );
    }
    let data: SeriesData[] = analytic.map((count, index) => {
      return {
        date: parseDate(analyticsSeriesData?.xLabels[index]),
        count: count
      };
    });
    data = data.map((entry, index) => ({
      ...entry,
      cumulative: data
        .slice(0, index + 1)
        .reduce((sum, item) => sum + item.count, 0)
    }));
    return data;
  }

  switch (selectedIndex) {
    case 0: {
      seriesData = getSeriesForAnalytic(analyticsSeriesData?.impressions);
      break;
    }
    case 1: {
      seriesData = getSeriesForAnalytic(analyticsSeriesData?.clicks);
      break;
    }
    case 2: {
      seriesData = getSeriesForAnalytic(analyticsSeriesData?.collects);
      break;
    }
    case 3: {
      seriesData = getSeriesForAnalytic(analyticsSeriesData?.shares);
      break;
    }
    case 4: {
      seriesData = getSeriesForAnalytic(analyticsSeriesData?.links);
      break;
    }
    case 5: {
      seriesData = getSeriesForAnalytic(analyticsSeriesData?.videoPlays);
      break;
    }
    default: {
    }
  }
  seriesData = seriesData || [];

  const maxCount = Math.max(...seriesData.map((entry) => entry.count));
  const maxYDomainValue = maxCount * 1.2;

  const yOffset = 365;
  const xOffset = 65;

  const labels = [
    'Impressions',
    'Clicks',
    'Saves',
    'Shares',
    'Links',
    'VideoPlays'
  ];

  const analyticsChart = (
    <div>
      <div className="flex flex-row mt-5">
        {labels.map((l, index) => {
          return (
            <div
              className={`ml-4 py-2 px-4 rounded-lg font-inter cursor-pointer hover:bg-primaryContainer hover:text-primary ${
                index === selectedIndex
                  ? 'text-white bg-primary'
                  : 'text-onSurfaceVariant bg-surfaceContainerLow'
              }`}
              onClick={() => setSelectedIndex(index)}
              key={index}
            >
              {l}
            </div>
          );
        })}
      </div>
      {isLoading ? (
        <div className="m-4">
          <Skeleton width={830} height={388} />
        </div>
      ) : (
        <div className="mt-5 relative">
          <ComposedChart
            width={850}
            height={400}
            data={seriesData}
            style={{ cursor: 'pointer' }}
          >
            <XAxis dataKey="date" stroke="#878897" />
            <YAxis
              domain={[
                0,
                maxYDomainValue === 0 ? 1 : maxYDomainValue.toFixed(0)
              ]}
              stroke="#878897"
            />
            <CartesianGrid vertical={false} />
            <Tooltip />
            {selectedChartType.value === ChartType.LineChart &&
              maxYDomainValue !== 0 &&
              seriesData.map((entry, index) => {
                const xPosition =
                  (selectedNOfDays.xSpaceBetween / seriesData.length) * index +
                  xOffset;
                const yPosition = yOffset * (1 - entry.count / maxCount);
                return (
                  <line
                    key={index}
                    x1={xPosition}
                    y1={yOffset}
                    x2={xPosition}
                    y2={yPosition}
                    stroke="#D9D9D9"
                    strokeDasharray="8 8"
                  />
                );
              })}
            {selectedChartType.value === ChartType.BarChart && (
              <Bar dataKey="count" fill="#FF566F" barSize={40} />
            )}
            {selectedChartType.value === ChartType.LineChart && (
              <Line
                type="linear"
                dataKey="count"
                strokeWidth={3}
                stroke="#FF566F"
                dot={{ fill: '#FF566F', r: 4 }}
              />
            )}
            {selectedChartType.value === ChartType.Cumulative && (
              <Line
                type="linear"
                dataKey="cumulative"
                strokeWidth={3}
                stroke="#FF566F"
                dot={{ fill: '#FF566F', r: 4 }}
              />
            )}
          </ComposedChart>
        </div>
      )}
    </div>
  );

  const businessDiff = getUpdateDiff(analytics?.timeComputed);

  return (
    <div className="relative">
      {!isPremiumOrPlusBusiness && <BlurComponent height={'560px'} />}
      <div className="flex flex-col bg-white rounded-lg border border-outline mt-4 font-inter">
        <div className="flex flex-row py-4 px-6 border-b border-outline relative">
          <div className="text-xl font-bold mt-3">{title}</div>
          {!isLoading && (
            <span className="mt-4 pt-0.5 mb-1.5 ml-4 text-sm text-onSurfaceVariant">
              {businessDiff}
            </span>
          )}
          {eventFlyoutMenuItems.length > 0 && (
            <div className="absolute top-4 right-96 mr-20 pr-2 z-20">
              <ImprovedFlyoutMenu
                flyoutMenuItems={eventFlyoutMenuItems}
                onChange={(fmiDate) => setSelectedEvent(fmiDate)}
                selectedItem={selectedEvent || eventFlyoutMenuItems[0]}
                wrapperClassName="rounded-lg"
              />
            </div>
          )}
          <div className="absolute right-72 z-10">
            <ImprovedFlyoutMenu
              flyoutMenuItems={chartTypeFlyoutMenuItems}
              onChange={setSelectedChartType}
              selectedItem={selectedChartType}
              wrapperClassName="rounded-lg"
              minWidth="170px"
            />
          </div>
          <div className="absolute right-6 z-10">
            <ImprovedFlyoutMenu
              flyoutMenuItems={dateRangeFlyoutMenuItems}
              onChange={getAnalyticsForFlyoutOptionAsync}
              selectedItem={selectedNOfDays}
              wrapperClassName="rounded-lg"
            />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex flex-col">
            <div className="flex flex-col px-2 py-6">
              {renderMetric(
                'Impressions',
                analyticsToRender?.impressions,
                Number(selectedNOfDays.value),
                '224px',
                false,
                isLoading
              )}
              {horizontalDivider}
              {renderMetric(
                'Total Clicks',
                analyticsToRender?.clicks,
                Number(selectedNOfDays.value),
                '224px',
                false,
                isLoading
              )}
              {horizontalDivider}
              {renderMetric(
                'Click-through Rate',
                {
                  ...analyticsToRender?.clickThroughRate,
                  count: Number(
                    (
                      (analyticsToRender?.clickThroughRate?.count || 0) * 100
                    ).toFixed(0)
                  )
                },
                Number(selectedNOfDays.value),
                '224px',
                true,
                isLoading
              )}
            </div>
          </div>
          <div className="border-l border-outline ml-2" />
          <div className="">{analyticsChart}</div>
        </div>
      </div>
    </div>
  );
}
