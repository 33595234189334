import { SubscriptionType } from '../subscriptions/ISubscription';

export interface ICreateCheckoutSessionRequest {
  priceId: string;
  customerId: string;
  listingId: string;
  listingName: string;
  subscriptionType: SubscriptionType;
}

export enum CheckoutSessionType {
  None,
  Subscription,
  Upgrade,
  Downgrade
}
