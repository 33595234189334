import React, { useContext, useState } from 'react';
import { AuthContext } from 'provider/auth/authProvider';
import { Form, Button } from '@bindystreet/bindystreet.kit.react';
import { useNavigate } from 'react-router-dom';
import Validator from 'utility/general/RegexValidator';
import { InputFormEvent } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import Layout from './Layout';
import { FormError } from './SignUp';
import { toast } from 'react-toastify';
import { IoClose } from 'react-icons/io5';
import ImprovedInput from '../shared/ImprovedInput';

function ForgotPassword() {
  const {
    handleForgotPasswordAsync,
    inputs,
    setInputs,
    errors,
    isLoading,
    resetState
  } = useContext(AuthContext);

  //Hooks
  const navigate = useNavigate();

  //State
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);

  function validateFields() {
    let isSubmitValid = true;
    let errors: FormError[] = [];

    if (!Validator.isEmailValid(inputs.email)) {
      errors.push({
        field: 'email',
        message: 'Please provide a valid email address'
      });
      isSubmitValid = false;
    }

    setFormErrors(errors);
    return isSubmitValid;
  }

  async function handleSubmitAsync(e: React.FormEvent<HTMLFormElement>) {
    const isSubmitValid = validateFields();
    if (!isSubmitValid) {
      return;
    }
    await handleForgotPasswordAsync();
    resetState();
    navigate('/');
    toast.success('Reset Link Sent', {
      hideProgressBar: true,
      closeButton: (
        <IoClose color={'#FFFFFF'} size={24} style={{ marginTop: '6px' }} />
      )
    });
  }

  function handleChange(e: InputFormEvent) {
    const { name, value } = e.target as any;
    setInputs((prev: any) => ({ ...prev, [name]: value }));
    if (name === 'email') {
      setIsEmailValid(Validator.isEmailValid(value));
    }
  }

  return (
    <Layout>
      <div className="flex-col px-40 items-center justify-center">
        <div className="w-full flex flex-col gap-2">
          <h2 className="heading-bold-2xl-onSurface">Forgot Password</h2>
          <span className="heading-regular-l-onSurfaceVariant">
            If your email exists, we will send you a reset link to reset your
            password. Please make sure to check your spam/junk folder.
          </span>
        </div>
        <div className="mt-6" style={{ maxWidth: '556px' }}>
          <Form
            onSubmit={handleSubmitAsync}
            formClassName={'flex flex-col gap-6'}
          >
            <ImprovedInput
              name={'email'}
              value={inputs.email}
              label={'Email address'}
              labelClassName={'heading-semibold-av-onSurfaceVariant'}
              placeHolder={'Enter your email address'}
              className={
                'body-regular-l-onSurface placeholder-surfaceContainerHighest focus:outline-none'
              }
              errorClassName={'heading-semibold-sm-red'}
              onChange={handleChange}
              isError={
                (inputs.email.length > 0 && !isEmailValid) ||
                formErrors.some((e) => e.field === 'email')
              }
              error={
                formErrors.find((fe) => fe.field === 'email')?.message ||
                'Please provide a valid email address'
              }
            />
            <div className="lg:w-1/2">
              <Button
                size="lg"
                type="submit"
                skin="transparent"
                isLoading={isLoading}
                className="flex flex-row justify-center items-center px-1 w-full bg-primary cursor-pointer"
              >
                <label
                  className={'body-bold-av-onPrimary w-full cursor-pointer'}
                >
                  Reset password
                </label>
              </Button>
            </div>
            {errors?.length > 0 &&
              errors.map((error: string, idx: number) => (
                <p key={idx} className="heading-semibold-sm-red">
                  {error}
                </p>
              ))}
          </Form>
        </div>
      </div>
    </Layout>
  );
}

export default ForgotPassword;
