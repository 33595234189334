import {
  FlyoutMenu,
  FlyoutMenuItem,
  Input
} from '@bindystreet/bindystreet.kit.react';
import { ActionLinkType, IEvent } from 'Colugo/interfaces/event/IEvent';
import {
  ILinkItem,
  linkUrlType
} from 'Colugo/interfaces/games/blocks/IExternalLinkBlockItem';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { EnumHelper } from 'Colugo/utility/helpers';
import { Facebook, Instagram, TikTok, Twitter } from 'images/socialMediaIcons';
import { isEqual } from 'lodash';
import { useState } from 'react';
import { AiOutlinePhone } from 'react-icons/ai';
import { BsGlobe } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import Validator from 'utility/general/RegexValidator';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import GenerativeLinkInputs from './GenerativeLinkInputs';

export enum LinkType {
  Action = 0,
  Website = 1,
  Facebook = 2,
  Twitter = 3,
  Instagram = 4,
  TikTok = 5
}

export enum ParsedActionLinkType {
  LearnMore = 'Find Out More',
  BookNow = 'Book Now',
  BookTickets = 'Book Tickets',
  ViewMenu = 'View Menu',
  Contact = 'Contact',
  EnterGiveaway = 'Enter Giveaway',
  WhatsOn = `What's On`
}

export const defaultActionLinkType: ILinkItem = {
  actionLinkType: ActionLinkType.BookNow,
  value: ''
};

type Props = {
  localEntity: IEvent | IListing;
  setLocalEntity: (localEntity: IEvent | IListing) => void;
  updateEntityAsync: (updatedEntity: IEvent | IListing) => Promise<boolean>;
  name: 'Business' | 'Event';
};

function LinkContainer(props: Props) {
  const { localEntity, updateEntityAsync, setLocalEntity, name } = props;
  const { errorToast } = useErrorToast();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [otherLinkFlyoutOpenIndex, setOtherLinkFlyoutOpenIndex] = useState<
    number | undefined
  >(undefined);
  const [selectedActionType, setSelectedActionType] = useState<number>(
    localEntity.social?.actionLinkType !== undefined
      ? localEntity.social.actionLinkType - 1
      : ActionLinkType.BookNow
  );
  const [otherLinks, setOtherLinks] = useState<ILinkItem[]>(
    localEntity.social?.otherLinks ?? [defaultActionLinkType]
  );

  const [actionLink, setActionLink] = useState(
    localEntity.social?.actionLinkUrl || ''
  );
  const [socialLinks, setSocialLinks] = useState({
    Facebook:
      localEntity.social?.socialLinks?.find(
        (l) => l.urlType === linkUrlType.Facebook
      )?.value || '',
    Twitter:
      localEntity.social?.socialLinks?.find(
        (l) => l.urlType === linkUrlType.Twitter
      )?.value || '',
    Instagram:
      localEntity.social?.socialLinks?.find(
        (l) => l.urlType === linkUrlType.Instagram
      )?.value || '',
    TikTok:
      localEntity.social?.socialLinks?.find(
        (l) => l.urlType === linkUrlType.TikTok
      )?.value || ''
  });

  const options = EnumHelper.getEnumValuesForFlyout(ParsedActionLinkType);

  const validateLinkItem = (linkItem: string, property: string | LinkType) => {
    let isValid: boolean = true;
    switch (property) {
      case LinkType.Action:
      case LinkType.Website:
        isValid = Validator.isUrlValid(linkItem);
        break;
      case 'Facebook':
      case LinkType.Facebook:
        isValid = Validator.isFacebookLink(linkItem);
        break;
      case 'Twitter':
      case LinkType.Twitter:
        isValid = Validator.isTwitterLink(linkItem);
        break;
      case 'Tiktok':
      case LinkType.TikTok:
        isValid = Validator.isTiktokLink(linkItem);
        break;
      case 'Instagram':
      case LinkType.Instagram:
        isValid = Validator.isInstagramLink(linkItem);
        break;
    }
    return linkItem && !isValid && 'URL invalid, please review and update';
  };

  async function handleFlyoutOnChangeAsync(e: FlyoutMenuItem) {
    setSelectedActionType(e.key!);
    if (!validateLinkItem(actionLink, LinkType.Action)) {
      const updatedEntity: IEvent | IListing = {
        ...localEntity,
        social: {
          ...localEntity.social,
          actionLinkUrl: actionLink,
          actionLinkType: e.key! + 1
        }
      };
      setLocalEntity(updatedEntity);
      const isSuccesful = await updateEntityAsync(updatedEntity);
      if (!isSuccesful) {
        setLocalEntity(localEntity);
      }
    }
  }

  async function handleOtherLinksFlyoutOnChangeAsync(
    e: FlyoutMenuItem,
    index: number
  ) {
    const updatedOtherLinks = otherLinks.map((link, i) => {
      if (i === index) {
        link.actionLinkType = e.key! + 1;
      }
      return link;
    });

    setOtherLinks(updatedOtherLinks);

    if (!validateLinkItem(otherLinks[index]?.value ?? '', LinkType.Action)) {
      const updatedEntity: IEvent | IListing = {
        ...localEntity,
        social: {
          ...localEntity.social,
          otherLinks: updatedOtherLinks
        }
      };
      setLocalEntity(updatedEntity);
      const isSuccesful = await updateEntityAsync(updatedEntity);
      if (!isSuccesful) {
        setLocalEntity(localEntity);
        setOtherLinks(otherLinks);
      }
    }
  }

  async function handleOtherLinksActionLinkAsync(index: number) {
    const validationErrorMessage = validateLinkItem(
      otherLinks[index]?.value ?? '',
      LinkType.Action
    );

    if (validationErrorMessage) {
      errorToast(validationErrorMessage);
    } else {
      const updatedEntity: IEvent | IListing = {
        ...localEntity,
        social: {
          ...localEntity.social,
          otherLinks: otherLinks
        }
      };
      setLocalEntity(updatedEntity);
      const isSuccesful = await updateEntityAsync(updatedEntity);
      if (!isSuccesful) {
        setLocalEntity(localEntity);
      }
    }
  }

  async function handleRemoveOtherLinksAsync(index: number) {
    const updatedLinks = otherLinks.filter((_, i) => i !== index);
    setOtherLinks(updatedLinks);

    const updatedEntity: IEvent | IListing = {
      ...localEntity,
      social: {
        ...localEntity.social,
        otherLinks: updatedLinks
      }
    };
    setLocalEntity(updatedEntity);
    const isSuccesful = await updateEntityAsync(updatedEntity);
    if (!isSuccesful) {
      setOtherLinks(otherLinks);
      setLocalEntity(localEntity);
    }
  }

  async function handleActionLinkChangeAsync() {
    if (!validateLinkItem(actionLink, LinkType.Action)) {
      const updatedEntity: IEvent | IListing = {
        ...localEntity,
        social: {
          ...localEntity.social,
          actionLinkUrl: actionLink,
          actionLinkType: selectedActionType + 1
        }
      };
      setLocalEntity(updatedEntity);
      const isSuccesful = await updateEntityAsync(updatedEntity);
      if (!isSuccesful) {
        setLocalEntity(localEntity);
      }
    }
  }

  function onChangeSocialLink(
    platform: 'Facebook' | 'Instagram' | 'Twitter' | 'TikTok',
    link: string
  ) {
    setSocialLinks({
      ...socialLinks,
      [platform]: link
    });
  }

  async function onUpdateSocialLinkAsync(urlType: string) {
    if (!validateLinkItem(socialLinks[urlType], urlType)) {
      const updatedLinks = localEntity.social?.socialLinks
        ? [...localEntity.social?.socialLinks]
        : [];

      const index = updatedLinks.findIndex(
        (l) => linkUrlType[l.urlType!] === urlType
      );

      const newLink: ILinkItem = {
        value: socialLinks[urlType],
        urlType: linkUrlType[urlType]
      };

      if (index !== -1) {
        updatedLinks[index] = newLink;
      } else {
        updatedLinks.push(newLink);
      }

      const updatedEntity: IEvent | IListing = {
        ...localEntity,
        social: {
          ...localEntity.social,
          socialLinks: updatedLinks
        }
      };
      setLocalEntity(updatedEntity);
      const isSuccesful = await updateEntityAsync(updatedEntity);
      if (!isSuccesful) {
        setLocalEntity(localEntity);
      }
    }
  }

  function getSocialLink(
    component: JSX.Element,
    name: 'Facebook' | 'Instagram' | 'Twitter' | 'TikTok',
    value: string,
    linkType: LinkType
  ) {
    return (
      <div className="flex flex-row relative">
        <div className="w-32 items-center flex absolute top-6 left-0">
          {component}
          <span className="text-primary-dark font-nunito text-base font-normal leading-5 ">
            {name}
          </span>
        </div>
        <div className="mr-4 w-full ml-28">
          <Input
            color="text-black"
            size="lg"
            value={value}
            onChange={(e) => onChangeSocialLink(name, e.currentTarget.value)}
            onBlurWithinCharacterLimit={() => onUpdateSocialLinkAsync(name)}
            error={validateLinkItem(value, linkType)}
            placeholder="Add social link"
          />
        </div>
      </div>
    );
  }

  function renderInput(index: number) {
    const handleInputChange = (url: string) => {
      const updatedActionTypes = [...otherLinks];
      updatedActionTypes[index] = {
        ...updatedActionTypes[index],
        value: url
      };
      setOtherLinks(updatedActionTypes);
    };

    return (
      <Input
        color="text-black"
        size="lg"
        value={otherLinks[index]?.value ?? ''}
        placeholder="Enter action link"
        onChange={(e) => handleInputChange(e.currentTarget.value)}
        onBlurWithinCharacterLimit={() =>
          handleOtherLinksActionLinkAsync(index)
        }
        error={validateLinkItem(
          otherLinks[index]?.value ?? '',
          LinkType.Action
        )}
      />
    );
  }

  function renderFlyoutMenu(index: number) {
    const isOpen = otherLinkFlyoutOpenIndex === index;
    const activeIndexKey =
      (otherLinks[index]?.actionLinkType &&
        otherLinks[index].actionLinkType! - 1) ??
      defaultActionLinkType.actionLinkType;

    return (
      <div className={`w-60 mr-4`} style={{ height: 60 }}>
        <FlyoutMenu
          key={index}
          isEqual={isEqual}
          size={'sm'}
          isMenuOpen={isOpen}
          setIsMenuOpen={() =>
            setOtherLinkFlyoutOpenIndex(isOpen ? undefined : index)
          }
          defaultPlaceholder="Select Type"
          activeKey={activeIndexKey}
          items={options}
          onChange={(e) => {
            handleOtherLinksFlyoutOnChangeAsync(e, index);
          }}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col rounded-lg px-6 py-8 w-full">
      <div className="text-inter text-2xl font-bold leading-9 text-left mb-6">
        Action Link
      </div>
      <span className="text-primary-charcoal font-inter text-base font-bold leading-6">
        Action Link
      </span>
      <div className="flex flex-row mt-2 border-b-2 border-gray pb-8 ">
        <div className={`w-64 mr-4`} style={{ height: 60 }}>
          <FlyoutMenu
            isEqual={isEqual}
            size={'md'}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            defaultPlaceholder="Select Type"
            activeKey={selectedActionType ?? ActionLinkType.BookNow}
            items={options}
            onChange={handleFlyoutOnChangeAsync}
          />
        </div>
        <div className="mr-4 w-full">
          <Input
            className="-mt-1.5"
            color="text-black"
            size="lg"
            onBlurWithinCharacterLimit={handleActionLinkChangeAsync}
            value={actionLink}
            placeholder="Enter action link"
            onChange={(e) => setActionLink(e.currentTarget.value)}
            error={validateLinkItem(actionLink, LinkType.Action)}
          />
        </div>

        <div className="border-r-2 border-gray-300 mr-6"></div>
        <span
          className="text-primary-charcoal font-inter text-base font-normal"
          style={{ width: 350 }}
        >
          This is the primary link that will be displayed in-app. Attach any
          direct booking links, menus, or even what's on pages to help our app
          users discover more about your {name.toLowerCase()}.
        </span>
      </div>
      <div className="h-auto w-full mt-8 flex flex-col items-start ">
        <div className="text-inter text-2xl font-bold leading-9 text-left mb-6">
          Other Links
        </div>
        <div className="flex w-full mt-1 border-b-2 border-gray pb-8">
          <div className="mr-4 w-full">
            <GenerativeLinkInputs
              maxBlocksCount={5}
              blocksCount={otherLinks.length}
              renderFlyoutMenu={renderFlyoutMenu}
              renderInput={renderInput}
              onAddBlock={() => {
                setOtherLinks([
                  ...otherLinks,
                  { actionLinkType: ActionLinkType.BookNow, value: '' }
                ]);
              }}
              onRemoveBlock={(index) => {
                handleRemoveOtherLinksAsync(index);
              }}
            />
          </div>

          <div className="border-r-2 border-gray-300 mr-6"></div>
          <span
            className="text-primary-charcoal font-inter text-base font-normal"
            style={{ width: 295 }}
          >
            Add any further links necessary for your {name.toLowerCase()}.
          </span>
        </div>
      </div>

      <div className="h-auto pb-6 w-full mt-6 flex flex-col items-start">
        <div className="text-inter text-2xl font-bold leading-9 text-left mb-6">
          Contact
        </div>

        <div className="flex w-full mt-1 border-b-2 border-gray pb-8">
          <div className="flex flex-col w-full">
            <div className="flex flex-row relative">
              <div className="w-32 items-center flex absolute top-6 left-0">
                <AiOutlinePhone className="w-4 h-4 mr-2" />
                <span className="text-primary-dark font-nunito text-base font-normal leading-5">
                  Number
                </span>
              </div>

              <div className="mr-4 w-full ml-28">
                <Input
                  value={localEntity?.contact}
                  placeholder="Enter Phone number"
                  size="md"
                  error={
                    localEntity.contact &&
                    !Validator.isPhoneNumberValid(localEntity.contact) &&
                    'Contact number invalid, please review and update'
                  }
                  onBlurWithinCharacterLimit={async () =>
                    await updateEntityAsync(localEntity)
                  }
                  onChange={(e) =>
                    setLocalEntity({
                      ...localEntity,
                      contact: e.currentTarget.value
                    })
                  }
                />
              </div>
            </div>

            <div className="flex flex-row relative">
              <div className="w-32 items-center flex absolute top-6 left-0">
                <HiOutlineMail className="w-4 h-4 mr-2" />
                <span className="text-primary-dark font-nunito text-base font-normal leading-5 ">
                  Email
                </span>
              </div>
              <div className="mr-4 w-full ml-28">
                <Input
                  value={localEntity?.emailAddress}
                  placeholder="Enter Email"
                  size="md"
                  error={
                    localEntity.emailAddress &&
                    !Validator.isEmailValid(localEntity.emailAddress) &&
                    'Email adress invalid, please review and update'
                  }
                  onBlurWithinCharacterLimit={async () =>
                    await updateEntityAsync(localEntity)
                  }
                  onChange={(e) =>
                    setLocalEntity({
                      ...localEntity,
                      emailAddress: e.currentTarget.value
                    })
                  }
                />
              </div>
            </div>

            <div className="flex flex-row relative">
              <div className="w-32 items-center flex absolute top-6 left-0">
                <BsGlobe className="w-4 h-4 mr-2" />
                <span className="text-primary-dark font-nunito text-base font-normal leading-5">
                  Website
                </span>
              </div>

              <div className="mr-4 w-full ml-28">
                <Input
                  value={localEntity?.social?.website}
                  placeholder="Enter Website"
                  size="md"
                  error={
                    localEntity?.social?.website &&
                    validateLinkItem(
                      localEntity.social.website,
                      LinkType.Website
                    )
                  }
                  onBlurWithinCharacterLimit={async () =>
                    await updateEntityAsync(localEntity)
                  }
                  onChange={(e) =>
                    setLocalEntity({
                      ...localEntity,
                      social: {
                        ...localEntity.social,
                        website: e.currentTarget.value
                      }
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="border-r-2 border-gray-300 ml-4 mr-6"></div>
          <span
            className="text-primary-charcoal font-inter text-base font-normal"
            style={{ width: 295 }}
          >
            Add any contact details for your {name.toLowerCase()} here.
          </span>
        </div>
      </div>

      <div className="h-auto pb-10 w-full flex flex-col items-start ">
        <div className="text-inter text-2xl font-bold leading-9 text-left mb-6">
          Social Links
        </div>

        <div className="flex w-full mt-1">
          <div className="flex flex-col w-full">
            {getSocialLink(
              <Facebook className="w-4 h-4 mr-2" />,
              'Facebook',
              socialLinks.Facebook,
              LinkType.Facebook
            )}
            {getSocialLink(
              <Instagram className="w-4 h-4 mr-2" />,
              'Instagram',
              socialLinks.Instagram,
              LinkType.Instagram
            )}
            {getSocialLink(
              <Twitter className="w-4 h-4 mr-2" />,
              'Twitter',
              socialLinks.Twitter,
              LinkType.Twitter
            )}
            {getSocialLink(
              <TikTok className="w-4 h-4 mr-2" />,
              'TikTok',
              socialLinks.TikTok,
              LinkType.TikTok
            )}
          </div>

          <div className="border-r-2 border-gray-300 mr-6"></div>
          <span
            className="text-primary-charcoal font-inter text-base font-normal"
            style={{ width: 295 }}
          >
            Add any social links relevant to your {name.toLowerCase()}.
          </span>
        </div>
      </div>
    </div>
  );
}

export default LinkContainer;
