import { Spinner } from '@bindystreet/bindystreet.kit.react';
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider
} from '@react-oauth/google';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { UserOperations } from 'Colugo/operations/identity';
import AuthOperations from 'Colugo/operations/identity/AuthOperations';
import { AuthContext } from 'provider/auth/authProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { container } from 'tsyringe';
import { GOOGLE_CLIENT_ID } from 'utility/constants/constants';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import * as firebaseAnalytics from 'utility/analytics/firebaseAnalytics';

const authOperations = container.resolve(AuthOperations);
const userOperations = container.resolve(UserOperations);

const GoogleSignInButton = () => {
  const { errorToast } = useErrorToast();
  const { setUser } = useContext(UserContext);
  const { setToken } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  async function handleGoogleLoginAsync(
    credentialResponse: CredentialResponse
  ) {
    const idToken = credentialResponse.credential;
    if (idToken) {
      setIsLoading(true);
      const { data, error: loginWithIdpError } =
        await authOperations.logInWithIdpAsync(idToken);

      if (!data || loginWithIdpError) {
        setIsLoading(false);

        if (loginWithIdpError?.status === 404) {
          navigate(`${PuffinRoutes.SignUp}?idToken=${idToken}`);
          return;
        }
        errorToast('Error logging in, please try again');
        return;
      }

      const { data: user, error: userError } =
        await userOperations.getUserAsync();

      if (!user || userError) {
        errorToast('Error fetching user, please try again');
        return;
      }

      if (user) {
        setIsLoading(false);
        setUser(user);
        setToken(data);
      }

      firebaseAnalytics.signInEvent(user.email);
    }
  }
  //NOTE: to use a custom button, you need to use the auth-code flow to retrieve the idp token
  //by validating the access token on the backend and then get an idp token from Google
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div className="flex" style={{ maxWidth: '400px' }}>
        <GoogleLogin
          onSuccess={handleGoogleLoginAsync}
          width={isLoading ? '350px' : '400px'}
          logo_alignment="left"
        />
        {isLoading && <Spinner />}
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleSignInButton;
