import { InputElement } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import React, { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

export enum ImprovedInputType {
  Text = 'text',
  Password = 'password'
}

export type ImprovedInputProps = {
  name?: string;
  value?: string;
  label?: string;
  labelClassName?: string;
  inputContainerStyle?: React.CSSProperties | undefined;
  inputType?: ImprovedInputType;
  placeHolder?: string;
  className?: string;
  wrapperClassName?: string;
  error?: string;
  isError?: boolean;
  errorClassName?: string;
  isPasswordVisible?: boolean;
  attachedComponent?: JSX.Element;
  hidePasswordIcon?: JSX.Element;
  showPasswordIcon?: JSX.Element;
  prependJsx?: JSX.Element;
  appendJsx?: JSX.Element;
  maxCharacterCount?: number;
  disabled?: boolean;
  //Callbacks
  onChange?: (e: React.FormEvent<InputElement>) => void;
  onContainerClick?: () => void;
  onBlurWithinCharacterLimit?: (e: React.FocusEvent<InputElement>) => void;
};

const ImprovedInput = (props: ImprovedInputProps): JSX.Element => {
  const {
    name,
    value,
    label,
    labelClassName,
    inputType = ImprovedInputType.Text,
    inputContainerStyle,
    placeHolder = 'Write text here...',
    className,
    wrapperClassName,
    error,
    isError = false,
    errorClassName,
    attachedComponent,
    hidePasswordIcon,
    showPasswordIcon,
    prependJsx,
    appendJsx,
    maxCharacterCount,
    disabled,
    //Callbacks
    onBlurWithinCharacterLimit,
    onChange,
    onContainerClick
  } = props;

  //State
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const focusedStyle = isFocused
    ? `border-${isError ? 'red' : 'onSurface'} `
    : `border-${isError ? 'red' : 'outline'} `;
  const containerClass = wrapperClassName
    ? `flex ${focusedStyle} ${wrapperClassName}`
    : `flex flex-row w-full py-4 px-6 border rounded-lg items-center ${focusedStyle}`;

  function handleOnBlur(e: React.FocusEvent<InputElement>) {
    if (
      maxCharacterCount !== undefined &&
      value &&
      value.length > maxCharacterCount
    ) {
      return;
    }
    onBlurWithinCharacterLimit && onBlurWithinCharacterLimit(e);
  }

  function handleFocusToggle() {
    if (isFocused) {
      return setIsFocused(!isFocused);
    }
    setIsFocused(true);
  }

  function renderShowHidePasswordIcon() {
    if (showPasswordIcon && hidePasswordIcon) {
      return <>{isPasswordVisible ? showPasswordIcon : hidePasswordIcon}</>;
    }
    return (
      <>
        {isPasswordVisible ? (
          <AiFillEye size={20} className="text-black" />
        ) : (
          <AiFillEyeInvisible size={20} className="text-black" />
        )}
      </>
    );
  }

  return (
    <div
      className={`flex flex-col w-full gap-2 ${
        disabled && onContainerClick && 'cursor-pointer'
      }`}
      onClick={onContainerClick}
    >
      {label && <label className={`${labelClassName}`}>{label}</label>}
      <div
        className={containerClass}
        onFocus={handleFocusToggle}
        onBlur={handleFocusToggle}
        style={{
          pointerEvents: disabled ? 'none' : 'auto',
          ...inputContainerStyle
        }}
      >
        {prependJsx}
        <input
          name={name}
          value={value}
          className={`w-full font-nunito text-base` + className}
          type={isPasswordVisible ? ImprovedInputType.Text : inputType}
          placeholder={placeHolder}
          onChange={onChange}
          maxLength={maxCharacterCount}
          disabled={disabled}
          onBlur={handleOnBlur}
        />
        {inputType === ImprovedInputType.Password && (
          <div onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
            {renderShowHidePasswordIcon()}
          </div>
        )}
        {maxCharacterCount && (
          <p className="text-surfaceContainerHighest text-base">
            {value?.length}/{maxCharacterCount}
          </p>
        )}
        {appendJsx}
      </div>
      {error && isError && (
        <label className={`heading-semibold-sm-red ` + errorClassName}>
          {error}
        </label>
      )}
      {attachedComponent}
    </div>
  );
};

export default ImprovedInput;
