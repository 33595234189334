import React, { createContext, useState } from 'react';
import authMethods from './authMethods';
import ICreateBusinessUser from 'Colugo/interfaces/identity/createUser/ICreateBusinessUser';

type Props = {
  children: React.ReactNode;
};

interface ICreateUserWithConfirmPassword extends ICreateBusinessUser {
  confirmPassword?: string;
}

export type AuthContextType = {
  handleSignUpAsync: () => Promise<boolean>;
  handleSignInAsync: () => Promise<boolean>;
  token: string | null;
  inputs: ICreateUserWithConfirmPassword;
  setInputs: React.Dispatch<
    React.SetStateAction<ICreateUserWithConfirmPassword>
  >;
  setToken: (token: string | null) => void;
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  handleSignout: (message?: string) => true | undefined;
  resetState: () => void;
  handleForgotPasswordAsync: () => Promise<void>;
  handleResetPasswordAsync: (oobCode: string) => Promise<boolean>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export const initialCreateUserState = {
  email: '',
  password: '',
  username: '',
  displayName: '',
  dateOfBirth: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
  contact: '',
  phoneNumber: ''
};

export const AuthContext = createContext<AuthContextType>({
  handleSignUpAsync: () => Promise.resolve(false),
  handleSignInAsync: () => Promise.resolve(false),
  token: null,
  inputs: { ...initialCreateUserState, password: '', confirmPassword: '' },
  setInputs: () => {},
  setToken: () => {},
  errors: [],
  setErrors: () => {},
  handleSignout: () => undefined,
  resetState: () => {},
  handleForgotPasswordAsync: () => Promise.resolve(),
  handleResetPasswordAsync: (oobCode: string) => Promise.resolve(false),
  isLoading: false,
  setIsLoading: () => {}
});

const AuthProvider = (props: Props) => {
  const [inputs, setInputs] = useState<ICreateUserWithConfirmPassword>(
    initialCreateUserState
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [token, setToken] = useState(authMethods.getToken());
  const [isLoading, setIsLoading] = useState(false);

  const handleSignUpAsync = async () => {
    setErrors([]);
    setIsLoading(true);

    const createUser: ICreateBusinessUser = {
      email: inputs.email,
      password: inputs.password,
      displayName: inputs.displayName,
      dateOfBirth: inputs.dateOfBirth,
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      username: inputs.username,
      phoneNumber: inputs.phoneNumber
    };

    const didSignUpSucceed = await authMethods.signUpAsync(
      createUser,
      setErrors
    );

    setIsLoading(false);
    return didSignUpSucceed;
  };

  const handleSignInAsync = async () => {
    setErrors([]);
    setIsLoading(true);

    await authMethods.signInAsync(
      inputs.email,
      inputs.password,
      setErrors,
      setToken
    );

    setIsLoading(false);
    return true;
  };

  const handleForgotPasswordAsync = async () => {
    setIsLoading(true);
    await authMethods.forgotPasswordAsync(setErrors, inputs.email);
    setIsLoading(false);
    setErrors([]);
  };

  const handleResetPasswordAsync = async (oobCode: string) => {
    setIsLoading(true);
    const isSuccess = await authMethods.resetPasswordAsync(
      setErrors,
      inputs.password,
      oobCode
    );
    setIsLoading(false);
    setErrors([]);
    return isSuccess;
  };

  const resetState = () => {
    setInputs({ ...initialCreateUserState, password: '', confirmPassword: '' });
    setIsLoading(false);
    setErrors([]);
  };

  const handleSignout = (message?: string) => {
    const isSignOutSuccessful = authMethods.signOutAsync(
      setErrors,
      setToken,
      message
    );
    if (isSignOutSuccessful) {
      return true;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        handleSignUpAsync,
        handleSignInAsync,
        token,
        inputs,
        setInputs,
        setToken,
        errors,
        setErrors,
        handleSignout,
        resetState,
        handleForgotPasswordAsync,
        handleResetPasswordAsync,
        isLoading,
        setIsLoading
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
