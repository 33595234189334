import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import { IIoiValidationError } from 'Colugo/interfaces/IIoiValidationError';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import { useReqListTagsByFilterGroup } from 'Colugo/operations/tags';
import { FilterGroupContext } from 'provider/filterGroups/filterGroupsProvider';
import { TagsContext } from 'provider/tags/tagsProvider';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import TagSelector from './TagSelector';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import { ManagerContext } from 'provider/manager/managerProvider';
import { ITag } from 'Colugo/interfaces/tags';

const listingOperations = container.resolve(ListingOperations);

type Props = {
  localListing: IListing;
  setLocalListing: (localListing: IListing) => void;
  validationErrors: IIoiValidationError[];
  updateIsActiveForInvalidListingAsync: () => Promise<boolean>;
  mutate: (listing: IListing, revalidate?: boolean) => void;
  updateListingAsync: (updatedListing: IListing) => Promise<boolean>;
};

function TaggingListingContainer(props: Props) {
  const {
    localListing,
    setLocalListing,
    validationErrors,
    updateIsActiveForInvalidListingAsync,
    mutate,
    updateListingAsync
  } = props;

  const { tags: allTags } = useContext(TagsContext);
  const { mutateListing } = useContext(ManagerContext);
  const { promotionsFilterGroup, establishmentsFilterGroup } =
    useContext(FilterGroupContext);
  const { errorToast } = useErrorToast();

  const {
    data: promotionTags,
    isLoading: isPromotionTagsLoading,
    isError: isPromotionTagsError
  } = useReqListTagsByFilterGroup(promotionsFilterGroup?.id || '');

  const {
    data: establishmentTags,
    isLoading: isEstablishmentTagsLoading,
    isError: isEstablishmentTagsError
  } = useReqListTagsByFilterGroup(establishmentsFilterGroup?.id || '');

  const nonPromotionAndEstablishmentTags = allTags?.filter((tag) => {
    const isInPromotionTags = promotionTags?.some(
      (promotionTag) => promotionTag.id === tag.id
    );
    const isInEstablishmentTags = establishmentTags?.some(
      (establishmentTag) => establishmentTag.id === tag.id
    );

    return !isInPromotionTags && !isInEstablishmentTags;
  });

  const listingEstablishmentTags = localListing.tags?.filter((tag) =>
    establishmentTags?.some(
      (establishmentTag) => establishmentTag.id === tag.id
    )
  );

  const listingTagsWithoutEstablishmentTags = localListing.tags?.filter((tag) =>
    establishmentTags?.every(
      (establishmentTag) => establishmentTag.id !== tag.id
    )
  );

  async function onClickTagPriorityUpdateAsync(
    tagId: string,
    isPriority: boolean
  ) {
    if (
      localListing.tags?.filter((item) => item.isPriority).length! >= 3 &&
      isPriority
    ) {
      errorToast(`Listing already has the maximum number of priority tags.`);
      return;
    }
    const updatedTags = localListing.tags
      ?.map((tag) =>
        tag.id === tagId ? { ...tag, isPriority: isPriority } : tag
      )
      .sort((a) => (a.isPriority ? -1 : 1));

    const updatedListing = {
      ...localListing,
      tags: updatedTags
    };
    setLocalListing(updatedListing);
    const { error } = await listingOperations.updateTagPriorityAsync(
      localListing.id!,
      tagId,
      isPriority
    );
    if (error) {
      errorToast('Failed to change the priority of tag, try again later');
      setLocalListing(localListing);
      return;
    }
    mutate(updatedListing);
    mutateListing(updatedListing);
  }

  async function handleAddTagAsync(
    tagId: string,
    isEstablishmentTags: boolean = false
  ) {
    if (
      isEstablishmentTags
        ? !establishmentTags || establishmentTags.length === 0
        : !nonPromotionAndEstablishmentTags ||
          nonPromotionAndEstablishmentTags.length === 0
    ) {
      toast.warn('No tags found.');
      return;
    }
    const tagToAdd = isEstablishmentTags
      ? establishmentTags?.find((t) => t.id === tagId)
      : nonPromotionAndEstablishmentTags?.find((t) => t.id === tagId);

    if (!tagToAdd) {
      toast.warn('No tag selected');
      return;
    }
    const updatedListing = {
      ...localListing,
      tags: [...(localListing?.tags || []), tagToAdd]
    };
    setLocalListing(updatedListing);

    const { error } = await listingOperations.addTagAsync(
      localListing.id!,
      tagId
    );

    if (error) {
      errorToast('Failed to add tag, try again later');
      setLocalListing(localListing);
      return;
    }
    mutate(updatedListing);
    mutateListing(updatedListing);
  }

  async function handleRemoveTagAsync(tagId: string) {
    if (!localListing.id) {
      errorToast('No listing selected, Please try again');
      return;
    }

    const newTags = localListing.tags!.filter((t) => t.id !== tagId);
    let updatedListing = {
      ...localListing,
      tags: newTags
    };
    setLocalListing(updatedListing);

    const { error } = await listingOperations.removeTagAsync(
      localListing.id,
      tagId
    );

    if (error) {
      errorToast('Failed to remove tag, try again later');
      setLocalListing(localListing);
      return;
    }

    if (newTags.length === 0) {
      updatedListing = {
        ...localListing,
        isActive: false,
        tags: newTags
      };
      setLocalListing(updatedListing);
      await updateIsActiveForInvalidListingAsync();
    }
    mutate(updatedListing);
    mutateListing(updatedListing);
  }

  async function handleOnClickPrimaryTagAsync(
    tagId: string,
    isPrimary: boolean
  ) {
    if (!tagId) {
      errorToast('No tag was selected, Please try again');
      return;
    }

    if (localListing.establishmentTag && isPrimary) {
      errorToast(
        `Listing can only have one primary establishment tag. Remove the existing primary tag to set a new one.`
      );
      return;
    }

    const establishmentTag: ITag = {
      id: tagId
    };

    setLocalListing({
      ...localListing,
      establishmentTag: isPrimary ? establishmentTag : undefined
    });

    const isSuccessful = await updateListingAsync({
      ...localListing,
      establishmentTag: isPrimary ? establishmentTag : undefined
    });

    if (!isSuccessful) {
      setLocalListing(localListing);
    }
  }

  if (isPromotionTagsLoading || isEstablishmentTagsLoading) {
    return <Spinner />;
  }

  if (isPromotionTagsError || isEstablishmentTagsError) {
    return (
      <ErrorPage>
        <span>{`Error retrieving tags.`}</span>
      </ErrorPage>
    );
  }

  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-col px-6 py-8 w-full border-b-2 border-gray">
        <span className="text-inter text-2xl font-bold leading-9 text-left">
          Establishment
        </span>
        <div className="flex flex-row mt-6">
          <TagSelector
            tags={establishmentTags || []}
            onClickTagDeleteIconAsync={handleRemoveTagAsync}
            onConfirmNewTagAsync={(tagId) => handleAddTagAsync(tagId, true)}
            entityTags={listingEstablishmentTags || []}
            onClickTagUpdatePriorityAsync={handleOnClickPrimaryTagAsync}
            customPlaceholder="Select an establishment tag"
            primaryEstablishmentTagId={localListing.establishmentTag?.id}
          />
          <div className="border-r-2 border-r-gray mr-6"></div>
          <span className="text-sm font-inter" style={{ width: 295 }}>
            Please select establishment tags. You can chose one primary tag
            which will represent your business on the app.
          </span>
        </div>
      </div>

      <div className="flex flex-col rounded-lg px-6 py-8 w-full">
        <span className="text-inter text-2xl font-bold leading-9 text-left">
          Tags
        </span>
        <div className="flex flex-row mt-6">
          <TagSelector
            tags={nonPromotionAndEstablishmentTags || []}
            onClickTagDeleteIconAsync={handleRemoveTagAsync}
            onConfirmNewTagAsync={handleAddTagAsync}
            entityTags={listingTagsWithoutEstablishmentTags || []}
            validationErrors={validationErrors}
            onClickTagUpdatePriorityAsync={onClickTagPriorityUpdateAsync}
          />
          <div className="border-r-2 border-r-gray mr-6"></div>
          <span className="text-sm font-inter" style={{ width: 295 }}>
            Tags help our users discover your business on the app. Please add
            between 5 - 10 tags that you see as relevant.
          </span>
        </div>
      </div>
    </div>
  );
}

export default TaggingListingContainer;
