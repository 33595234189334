import {
  ErrorType,
  IIoiValidationError
} from 'Colugo/interfaces/IIoiValidationError';
import { IVideo } from 'Colugo/interfaces/video/IVideo';
import { useEffect, useState } from 'react';
import ImageUploaderInput from 'storybook/ImageUploaderInput';
import { uploadFileOnDrop } from 'utility/general/fileUploadOnDrop';

export interface localEntity {
  images?: string[];
  video?: IVideo;
}

type Props<T> = {
  localEntity: T;
  addImageAsync(imageUrl: string): Promise<void>;
  deleteImageAsync: (index: number) => Promise<void>;
  validationErrors: IIoiValidationError[];
  label: 'Listings' | 'Events';
  updateImagesOrderAsync(images: string[]): Promise<void>;
};

const maxImageCount = 5;

function ImageSettings<T extends localEntity>(props: Props<T>) {
  const {
    localEntity,
    addImageAsync,
    deleteImageAsync,
    validationErrors,
    label,
    updateImagesOrderAsync
  } = props;

  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [localImages, setLocalImages] = useState<string[]>(
    localEntity.images ?? []
  );
  const [dragId, setDragId] = useState<string>();

  useEffect(() => {
    if (localEntity.images) {
      setLocalImages(localEntity.images);
    }
  }, [localEntity.images]);

  const imageValidationErrror = validationErrors.find(
    (ve) => ve.type === ErrorType.Image
  );

  let entityImages = localImages || [];

  const onDropImage = uploadFileOnDrop(
    addImageAsync,
    setIsLoadingImage,
    setProgress
  );

  for (let i = 0; i < maxImageCount - (localEntity.images?.length || 0); i++) {
    entityImages = [...entityImages, ''];
  }

  const isLoadingIndexedImage = (index: number) => {
    if (
      localEntity.images ? index === localEntity.images.length : index === 0
    ) {
      return isLoadingImage;
    }

    return false;
  };

  async function handleUploadImageAsync(image: File) {
    setIsLoadingImage(true);
    await onDropImage([image]);
    setIsLoadingImage(false);
  }

  const handleDrag = (ev: React.DragEvent<HTMLDivElement>) => {
    if (ev.currentTarget.id) {
      setDragId(ev.currentTarget.id);
    }
  };

  const handleDropAsync = async (ev: React.DragEvent<HTMLDivElement>) => {
    if (!ev.currentTarget.id) {
      return null;
    }
    const dragIndex = localImages.findIndex((item) => item === dragId);
    const dropIndex = localImages.findIndex(
      (item) => item === ev.currentTarget.id
    );

    const newData = [...localImages];
    // Swap the positions of the images
    const temp = newData[dragIndex];
    newData[dragIndex] = newData[dropIndex];
    newData[dropIndex] = temp;

    setLocalImages(newData);
    await updateImagesOrderAsync(newData);
  };

  return (
    <div className="flex flex-col w-full ">
      <div className="font-inter text-2xl font-bold">Images</div>
      <div className="flex flex-row w-full relative">
        <div className="flex-row border-r-2" style={{ width: '85%' }}>
          <div className="flex flex-row flex-wrap mt-1" style={{ gap: '22px' }}>
            {entityImages.map((image, index) => {
              const isEnabled =
                entityImages[index] !== '' ||
                (entityImages && index === entityImages.indexOf(''));
              return (
                <div key={index}>
                  <div className="">
                    <ImageUploaderInput
                      onUploadComplete={(image) => {
                        handleUploadImageAsync(image);
                      }}
                      imageUrl={image}
                      progress={progress}
                      isLoading={isLoadingIndexedImage(index)}
                      deleteImage={async () => await deleteImageAsync(index)}
                      resolution={{
                        width: 512,
                        height: 512
                      }}
                      customPlaceholderSize={28}
                      customHeightPx={'192px'}
                      customWidthPx={'192px'}
                      fit="cover"
                      handleDrag={handleDrag}
                      handleDrop={handleDropAsync}
                      isUploadEnabled={isEnabled}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <p className="text-error1 italic mt-6">
            {imageValidationErrror && imageValidationErrror.tabMessage}
          </p>
        </div>
        <div
          className="ml-6 text-sm font-inter font-normal"
          style={{ width: '247px' }}
        >
          Add photos to really show off your{' '}
          {label === 'Events' ? 'event' : 'business'}! Photos can be dragged and
          reordered.
        </div>
      </div>
    </div>
  );
}

export default ImageSettings;
