import React, { useContext, useState } from 'react';
import { AuthContext } from 'provider/auth/authProvider';
import { Form, Button, Input } from '@bindystreet/bindystreet.kit.react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Validator from 'utility/general/RegexValidator';
import { InputFormEvent } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import Layout from './Layout';
import { FormError } from './SignUp';
import { toast } from 'react-toastify';
import { IoClose } from 'react-icons/io5';

const toastStyle: React.CSSProperties = {
  backgroundColor: '#878897',
  borderColor: '#878897',
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingLeft: '24px',
  paddingRight: '24px',
  borderWidth: '1px',
  borderRadius: '8px',
  alignItems: 'center',
  justifyItems: 'center'
};

const bodyToastStyle: React.CSSProperties = {
  color: '#FFFFFF',
  alignItems: 'center',
  justifyItems: 'center',
  gap: '16px'
};

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const {
    handleResetPasswordAsync,
    inputs,
    setInputs,
    errors,
    isLoading,
    resetState
  } = useContext(AuthContext);
  const oobCode = searchParams.get('oobCode');

  //Hooks
  const navigate = useNavigate();

  //State
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean>(true);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);

  function validateFields() {
    let isSubmitValid = true;
    let errors: FormError[] = [];

    if (!inputs.password || !Validator.isPasswordValid(inputs.password)) {
      errors.push({
        field: 'password',
        message: 'Please provide a password'
      });
      isSubmitValid = false;
    }

    if (!inputs.confirmPassword) {
      errors.push({
        field: 'confirmPassword',
        message: 'Please retype your password'
      });
      isSubmitValid = false;
    }

    setFormErrors(errors);
    return isSubmitValid;
  }

  async function handleSubmitAsync(e: React.FormEvent<HTMLFormElement>) {
    const isSubmitValid = validateFields();
    if (!isSubmitValid) {
      return;
    }
    if (oobCode) {
      resetState();
      const isSuccessful = await handleResetPasswordAsync(oobCode);
      if (isSuccessful) {
        navigate('/');
        toast.success('Password succesfully reset', {
          style: toastStyle,
          bodyStyle: bodyToastStyle,
          hideProgressBar: true,
          closeButton: (
            <IoClose color={'#FFFFFF'} size={24} style={{ marginTop: '6px' }} />
          )
        });
      }
    }
  }

  function handleConfirmPassword(e: InputFormEvent) {
    const { name, value } = e.target as any;
    setInputs((prev: any) => ({ ...prev, [name]: value }));
    setIsPasswordMatch(inputs.password === value);
  }

  function handleChangePassword(e: InputFormEvent) {
    const { name, value } = e.target as any;
    setInputs((prev: any) => ({ ...prev, [name]: value }));
    setIsPasswordMatch(value === inputs.confirmPassword);
  }

  function renderError(fieldName: string, formErrors: FormError[]) {
    const errorMessage = formErrors.find(
      (fe) => fe.field === fieldName
    )?.message;
    return errorMessage ?? false;
  }

  function displayError(field: string) {
    const errorMessage = formErrors.find((fe) => fe.field === field)?.message;
    return (
      <div className="text-sm -mt-1 mb-1 text-red font-bold">
        {errorMessage}
      </div>
    );
  }

  return (
    <Layout>
      <div className="flex-col px-40 items-center justify-center">
        <div className="w-full flex flex-col gap-2">
          <h2 className="heading-bold-2xl-onSurface">Reset Password</h2>
          <span className="heading-regular-l-onSurfaceVariant">
            Please reset your password below.
          </span>
        </div>
        <div className="mt-6" style={{ maxWidth: '556px' }}>
          <Form onSubmit={handleSubmitAsync} formClassName={'flex flex-col'}>
            <Input
              label="Password"
              labelColor="text-onSurface"
              labelHint="At least 8 characters, with at least 1 number and 1 special character"
              placeholder="Type Password"
              onChange={handleChangePassword}
              error={
                (inputs.password.length > 0 &&
                  !Validator.isPasswordValid(inputs.password) &&
                  `Your password doesn't meet our requirements`) ||
                (!inputs.password &&
                  !Validator.isPasswordValid(inputs.password) &&
                  renderError('password', formErrors))
              }
              showErrorText={false}
              name="password"
              type="password"
              value={inputs.password}
            />

            {!inputs.password && (
              <div>
                {!Validator.isPasswordValid(inputs.password) && (
                  <div>
                    {formErrors.some((error) => error.field === 'password') && (
                      <div className="text-sm text-red">
                        {displayError('password')}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div>
              {!Validator.isPasswordValid(inputs.password) &&
                inputs.password && (
                  <div>
                    <h2 className="text-sm text-red -mt-2 font-bold mb-3">
                      Your password doesn't meet our requirements
                    </h2>
                  </div>
                )}
            </div>

            <Input
              label="Retype Password"
              labelColor="text-onSurface"
              placeholder="Retype Password"
              onChange={handleConfirmPassword}
              error={
                !inputs.confirmPassword &&
                renderError('confirmPassword', formErrors)
              }
              name="confirmPassword"
              type="password"
              value={inputs.confirmPassword}
            />

            {Validator.isPasswordValid(inputs.password) &&
              !isPasswordMatch &&
              inputs.confirmPassword &&
              inputs.confirmPassword.length > 0 && (
                <div>
                  <h2 className="text-sm text-red font-bold mb-5">
                    Passwords do not match, please try again
                  </h2>
                </div>
              )}

            <div className="lg:w-1/2">
              <Button
                size="lg"
                type="submit"
                skin="transparent"
                isLoading={isLoading}
                className="flex flex-row justify-center items-center px-1 w-full bg-primary cursor-pointer"
              >
                <label
                  className={'body-bold-av-onPrimary w-full cursor-pointer'}
                >
                  Reset password
                </label>
              </Button>
            </div>
            {errors?.length > 0 &&
              errors.map((error: string, idx: number) => (
                <p key={idx} className="heading-semibold-sm-red mt-4">
                  {error}
                </p>
              ))}
          </Form>
        </div>
      </div>
    </Layout>
  );
}

export default ResetPassword;
