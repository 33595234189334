import {
  CheckoutSessionType,
  ICreateCheckoutSessionRequest
} from 'Colugo/interfaces/payment/ICreateCheckoutSessionRequest';
import { ICreateStripeCustomerRequest } from 'Colugo/interfaces/payment/ICreateStripeCustomerRequest';
import { ICreateStripeCustomerResponse } from 'Colugo/interfaces/payment/ICreateStripeCustomerResponse';
import { IStripePrice } from 'Colugo/interfaces/payment/IStripePrice';
import { requestResponse } from 'Colugo/provider/HttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import {
  swrRequestResponse,
  useGetHttpClient
} from '../../provider/useGetHttpClient';
import AbstractOperations from '../AbstractOperations';

@scoped(Lifecycle.ContainerScoped)
class PaymentOperations extends AbstractOperations {
  protected basePath: string = 'payment';

  public async createStripeCustomerAsync(
    createStripeCustomerRequest: ICreateStripeCustomerRequest
  ): requestResponse<ICreateStripeCustomerResponse> {
    return await this.executePost(
      `createStripeCustomer`,
      createStripeCustomerRequest
    );
  }

  public async createStripeCheckoutSessionAsync(
    createCheckoutSessionRequest: ICreateCheckoutSessionRequest,
    checkoutSessionType: CheckoutSessionType = CheckoutSessionType.Subscription
  ): requestResponse<string> {
    return await this.executePost(
      `createStripeCheckoutSession?checkoutSessionType=${checkoutSessionType}`,
      createCheckoutSessionRequest
    );
  }
}

const useReqListStripePricesAsync = (): swrRequestResponse<IStripePrice[]> => {
  return useGetHttpClient(
    'payment/listStripePrices',
    {
      revalidateOnFocus: false
    },
    false
  );
};

export default PaymentOperations;
export { useReqListStripePricesAsync };
