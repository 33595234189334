import { AiFillClockCircle } from 'react-icons/ai';
import { BsFillTagsFill } from 'react-icons/bs';
import { FaHome } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { RiMoneyPoundBoxFill } from 'react-icons/ri';
import { ReactComponent as Analytics } from 'images/sidebar/business/analytics.svg';
import { HiLink } from 'react-icons/hi';

export interface ITab {
  name: string;
  path: string;
  icon?: JSX.Element;
}

export function ListingTabList(
  isSuperAdmin: boolean,
  activeStepName?: string
): ITab[] {
  const tabList = [
    {
      name: 'General',
      path: '/general',
      icon: (
        <FaHome
          size={24}
          className={
            activeStepName === 'General'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    },
    {
      name: 'Tagging',
      path: '/tagging',
      icon: (
        <BsFillTagsFill
          size={24}
          className={
            activeStepName === 'Tagging'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    },
    {
      name: 'Location',
      path: '/location',
      icon: (
        <FaLocationDot
          size={24}
          className={
            activeStepName === 'Location'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    },
    {
      name: 'Opening Times',
      path: '/openingTimes',
      icon: (
        <AiFillClockCircle
          size={24}
          className={
            activeStepName === 'Opening Times'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    },
    {
      name: 'Links',
      path: '/link',
      icon: (
        <HiLink
          size={24}
          className={
            activeStepName === 'Links' ? 'text-white' : 'text-onSurfaceVariant'
          }
        />
      )
    },
    {
      name: 'Promotions',
      path: '/promotions',
      icon: (
        <RiMoneyPoundBoxFill
          size={24}
          className={
            activeStepName === 'Promotions'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    }
  ];

  if (isSuperAdmin) {
    tabList.push({
      name: 'Analytics',
      path: '/analytics',
      icon: (
        <Analytics
          className={
            activeStepName === 'Analytics'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    });
  }

  return tabList;
}

export function EventTabList(
  isSuperAdmin: boolean,
  activeStepName?: string
): ITab[] {
  const tabList = [
    {
      name: 'General',
      path: '/general',
      icon: (
        <FaHome
          size={24}
          className={
            activeStepName === 'General'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    },
    {
      name: 'Date & Time',
      path: '/datetime',
      icon: (
        <AiFillClockCircle
          size={24}
          className={
            activeStepName === 'Date & Time'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    },
    {
      name: 'Tagging',
      path: '/tagging',
      icon: (
        <BsFillTagsFill
          size={24}
          className={
            activeStepName === 'Tagging'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    },
    {
      name: 'Location',
      path: '/location',
      icon: (
        <FaLocationDot
          size={24}
          className={
            activeStepName === 'Location'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    },
    {
      name: 'Links',
      path: '/link',
      icon: (
        <HiLink
          size={24}
          className={
            activeStepName === 'Links' ? 'text-white' : 'text-onSurfaceVariant'
          }
        />
      )
    },
    {
      name: 'Promotions',
      path: '/promotions',
      icon: (
        <RiMoneyPoundBoxFill
          size={24}
          className={
            activeStepName === 'Promotions'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    }
  ];

  if (isSuperAdmin) {
    tabList.push({
      name: 'Analytics',
      path: '/analytics',
      icon: (
        <Analytics
          className={
            activeStepName === 'Analytics'
              ? 'text-white'
              : 'text-onSurfaceVariant'
          }
        />
      )
    });
  }

  return tabList;
}
