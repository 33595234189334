import { IBusinessRequest } from 'Colugo/interfaces/business/IBusinessRequest';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import BusinessRequestOperations from 'Colugo/operations/businessRequest/BusinessRequestOperations';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';

import { ILocationDetails } from 'Colugo/interfaces/games/ILocationDetails';
import { NameLocationResult } from 'Colugo/interfaces/NameLocationResult';
import CustomFormHeader from 'components/shared/CustomFormHeader';
import ImprovedInput from 'components/shared/ImprovedInput';
import ImprovedSwitch from 'components/shared/ImprovedSwitch';
import LocationModal from 'components/shared/LocationModal';
import LocationSnippet from 'components/shared/LocationSnippet';
import { ManagerContext } from 'provider/manager/managerProvider';
import { useContext, useState } from 'react';
import { container } from 'tsyringe';
import { formatAddress } from 'utility/general/formatHelpers';
import { validateFields } from 'utility/general/validators';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import BusinessRequestClaimFooter, {
  BusinessClaimOrRequestInput,
  BusinessValidationErrors,
  businessValidationRequiredErrors
} from '../common/BusinessRequestClaimFooter';
import ThankYou from './ThankYou';

const businessRequestOperations = container.resolve(BusinessRequestOperations);
const listingOperations = container.resolve(ListingOperations);

function SubmitBusinessRequest() {
  // Hooks
  const { errorToast } = useErrorToast();
  const { mutateOwnsClaimsRequests, businessOwnsClaimsRequests } =
    useContext(ManagerContext);

  // State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const [errors, setErrors] = useState<BusinessValidationErrors | null>(null);
  const [useAddress, setUseAddress] = useState<boolean>(true);
  const [isLocationModalOpen, setIsLocationModalOpen] =
    useState<boolean>(false);

  const [businessRequest, setBusinessRequest] =
    useState<BusinessClaimOrRequestInput>({
      name: '',
      address: null,
      location: {},
      isYourBusiness: null,
      businessConnection: '',
      hasAlreadyContacted: null,
      clarification: ''
    });

  async function handleSubmitAsync() {
    setErrors(null);
    const requiredExceptions = useAddress
      ? ['location']
      : ['location', 'address'];
    const required = (
      businessRequest.isYourBusiness
        ? Object.keys(businessRequest).filter(
            (i) => !['businessConnection', ...requiredExceptions].includes(i)
          )
        : Object.keys(businessRequest).filter(
            (i) => !requiredExceptions.includes(i)
          )
    ) as (keyof BusinessClaimOrRequestInput)[];

    const errors = validateFields(
      businessRequest,
      required,
      businessValidationRequiredErrors
    );

    if (Object.keys(errors).length > 0) {
      return setErrors(errors as unknown as BusinessValidationErrors);
    }
    let listing: IListing;
    if (businessRequest.location && useAddress) {
      listing = {
        name: businessRequest.name!,
        address: businessRequest.address!,
        location: businessRequest.location
      };
    } else {
      listing = {
        name: businessRequest.name!,
        address: {}
      };
    }

    setIsLoading(true);

    const { data: nameLocationResult } =
      await listingOperations.checkNameLocationAsync(listing);

    if (nameLocationResult === NameLocationResult.NotUnique) {
      setIsLoading(false);
      setErrors({
        ...errors,
        name: `A business with this 'Name' already exists. Please return to the account dashboard to search for and claim this business instead.`
      });
      return;
    }
    if (nameLocationResult === NameLocationResult.AlreadyClaimed) {
      setIsLoading(false);
      setErrors({
        ...errors,
        name: `A business with this 'Name' ${
          useAddress ? `and 'Address'` : ``
        } has already been claimed on our platform. To dispute this, please Contact Us.`
      });
      return;
    }

    const { address, location, name, ...rest } = businessRequest;
    const { data: createdBusinessRequest, error } =
      await businessRequestOperations.createAsync({
        ...rest,
        listing
      } as IBusinessRequest);

    if (!createdBusinessRequest || error) {
      errorToast('An error occured, please try again');
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setShowThankYou(true);

    mutateOwnsClaimsRequests({
      ...businessOwnsClaimsRequests,
      requests: [
        ...businessOwnsClaimsRequests.requests,
        { ...createdBusinessRequest, listing: listing }
      ]
    });
  }

  function setBusinessLocation(locationDetails: ILocationDetails) {
    setBusinessRequest({
      ...businessRequest,
      address: {
        ...locationDetails.address,
        addressString: formatAddress(locationDetails.address || {})
      },
      location: locationDetails.location
    });
  }

  if (showThankYou) {
    return <ThankYou type={'Request'} />;
  }

  return (
    <div className="flex flex-col bg-pageColour h-screen max-h-screen overflow-y-hidden">
      <CustomFormHeader
        title="Submit Business Request"
        isActionLoading={isLoading}
      />
      <div className="overflow-y-auto">
        <div
          className="flex-col items-center justify-center ml-auto mr-auto"
          style={{ maxWidth: '714px' }}
        >
          {isLocationModalOpen && (
            <LocationModal
              setIsModalOpen={setIsLocationModalOpen}
              onConfirmLocation={(locationDetails: ILocationDetails) =>
                setBusinessLocation(locationDetails)
              }
            />
          )}
          <div
            className="mt-10 text-lg font-inter font-normal text-onSurfaceVariant"
            style={{ lineHeight: '22px' }}
          >
            <div>
              <p className="font-inter text-onSurfaceVariant font-light text-base">
                Please note that, apart from your business name and address, any
                additional information gathered during your request will be used
                solely for internal verification purposes and will not be
                displayed publicly without your consent.
              </p>
            </div>
            <div className="mt-6">
              <p className="font-inter text-onSurface font-bold text-base mb-2">
                Business Name
              </p>
              <ImprovedInput
                className="h-full py-4 focus:outline-none"
                wrapperClassName="border rounded-lg bg-white flex-row items-center focus:border-onSurface px-6"
                maxCharacterCount={70}
                onChange={(e) =>
                  setBusinessRequest({
                    ...businessRequest,
                    name: e.currentTarget.value
                  })
                }
                placeHolder="Enter Business Name"
                value={businessRequest.name}
                error={errors?.name}
                isError={Boolean(errors?.name)}
              />
            </div>
            <div className="mt-6">
              <div className="flex flkex-row mb-2">
                <p className="font-inter text-onSurface font-bold text-base mb-1">
                  Business Address
                </p>
                <div className="flex flex-grow" />
                <div className="mt-0.5">
                  <ImprovedSwitch
                    onChange={() => setUseAddress(!useAddress)}
                    checked={useAddress}
                  />
                </div>
              </div>
              {useAddress ? (
                <LocationSnippet
                  latitude={businessRequest.location?.latitude}
                  longitude={businessRequest.location?.longitude}
                  address={businessRequest.address}
                  openLocationModal={() => setIsLocationModalOpen(true)}
                  error={errors?.address}
                />
              ) : (
                <p className="text-sm text-lightCharcoal font-normal">
                  Not having a location will make it harder for users to find
                  your business on the map.
                </p>
              )}
            </div>
            <BusinessRequestClaimFooter
              setClaimOrRequestInput={setBusinessRequest}
              claimOrRequestInput={businessRequest}
              errors={errors}
              onPressSubmit={async () => await handleSubmitAsync()}
              isSubmitLoading={isLoading}
              type={'Business Request'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmitBusinessRequest;
