import { ISubscription } from 'Colugo/interfaces/subscriptions/ISubscription';
import { requestResponse } from 'Colugo/provider/HttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import AbstractOperations from '../AbstractOperations';

@scoped(Lifecycle.ContainerScoped)
class SubscriptionOperations extends AbstractOperations {
  protected basePath: string = 'subscription';

  public async cancelAsync(subscriptionId: string): requestResponse {
    return await this.executePost(`cancelSubscription/${subscriptionId}`);
  }

  public async validateAsync(subscription: ISubscription): requestResponse {
    return await this.executePost(`validateSubscription`, subscription);
  }
}
export default SubscriptionOperations;
