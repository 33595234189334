import {
  ErrorType,
  IIoiValidationError
} from 'Colugo/interfaces/IIoiValidationError';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import ListingOperations from 'Colugo/operations/listings/ListingOperations';
import ImprovedSwitch from 'components/shared/ImprovedSwitch';
import ImprovedTooltip from 'components/shared/ImprovedTooltip';
import LinkContainer from 'components/shared/LinkContainer';
import TabComponent from 'components/shared/TabComponent';
import { ITab, ListingTabList } from 'components/shared/TabList';
import { ManagerContext } from 'provider/manager/managerProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useEffect, useState } from 'react';
import { FaCircleCheck } from 'react-icons/fa6';
import { TiWarning } from 'react-icons/ti';
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import AutoSaveTimer from './AutoSaveTimer';
import GeneralTabContainer from './general/GeneralTabContainer';
import LocationTabContainer from './location/LocationTabContainer';
import ListingOpeningTimesContainer from './openingTimes/ListingOpeningTimesContainer';
import PromotionTabContainer from './promotions/PromotionTabContainer';
import TaggingListingContainer from './tagging/TaggingListingContainer';
import AnalyticsTabContainer from './analytics/AnalyticsTabContainer';

const listingOperations = container.resolve(ListingOperations);

type Props = {
  listing: IListing;
  mutate: (listing: IListing, revalidate?: boolean) => void;
};

function EditListingEditor(props: Props) {
  const { errorToast } = useErrorToast();
  const { listing, mutate } = props;
  const [listingSaved, setListingSaved] = useState(false);
  const [localListing, setLocalListing] = useState<IListing>(listing);
  const { mutateListing, hasActivatedBusiness, setHasActivatedBusiness } =
    useContext(ManagerContext);

  const { isSuperAdmin } = useContext(UserContext);

  const expectedUrl = isSuperAdmin
    ? PuffinRoutes.EditListing
    : PuffinRoutes.BusinessDetails;

  useEffect(() => {
    setLocalListing(listing);
  }, [listing]);

  const validationErrors: IIoiValidationError[] = [];

  if (!localListing.categories || localListing.categories.length === 0) {
    validationErrors.push({
      tabMessage:
        'To activate your listing you must select at least one "Category".',
      type: ErrorType.Category
    });
  }

  if (!localListing.images || localListing.images.length === 0) {
    validationErrors.push({
      tabMessage: 'To activate your listing you must upload a "Primary image".',
      type: ErrorType.Image
    });
  }

  if (!localListing.tags || localListing.tags.length === 0) {
    validationErrors.push({
      tabMessage:
        'To activate your listing you must select at least one "tag".',
      type: ErrorType.Tag
    });
  }

  const match = useMatch(expectedUrl);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setListingSaved(true);
  }, [localListing]);

  const isActive = localListing.isActive;

  const currentTabName = ListingTabList(isSuperAdmin).find((tab) =>
    location.pathname.includes(tab.path)
  )?.name;

  async function updateIsActiveForInvalidListingAsync() {
    if (!isActive) {
      return true;
    }

    errorToast('Listing Inactive, due to missing field');
    setListingSaved(true);
    const hiddenListing: IListing = {
      ...localListing,
      isActive: false
    };
    await listingOperations.inactivateAsync(hiddenListing.id!);
    return false;
  }

  async function updateListingAsync(updatedListing: IListing) {
    if (!updatedListing) {
      errorToast('Unable to edit listing');
      return false;
    }
    setLocalListing(updatedListing);
    setListingSaved(true);

    const { error } = await listingOperations.updateAsync(updatedListing);

    if (error) {
      errorToast('Failed to edit listing.');
      return false;
    }

    if (!updatedListing.images || updatedListing.images.length === 0) {
      await updateIsActiveForInvalidListingAsync();
      setLocalListing({
        ...updatedListing,
        isActive: false
      });
    }

    mutate(updatedListing, false);
    mutateListing(updatedListing);

    return true;
  }

  async function checkAndUpdateIsActiveAsync() {
    toast.dismiss();

    if (!isActive) {
      if (validationErrors.length > 0) {
        validationErrors.forEach((error) =>
          errorToast(`You are missing a ${error.type}`)
        );
        return;
      }
    }

    toast.dismiss();
    toast.info(
      <div className="flex items-center">
        {!isActive ? (
          <FaCircleCheck className="text-green" size={20} />
        ) : (
          <TiWarning className="text-primary" size={24} />
        )}
        <span className="text-onSurface font-inter ml-2">
          Your Business is now {isActive ? 'inactive' : 'active'}.
        </span>
      </div>,
      {
        className: 'font-nunito'
      }
    );

    if (!localListing) {
      errorToast('No listing selected, please refresh');
      return;
    }

    if (!hasActivatedBusiness) {
      setHasActivatedBusiness(true);
    }

    setLocalListing({ ...localListing, isActive: !isActive });
    setListingSaved(true);

    const { error } = isActive
      ? await listingOperations.inactivateAsync(localListing.id!)
      : await listingOperations.activateAsync(localListing.id!);

    if (error) {
      toast.dismiss();
      errorToast(`Failed to ${isActive ? 'inactivate' : 'activate'} listing.`);
      localListing.isActive = isActive;
      setLocalListing(localListing);
    }

    mutateListing({ ...listing, isActive: !isActive });
  }

  function handleOnClickTab(tab: ITab) {
    navigate(`${match?.pathnameBase}${tab.path}`);
  }

  return (
    <div className="bg-theme1 h-auto w-full mb-5 overflow-hidden">
      {isSuperAdmin && (
        <div
          className="ml-auto mr-auto"
          style={{ width: '80vw', paddingTop: '20px' }}
        >
          {!!listing.hasSuggestions && (
            <div className="bg-primaryTeal h-14 rounded-lg flex flex-row text-white p-4 mb-4 font-nunito">
              <span>
                There are suggestions for this Listing waiting for your approval
              </span>
              <div className="flex-grow" />
              <span
                className="cursor-pointer hover:underline"
                onClick={() => navigate(`/suggestions/listing/${listing.id}`)}
              >
                Take me there
              </span>
            </div>
          )}
          <div className="flex flex-row justify-between w-full h-10">
            <div className="font-inter font-bold">
              <div className="mt-1 text-2xl ">{listing.name}</div>
              <div>Popular: {listing.popularScore?.toFixed(2)}</div>
              <div className="mb-2">
                Trending: {listing.trendingScore?.toFixed(2)}
              </div>
            </div>
            <AutoSaveTimer
              entitySaved={listingSaved}
              setListingSaved={setListingSaved}
            />
          </div>
        </div>
      )}

      <div
        className={`flex flex-row items-end justify-between h-14 ml-auto mr-auto relative ${
          isSuperAdmin ? 'mt-10' : ''
        }`}
        style={{ width: '80vw' }}
      >
        <div className="flex flex-row">
          {ListingTabList(isSuperAdmin, currentTabName).map((tab, i) => (
            <TabComponent
              key={i}
              index={i}
              tab={tab}
              activeTabName={currentTabName!}
              onClickTab={handleOnClickTab}
            />
          ))}
        </div>
        <div
          className="px-6 py-3 flex flex-row items-center"
          style={{
            borderRadius: '8px 8px 0px 0px',
            borderTop: '1px solid #E6E6E6',
            borderRight: '1px solid #E6E6E6',
            borderLeft: '1px solid  #E6E6E6'
          }}
        >
          {isActive ? (
            <FaCircleCheck className="text-green" size={20} />
          ) : (
            <TiWarning className="text-primary" size={24} />
          )}
          <div className="ml-2 font-medium font-inter text-base">
            {isActive ? 'Active' : 'Inactive'} on Ember
          </div>
          <ImprovedSwitch
            className="ml-2 mt-2"
            checked={!!isActive}
            onChange={async () => await checkAndUpdateIsActiveAsync()}
          />
        </div>
      </div>
      {!hasActivatedBusiness && validationErrors.length === 0 && !isActive && (
        <div className="absolute right-36 mr-1 top-30 z-20 mt-4">
          <ImprovedTooltip
            title={
              "Don't forget to toggle this to make your business active on the app."
            }
            isSuperAdmin={isSuperAdmin}
          />
        </div>
      )}
      <div
        className="bg-white flex flex-col items-start ml-auto mr-auto"
        style={{
          borderRadius: '0px 0px 12px 12px',
          border: '1px solid rgba(0, 0, 0, 0.10)',
          width: '80vw'
        }}
      >
        <Routes>
          <Route
            path="/general"
            element={
              <GeneralTabContainer
                localListing={localListing}
                setLocalListing={setLocalListing}
                updateListingAsync={updateListingAsync}
                validationErrors={validationErrors}
                updateIsActiveForInvalidListingAsync={
                  updateIsActiveForInvalidListingAsync
                }
                mutate={mutate}
              />
            }
          />
          <Route
            path="/tagging"
            element={
              <TaggingListingContainer
                localListing={localListing}
                setLocalListing={setLocalListing}
                validationErrors={validationErrors}
                updateIsActiveForInvalidListingAsync={
                  updateIsActiveForInvalidListingAsync
                }
                mutate={mutate}
                updateListingAsync={updateListingAsync}
              />
            }
          />
          <Route
            path="/location"
            element={
              <LocationTabContainer
                localListing={localListing}
                setLocalListing={setLocalListing}
                updateListingAsync={updateListingAsync}
              />
            }
          />
          <Route
            path="/link"
            element={
              <LinkContainer
                localEntity={localListing}
                setLocalEntity={setLocalListing}
                updateEntityAsync={updateListingAsync}
                name="Business"
              />
            }
          />
          <Route
            path="/openingTimes"
            element={
              <ListingOpeningTimesContainer
                localListing={localListing}
                setLocalListing={setLocalListing}
                updateListingAsync={updateListingAsync}
              />
            }
          />
          <Route
            path="/promotions"
            element={
              <PromotionTabContainer
                localEntity={localListing}
                setLocalEntity={setLocalListing}
              />
            }
          />
          <Route
            path="/analytics"
            element={<AnalyticsTabContainer localEntity={localListing} />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default EditListingEditor;
