import { AuthOrigin } from 'Colugo/interfaces/identity/IAuthOrigin';
import HttpClient, { requestResponse } from 'Colugo/provider/HttpClient';
import AbstractOperations from '../AbstractOperations';
import ICreateUserIdp from 'Colugo/interfaces/identity/createUser/ICreateUserIdp';

class AuthOperations extends AbstractOperations {
  protected basePath: string = 'auth';

  public async logInWithIdpAsync(
    idToken: string,
    rawNonce = ''
  ): requestResponse {
    const httpClient = new HttpClient();
    const response = await this.executePost(
      `handleWithIdp?loginOrigin=${AuthOrigin.Puffin}&rawNonce=${rawNonce}`,
      undefined,
      {
        ...httpClient.getEmptyOptions(),
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }
    );
    localStorage.setItem('token', response.data);
    return response;
  }

  public async signUpWithIdpAsync(
    createUserIdp: ICreateUserIdp,
    idToken: string,
    rawNonce = ''
  ): requestResponse {
    const httpClient = new HttpClient();
    const response = await this.executePost(
      `createWithIdp?loginOrigin=${AuthOrigin.Puffin}&rawNonce=${rawNonce}`,
      createUserIdp,
      {
        ...httpClient.getEmptyOptions(),
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }
    );
    localStorage.setItem('token', response.data);
    return response;
  }

  public async resetPassword(email: string): requestResponse {
    return await this.executePost('resetPassword', { email: email });
  }
}

export default AuthOperations;
