import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { ILocationDetails } from 'Colugo/interfaces/games/ILocationDetails';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import EventOperations from 'Colugo/operations/events/EventOperations';
import { maximumEventAndPromotionCount } from 'components/business/dashboard/ManageEvents';
import ListingSearchModal from 'components/listings/ListingSearchModal';
import CustomFormHeader from 'components/shared/CustomFormHeader';
import ImprovedCheckbox from 'components/shared/ImprovedCheckbox';
import ImprovedInput from 'components/shared/ImprovedInput';
import LocationModal from 'components/shared/LocationModal';
import LocationSnippet from 'components/shared/LocationSnippet';
import { EventsContext } from 'provider/events/eventProvider';
import { ListingsContext } from 'provider/listings/listingsProvider';
import { ManagerContext } from 'provider/manager/managerProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import { getEditEventRoute } from './EventCard';
import { maxNameLength } from './general/GeneralEventSettings';
import { SubscriptionType } from 'Colugo/interfaces/subscriptions/ISubscription';

enum LocationCheckOption {
  BusinessAddress = 'Business Address',
  OtherAddress = 'Other Address',
  AddManually = 'Add Manually'
}

const eventOperations = container.resolve(EventOperations);

const emptyEvent: IEvent = {
  name: '',
  isActive: false,
  address: undefined
};

type Errors = {
  name: string;
  address: string;
};

function CreateEvent() {
  const { errorToast } = useErrorToast();
  const { listing, mutateListing } = useContext(ManagerContext);
  const { listings, mutateListings } = useContext(ListingsContext);
  const { events, mutateEvents } = useContext(EventsContext);
  const { isSuperAdmin, setIsEventNewlyCreated } = useContext(UserContext);
  const [isLocationModalOpen, setIsLocationModalOpen] =
    useState<boolean>(false);
  const [selectedListing, setSelectedListing] = useState<IListing | undefined>(
    listing
  );
  const [otherAddress, setOtherAddress] = useState<
    ILocationDetails | undefined
  >(undefined);
  const [isCreateButtonLoading, setIsCreateButtonLoading] = useState(false);
  const [errors, setErrors] = useState<Errors | undefined>(undefined);
  function setNameError(name: string) {
    setErrors({ ...errors, name: name } as Errors);
  }
  function setAddressError(address: string) {
    setErrors({ ...errors, address: address } as Errors);
  }
  const [locationOption, setLocationCheckOption] =
    useState<LocationCheckOption>(LocationCheckOption.BusinessAddress);
  const [localEvent, setLocalEvent] = useState<IEvent>(emptyEvent);

  const navigate = useNavigate();

  const isListingAddress =
    locationOption === LocationCheckOption.BusinessAddress;
  const currentEventWithLocationDetails: IEvent = {
    ...localEvent,
    location:
      isListingAddress && !!selectedListing
        ? selectedListing.location
        : otherAddress?.location,
    address:
      isListingAddress && !!selectedListing
        ? selectedListing.address
        : otherAddress?.address
  };

  function updateListingsForCreatedEvent(event: IEvent) {
    const listingToUpdate = listings.find(
      (listing) => listing.id === event.listing?.id
    );
    if (!listingToUpdate) {
      return;
    }
    if (!listingToUpdate.events) {
      listingToUpdate.events = [];
    }
    listingToUpdate.events = [
      ...events.filter((p) => p.id !== event!.id),
      event
    ];
    mutateListings([
      ...listings.filter((p) => p.id !== event?.listing!.id),
      listingToUpdate
    ]);
  }
  const subscriptionType =
    listing?.subscription?.subscriptionType ?? SubscriptionType.Essential;

  const isWithinEventLimit =
    !listing?.events ||
    listing?.events.length < maximumEventAndPromotionCount[subscriptionType];

  async function submitCreateEventAsync() {
    if (!isWithinEventLimit) {
      errorToast('Already reached maximum event count');
      return;
    }
    setErrors(undefined);
    if (!localEvent.name) {
      setNameError('No event name provided.');
      return;
    }
    if (localEvent.name.length > maxNameLength) {
      setNameError('Name for this event is too long.');
      return;
    }

    if (!currentEventWithLocationDetails.address) {
      setAddressError('Provide an address for the event.');
      return;
    }

    if (
      isListingAddress &&
      selectedListing &&
      (!selectedListing.address ||
        !(
          selectedListing?.address.addressString ||
          Object.keys(selectedListing?.address).length > 3
        ))
    ) {
      setAddressError(
        `The business assigned doesn't have an address, please add an address to create this event.`
      );
      return;
    }

    setIsCreateButtonLoading(true);

    const localEventDraft: IEvent = {
      isActive: false,
      name: localEvent.name,
      listing: selectedListing,
      location: currentEventWithLocationDetails.location,
      address:
        isListingAddress && selectedListing
          ? {}
          : currentEventWithLocationDetails.address,
      inheritAddress: isListingAddress && !!selectedListing,
      startDate: '01/01/0001'
    };

    const { data: isUnique } = await eventOperations.checkNameForLocationAsync(
      localEventDraft
    );

    if (!isUnique) {
      setIsCreateButtonLoading(false);
      setAddressError(
        'Event location already exists for this name. Please enter a different event location.'
      );
      return;
    }

    const { data: createdEvent, error } = await eventOperations.createAsync(
      localEventDraft
    );

    if (error || !createdEvent) {
      errorToast('Failed to create event');
      setIsCreateButtonLoading(false);
      return;
    }

    setIsCreateButtonLoading(false);
    mutateEvents([...events, { ...createdEvent, listing: selectedListing }]);
    mutateListing({
      ...listing!,
      events: [...(listing?.events || []), createdEvent]
    });
    updateListingsForCreatedEvent(createdEvent);
    setIsEventNewlyCreated(true);
    navigate(getEditEventRoute(createdEvent, isSuperAdmin));
  }

  function handleSelectedListing(listing: IListing | undefined) {
    setSelectedListing(listing);
    if (listing) {
      setLocalEvent({
        ...localEvent,
        location: {
          latitude: listing?.location?.latitude,
          longitude: listing?.location?.longitude
        },
        address: listing.address
      });
    }

    if (otherAddress) {
      setLocationCheckOption(LocationCheckOption.OtherAddress);
    }
  }

  function handleClearSelection() {
    setSelectedListing(undefined);
    setLocalEvent({
      ...localEvent,
      location: {},
      address: {}
    });
  }

  function handleToggleAddressType(
    newLocationCheckOption: LocationCheckOption
  ) {
    setLocationCheckOption(newLocationCheckOption);
    setErrors(undefined);
  }

  function checkOption(locationCheckOption: LocationCheckOption) {
    return (
      <div className="flex items-center gap-1 mr-2">
        <ImprovedCheckbox
          type="radio"
          onChange={() => handleToggleAddressType(locationCheckOption)}
          unselectedBorderColour={'hot'}
          checked={locationOption === locationCheckOption}
          selectedBackgroundColour="primary"
          selectedColour="primary"
        />
        {locationCheckOption}
      </div>
    );
  }

  const isOtherAddress = locationOption === LocationCheckOption.OtherAddress;

  return (
    <div className="flex flex-col bg-pageColour h-screen max-h-screen overflow-y-auto">
      <div>
        <CustomFormHeader
          title="Create New Event"
          actionTitle="Submit"
          onPressAction={async () => await submitCreateEventAsync()}
          isActionLoading={isCreateButtonLoading}
          onPressClose={() => {
            setIsEventNewlyCreated(false);
            navigate(-1);
          }}
        />
        {isLocationModalOpen && (
          <LocationModal
            setIsModalOpen={setIsLocationModalOpen}
            onConfirmLocation={(locationDetails: ILocationDetails) =>
              setOtherAddress({
                ...otherAddress,
                location: locationDetails.location,
                address: locationDetails.address
              })
            }
            initialLocationDetails={{
              address: otherAddress?.address || {},
              location: otherAddress?.location
            }}
          />
        )}
        <div
          className="flex-col items-center justify-center ml-auto mr-auto"
          style={{ width: '714px' }}
        >
          <div
            className="mt-10 text-lg font-inter font-normal text-onSurfaceVariant"
            style={{ lineHeight: '22px', width: '714px' }}
          >
            <div className="mt-6">
              <p className="font-inter text-onSurface font-bold text-base mb-2">
                Event Name
              </p>
              <ImprovedInput
                className="h-full py-4 focus:outline-none"
                wrapperClassName="border rounded-lg bg-white flex-row items-center focus:border-onSurface px-6"
                maxCharacterCount={70}
                onChange={(e) =>
                  setLocalEvent({
                    ...localEvent,
                    name: e.currentTarget.value
                  })
                }
                placeHolder="Enter Event Name"
                value={localEvent.name}
                error={errors?.name}
                isError={!!errors?.name}
              />
            </div>
            <div
              className="flex flex-col text-left font-inter text-primaryDark font-bold mt-4"
              style={{ fontSize: '16px', lineHeight: '24px' }}
            >
              {isSuperAdmin && (
                <div>
                  Event Business
                  <div>
                    <ListingSearchModal
                      selectedListing={selectedListing}
                      setSelectedListing={(listing) =>
                        handleSelectedListing(listing)
                      }
                      goBack={() => navigate('')}
                      isDisabled={!isListingAddress}
                      handleClearEventSelection={handleClearSelection}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="flex flex-col text-left font-inter text-base leading-4 text-primaryDark font-bold my-3">
                Event Address
              </div>
              <div>
                <div className="flex font-inter font-normal text-primaryDark">
                  {checkOption(LocationCheckOption.BusinessAddress)}
                  {checkOption(LocationCheckOption.OtherAddress)}
                </div>
              </div>
              <div className="mt-4">
                <LocationSnippet
                  latitude={
                    isOtherAddress
                      ? otherAddress?.location?.latitude
                      : selectedListing?.location?.latitude
                  }
                  longitude={
                    isOtherAddress
                      ? otherAddress?.location?.longitude
                      : selectedListing?.location?.longitude
                  }
                  address={
                    isOtherAddress
                      ? otherAddress?.address
                      : selectedListing?.address || {
                          addressString: 'Location Provided'
                        }
                  }
                  openLocationModal={() => setIsLocationModalOpen(true)}
                  canOpenLocationModal={isOtherAddress}
                />
                {!!errors?.address && (
                  <div className="text-sm font-semibold text-primary mt-1">
                    {errors?.address}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateEvent;
