import { IStripePrice } from 'Colugo/interfaces/payment/IStripePrice';

export function getStripePriceDetails(
  stripePrices: IStripePrice[] | undefined,
  lookupKey: string
) {
  if (!stripePrices) {
    return;
  }

  const priceId = stripePrices.find((sp) => sp.lookupKey === lookupKey)?.id;
  const price =
    (stripePrices!.find((sp) => sp.lookupKey === lookupKey)?.unitAmount ?? 0) /
    100;
  if (priceId && price) {
    const stripePrice: IStripePrice = {
      id: priceId!,
      lookupKey: lookupKey,
      unitAmount: price
    };
    return stripePrice;
  }
  return;
}
