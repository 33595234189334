import 'firebase/analytics';
import 'firebase/auth'; // Import Firebase Auth
import {
  Analytics,
  getAnalytics,
  isSupported,
  logEvent
} from 'firebase/analytics';
import { firebaseApp } from 'utility/firebase';

let analytics: Analytics;
export async function initializeFirebaseAnalyticsAsync() {
  const supported = await isSupported();
  if (supported) {
    analytics = getAnalytics(firebaseApp);
  }
}

export const signOutEvent = (label?: string) => {
  logEvent(analytics, 'sign_out', {
    event_label: label
  });
};

export const signInEvent = (label?: string) => {
  logEvent(analytics, 'sign_in', {
    event_label: label
  });
};
