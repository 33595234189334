import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import { useReqGetAnalyticsAsync } from 'Colugo/operations/analytics/AnalyticsOperations';
import BusinessAnalytics from 'components/business/dashboard/BusinessAnalytics';

type Props = {
  localEntity: IListing | IEvent;
};

function AnalyticsTabContainer(props: Props) {
  const { localEntity } = props;

  const {
    data: listingAnalytics,
    isLoading: isLoadingListingAnalytics,
    isError: isErrorListingAnalytics,
    revalidate: revalidateListingAnalytics,
    isValidating: isValidatingListingAnalytics
  } = useReqGetAnalyticsAsync(localEntity.id);

  return (
    <div className="w-full h-full flex flex-row justify-center">
      <BusinessAnalytics
        providedListing={localEntity}
        providedListingAnalytics={listingAnalytics}
        isLoadigProvidedListingAnalytics={isLoadingListingAnalytics}
        isErrorPovidedListingAnalytics={isErrorListingAnalytics}
        isValidatingProvidedListingAnalytics={isValidatingListingAnalytics}
        revalidateProvidedListingAnalyticsAsync={revalidateListingAnalytics}
      />
    </div>
  );
}

export default AnalyticsTabContainer;
