import React from 'react';
import { IoIosClose } from 'react-icons/io';

type Props = {
  header?: string;
  children: React.ReactNode;
  onCloseHint?: () => void;
  padding?: string;
  styles?: React.CSSProperties;
  className?: string;
};

function HintBox(props: Props) {
  const {
    header,
    onCloseHint,
    children,
    padding = '24px',
    styles,
    className
  } = props;

  return (
    <div
      className={`border bg-white rounded-xl border-gray-300 transition-all duration-300 mb-3
        ${className}`}
      style={{
        padding: padding,
        backgroundColor: 'rgba(239, 239, 247, 1)',
        ...styles
      }}
    >
      <div className="flex justify-between items-start">
        <div className="">
          {header && (
            <p className="text-onSurface text-left text-black font-extrabold text-lg border">
              {header}
            </p>
          )}
          {children}
        </div>
        {onCloseHint && (
          <button onClick={onCloseHint}>
            <IoIosClose size={24} />
          </button>
        )}
      </div>
    </div>
  );
}

export default HintBox;
