import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import EventOperations from 'Colugo/operations/events/EventOperations';
import Analytics from 'components/business/dashboard/Analytics';
import AutoSaveTimer from 'components/listings/AutoSaveTimer';
import PromotionTabContainer from 'components/listings/promotions/PromotionTabContainer';
import ImprovedSwitch from 'components/shared/ImprovedSwitch';
import ImprovedTooltip from 'components/shared/ImprovedTooltip';
import LinkContainer from 'components/shared/LinkContainer';
import TabComponent from 'components/shared/TabComponent';
import { EventTabList, ITab } from 'components/shared/TabList';
import { ManagerContext } from 'provider/manager/managerProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useEffect, useState } from 'react';
import { FaCircleCheck } from 'react-icons/fa6';
import { TiWarning } from 'react-icons/ti';
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { container } from 'tsyringe';
import { validateEvent } from 'utility/general/validators';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import DateTimeContainer from './dateTime/DateTimeContainer';
import GeneralEventTabContainer from './general/GeneralEventTabContainer';
import EventLocationTabContainer from './location/EventLocationTabContainer';
import TaggingEventContainer from './tagging/TaggingEventContainer';

const eventOperation = container.resolve(EventOperations);

type Props = {
  event: IEvent;
  mutate: (event: IEvent, revalidate?: boolean) => void;
  incrementTabClickCount: () => void;
};

function EditEventEditor(props: Props) {
  const { event, mutate, incrementTabClickCount } = props;
  const { errorToast } = useErrorToast();
  const [eventSaved, setEventSaved] = useState(false);
  const [localEvent, setLocalEvent] = useState<IEvent>(event);
  const validationErrors = validateEvent(localEvent);

  const { isSuperAdmin } = useContext(UserContext);
  const { setHasActivatedEvent, hasActivatedEvent, listing, mutateListing } =
    useContext(ManagerContext);

  const expectedUrl = isSuperAdmin
    ? '/' + PuffinRoutes.EditEvent
    : '/' + PuffinRoutes.ManagerEditEvent;

  const match = useMatch(expectedUrl);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLocalEvent(event);
  }, [event]);

  useEffect(() => {
    setEventSaved(true);
  }, [localEvent]);

  const isActive = localEvent.isActive;

  const currentTabName = EventTabList(isSuperAdmin).find((tab) =>
    location.pathname.includes(tab.path)
  )?.name;

  const tabs = EventTabList(isSuperAdmin, currentTabName);

  async function updateIsActiveForInvalidEventAsync() {
    if (!isActive) {
      return true;
    }

    errorToast('Event Inactive, due to missing field');

    setEventSaved(true);
    const hiddenEvent: IEvent = {
      ...localEvent,
      isActive: false
    };
    await eventOperation.inactivateAsync(hiddenEvent.id!);
    return false;
  }

  async function updateEventAsync(updatedEvent: IEvent) {
    if (!updatedEvent) {
      errorToast('Unable to edit event');
      return false;
    }

    setLocalEvent(updatedEvent);
    setEventSaved(true);
    const { error } = await eventOperation.updateAsync(updatedEvent);

    if (error) {
      errorToast('Failed to edit event.');
      return false;
    }

    if (isActive && validateEvent(updatedEvent).length > 0) {
      await updateIsActiveForInvalidEventAsync();
      setLocalEvent({
        ...updatedEvent,
        isActive: false
      });
    }

    mutate(updatedEvent);
    mutateListing({
      ...listing!,
      events: [
        ...(listing?.events?.filter((e) => e.id !== updatedEvent.id) || []),
        updatedEvent
      ]
    });
    return true;
  }

  async function checkAndUpdateVisibilityAsync() {
    toast.dismiss();

    if (!isActive) {
      if (validationErrors.length > 0) {
        validationErrors.forEach((error) => errorToast(error.tabMessage));
        return;
      }
    }
    toast.dismiss();
    toast.info(
      <div className="flex items-center">
        {!isActive ? (
          <FaCircleCheck className="text-green" size={20} />
        ) : (
          <TiWarning className="text-primary" size={24} />
        )}
        <span className="text-onSurface font-inter ml-2">
          Your Event is now {isActive ? 'inactive' : 'active'}.
        </span>
      </div>,
      {
        className: 'font-nunito'
      }
    );

    if (!localEvent) {
      toast.warn('No event selected, please refresh');
      return;
    }

    if (!hasActivatedEvent) {
      setHasActivatedEvent(localEvent.id || '');
    }

    setLocalEvent({ ...localEvent, isActive: !localEvent.isActive });
    setEventSaved(true);
    await updateEventAsync({ ...localEvent, isActive: !localEvent.isActive });
  }

  function handleOnClickTab(tab: ITab) {
    navigate(`${match?.pathnameBase}${tab.path}`);
    incrementTabClickCount();
  }

  return (
    <div className="bg-theme1 h-auto w-full mb-5">
      {isSuperAdmin && (
        <div
          className="flex flex-row justify-between w-full h-10 ml-auto mr-auto"
          style={{ width: '80vw', marginTop: '20px' }}
        >
          <div className="font-inter font-bold">
            <a
              className="mt-1 text-2xl cursor-pointer hover:border-black border-b transition-all duration-200"
              href={'https://bindy-street.webflow.io/event?id=' + event.id}
            >
              {event.name}
            </a>
            <div>Popular: {event.popularScore?.toFixed(2)}</div>
            <div className="mb-2">
              Trending: {event.trendingScore?.toFixed(2)}
            </div>
          </div>
          <AutoSaveTimer
            entitySaved={eventSaved}
            setListingSaved={setEventSaved}
          />
        </div>
      )}
      <div
        className="flex flex-row items-end justify-between h-14 mt-10 ml-auto mr-auto relative"
        style={{ width: '80vw' }}
      >
        <div className="flex flex-row">
          {tabs.map((tab, i) => (
            <TabComponent
              key={i}
              index={i}
              tab={tab}
              activeTabName={currentTabName!}
              onClickTab={handleOnClickTab}
            />
          ))}
        </div>
        <div
          className="px-6 py-3 flex flex-row items-center"
          style={{
            borderRadius: '8px 8px 0px 0px',
            borderTop: '1px solid #E6E6E6',
            borderRight: '1px solid #E6E6E6',
            borderLeft: '1px solid  #E6E6E6'
          }}
        >
          {isActive ? (
            <FaCircleCheck className="text-green" size={20} />
          ) : (
            <TiWarning className="text-primary" size={24} />
          )}
          <div className="ml-2 font-medium font-inter text-base">
            {isActive ? 'Active' : 'Inactive'} on Ember
          </div>
          <ImprovedSwitch
            className="ml-2 mt-2"
            checked={localEvent.isActive}
            onChange={async () => await checkAndUpdateVisibilityAsync()}
          />
        </div>
        {!hasActivatedEvent && validationErrors.length === 0 && !isActive && (
          <div className="absolute right-0 top-14 z-20 mt-4">
            <ImprovedTooltip
              title={
                "Don't forget to toggle this to make your event active on the app."
              }
              isSuperAdmin={isSuperAdmin}
            />
          </div>
        )}
      </div>
      <div
        className="bg-white flex flex-col items-start ml-auto mr-auto"
        style={{
          borderRadius: '0px 0px 12px 12px',
          border: '1px solid rgba(0, 0, 0, 0.10)',
          width: '80vw'
        }}
      >
        <Routes>
          <Route
            path="/general"
            element={
              <GeneralEventTabContainer
                event={localEvent}
                setLocalEvent={setLocalEvent}
                validationErrors={validationErrors}
                updateIsActiveForInvalidEventAsync={
                  updateIsActiveForInvalidEventAsync
                }
                updateEventAsync={updateEventAsync}
                mutate={mutate}
              />
            }
          />
          <Route
            path="/tagging"
            element={
              <TaggingEventContainer
                localEvent={localEvent}
                setLocalEvent={setLocalEvent}
                validationErrors={validationErrors}
                updateIsActiveForInvalidEventAsync={
                  updateIsActiveForInvalidEventAsync
                }
                mutate={mutate}
              />
            }
          />
          <Route
            path="/location"
            element={
              <EventLocationTabContainer
                localEvent={localEvent}
                setLocalEvent={setLocalEvent}
                updateEventAsync={updateEventAsync}
                validationErrors={validationErrors}
              />
            }
          />
          <Route
            path="/datetime"
            element={
              <DateTimeContainer
                localEvent={localEvent}
                setLocalEvent={setLocalEvent}
                validationErrors={validationErrors}
                updateEventAsync={updateEventAsync}
                updateIsActiveForInvalidEventAsync={
                  updateIsActiveForInvalidEventAsync
                }
              />
            }
          />
          <Route
            path="/link"
            element={
              <LinkContainer
                localEntity={localEvent}
                setLocalEntity={setLocalEvent}
                updateEntityAsync={updateEventAsync}
                name={'Event'}
              />
            }
          />
          <Route
            path="/promotions"
            element={
              <PromotionTabContainer
                localEntity={localEvent}
                setLocalEntity={setLocalEvent}
              />
            }
          />
          <Route
            path="/analytics"
            element={
              <div className="w-full h-full flex flex-row my-8">
                <div className="mx-auto">
                  <Analytics
                    entity={localEvent}
                    title={'Analytics'}
                    isPremiumOrPlusBusiness={true}
                  />
                </div>
              </div>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default EditEventEditor;
