import { Input } from '@bindystreet/bindystreet.kit.react';
import { IIoiValidationError } from 'Colugo/interfaces/IIoiValidationError';
import { ICategoryUserView } from 'Colugo/interfaces/common';
import { IEvent } from 'Colugo/interfaces/event/IEvent';
import { IListing } from 'Colugo/interfaces/listing/IListing';
import EventOperations from 'Colugo/operations/events/EventOperations';
import ListingSearchModal from 'components/listings/ListingSearchModal';
import ConfirmationPopup from 'components/shared/ConfirmationPopup';
import { produce } from 'immer';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useMemo, useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { toast } from 'react-toastify';
import CategorySelector from 'storybook/CategorySelector';
import { container } from 'tsyringe';
import { useErrorToast } from 'utility/hooks/useErrorToast';

export const maxDescriptionLength = 600;
export const maxNameLength = 70;
const eventOperations = container.resolve(EventOperations);

type Props = {
  localEvent: IEvent;
  setLocalEvent: (localEvent: IEvent) => void;
  updateEventAsync: (updatedEvent: IEvent) => Promise<boolean>;
  categories: ICategoryUserView[];
  handleRemoveCategoryAsync: (
    index: number,
    categoryId: string
  ) => Promise<void>;
  handleAddCategoryAsync: (categoryId: string) => Promise<boolean>;
  validationErrors: IIoiValidationError[];
};

function GeneralEventSettings(props: Props) {
  const {
    localEvent,
    setLocalEvent,
    updateEventAsync,
    categories,
    handleRemoveCategoryAsync,
    handleAddCategoryAsync,
    validationErrors
  } = props;

  const { errorToast } = useErrorToast();
  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [isRemoveListingModalOpen, setIsRemoveListingModalOpen] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eventName, setEventName] = useState<string>(localEvent.name);
  const [isNameError, setIsNameError] = useState<boolean>(false);
  const [selectedListing, setSelectedListing] = useState<IListing | undefined>(
    undefined
  );

  const { isSuperAdmin } = useContext(UserContext);

  const updatedCategories = useMemo(() => {
    return categories.map((category) => {
      if (localEvent.categories?.some((c) => c.id === category.id)) {
        return produce(category, (draft) => {
          draft.isGuideOrItemInCategory = true;
        });
      }
      return category;
    });
  }, [categories, localEvent]);

  const updatedCategoriesCount = updatedCategories.filter(
    (uc) => uc.isGuideOrItemInCategory
  ).length;

  async function handleOnEditNameAsync() {
    setIsLoading(true);
    if (!eventName) {
      setErrorMessage(
        'No Event name provided, please enter a name for your Event'
      );
      setIsNameError(true);
      setEventName(localEvent.name);
      return;
    }
    if (eventName.length > maxNameLength) {
      return;
    }
    setIsNameError(false);

    const eventToUpdate: IEvent = {
      ...localEvent,
      name: eventName
    };

    const { data: isUnique, error } =
      await eventOperations.checkNameForLocationAsync(eventToUpdate);

    setIsLoading(false);
    if (error) {
      errorToast('Failed to check event name, please try again');
      return;
    }

    if (!isUnique) {
      setErrorMessage(
        'Event name already exists. Please enter a different Event name.'
      );
      setIsNameError(true);
      return;
    }

    const isSuccessful = await updateEventAsync(eventToUpdate);

    if (isSuccessful) {
      setIsEditNameModalOpen(false);
    } else {
      errorToast('Unable to edit Event.');
    }
  }

  function handleCloseNameChangeModal() {
    setIsEditNameModalOpen(false);
    setEventName(localEvent.name);
    setIsNameError(false);
  }

  async function handleAddListingAsync(listing: IListing | undefined) {
    if (listing) {
      setSelectedListing(listing);
      setLocalEvent({
        ...localEvent,
        listing: listing
      });

      const isSuccessful = await updateEventAsync({
        ...localEvent,
        listing: listing
      });

      if (!isSuccessful) {
        setLocalEvent(localEvent);
        setSelectedListing(undefined);
        return;
      }

      toast.success('Listing was successfully added');
    }
  }

  async function handleRemoveListingAsync() {
    setIsLoading(true);
    setSelectedListing(undefined);
    setLocalEvent({
      ...localEvent,
      listing: undefined,
      inheritAddress: false
    });

    const isSuccessful = await updateEventAsync({
      ...localEvent,
      listing: undefined,
      inheritAddress: false
    });

    if (!isSuccessful) {
      setLocalEvent(localEvent);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setIsRemoveListingModalOpen(false);
    toast.success('Listing was successfully removed');
  }

  return (
    <div className="flex flex-col w-full">
      <div className="font-inter text-2xl font-bold">General Settings</div>
      <div className="flex flex-col w-full">
        <div className="mt-3 text-base font-inter font-bold text-primaryCharcoal">
          Event Name
        </div>
        <div className="flex flex-row w-full mt-2" style={{ height: '84px' }}>
          <div
            className="bg-theme3 rounded-lg flex flex-col items-start px-6 py-4 text-secondaryText"
            style={{ width: '85%', height: '54px', borderColor: '#0000001a' }}
          >
            {eventName}
          </div>
          <div className="border-r-2 border-r-gray ml-6"></div>
          <div
            style={{ width: '247px' }}
            className="ml-6 w-full text-sm font-inter font-normal"
          >
            <div className="flex flex-col">
              Any change to your Event Name must be requested, as it will be
              subject to our review process.
              <span
                className="text-primary cursor-pointer"
                onClick={() => setIsEditNameModalOpen(true)}
              >
                Request change?
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full">
        <div className="mt-3 text-base font-inter font-bold text-primaryCharcoal">
          Listing
        </div>
        <div className="flex flex-row w-full mt-2">
          {localEvent.listing ? (
            <div
              className="bg-white rounded-lg flex justify-between px-6 py-4 border text-secondaryText"
              style={{
                width: '85%',
                height: '54px',
                borderColor: 'rgba(0, 0, 0, 0.10)'
              }}
            >
              <div>{localEvent.listing?.name}</div>

              {isSuperAdmin && (
                <div onClick={() => setIsRemoveListingModalOpen(true)}>
                  <IoMdClose className="text-2xl text-black cursor-pointer" />
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                width: '85%',
                height: '54px',
                borderColor: 'rgba(0, 0, 0, 0.10)'
              }}
              className="-mt-4"
            >
              <ListingSearchModal
                selectedListing={selectedListing}
                setSelectedListing={(listing) => handleAddListingAsync(listing)}
                className={`border bg-white rounded-lg shadow absolute overflow-y-auto flex flex-col z-50`}
                flyoutStyle={{ zIndex: 100, width: '62%' }}
              />
            </div>
          )}

          <div className="border-r-2 border-r-gray ml-6"></div>
          <div
            style={{ width: '247px' }}
            className="ml-6 w-full text-sm font-inter font-normal"
          >
            This is the business holding, or associated to, the event.
          </div>
        </div>
      </div>

      {isEditNameModalOpen && (
        <ConfirmationPopup
          isModalOpen={isEditNameModalOpen}
          handleClickConfirmButton={handleOnEditNameAsync}
          closeModal={handleCloseNameChangeModal}
          popupLabel={'Edit Event Name'}
          confirmButtonText="Change Event Name"
          Icon={FaSave}
          isErrorButton={false}
          isLoading={isLoading}
          height="270px"
        >
          <div>
            <div className="text-left text-base font-inter text-primaryCharcoal font-bold">
              Event Name
            </div>
            <Input
              isMandatory={true}
              value={eventName}
              placeholder="Enter event name"
              size="lg"
              error={isNameError && errorMessage}
              showErrorText={true}
              onChange={(e) => setEventName(e.currentTarget.value)}
              maxCharacterCount={maxNameLength}
            />
          </div>
        </ConfirmationPopup>
      )}

      {isRemoveListingModalOpen && (
        <ConfirmationPopup
          isModalOpen={isRemoveListingModalOpen}
          handleClickConfirmButton={async () =>
            await handleRemoveListingAsync()
          }
          closeModal={() => setIsRemoveListingModalOpen(false)}
          popupLabel={'Remove Listing from Event? '}
          confirmButtonText="Remove Listing"
          isErrorButton={true}
          isLoading={isLoading}
          height="240px"
        >
          <div>
            <div className="text-left text-base font-nunito text-primaryCharcoal font-normal">
              Are you sure you want to remove this listing from the event?
              Removing a listing from an event will also remove the listing
              address data. Meaning your event could be made inactive until you
              add another address.
            </div>
          </div>
        </ConfirmationPopup>
      )}

      <div className="mt-6 flex flex-col w-full">
        <div className="mt-3 text-base font-inter font-bold text-primaryCharcoal">
          Short Description
        </div>
        <div className="flex flex-row w-full mt-2" style={{ height: '136px' }}>
          <div style={{ width: '85%' }}>
            <Input
              value={localEvent?.description}
              className="-mt-2 overflow-y-auto"
              multiline
              placeholder="Enter your short description"
              size="xl"
              onBlurWithinCharacterLimit={async () =>
                await updateEventAsync(localEvent)
              }
              onChange={(e) =>
                setLocalEvent({
                  ...localEvent,
                  description: e.currentTarget.value
                })
              }
              maxCharacterCount={maxDescriptionLength}
              resizable={false}
            />
          </div>
          <div className="border-r-2 border-r-gray ml-6"></div>
          <div
            style={{ width: '247px' }}
            className="ml-6 w-full text-sm font-inter font-normal"
          >
            Your short description should be a fun, punchy introduction or
            explanation about your event. Please try to keep this to a minimum
            of 3 - 5 sentences long.
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full">
        <div className="mt-3 text-base font-inter font-bold text-primaryCharcoal">
          Category
        </div>
        <div className="flex flex-row w-full mt-2" style={{ height: '134px' }}>
          <div className="flex flex-col" style={{ width: '85%' }}>
            <div className="-ml-1">
              <CategorySelector
                categories={updatedCategories}
                handleRemoveCategoryAsync={handleRemoveCategoryAsync}
                handleAddCategoryAsync={handleAddCategoryAsync}
                pillWidth="292px"
                validationErrors={validationErrors}
              />
            </div>
            <div className="flex flex-row justify-end">
              <div className="mt-2 mr-5 abso text-base font-inter font-normal text-primaryCharcoal">
                {` ${updatedCategoriesCount}/2 categories`}
              </div>
            </div>
          </div>

          <div className="border-r-2 border-r-gray ml-6"></div>
          <div
            style={{ width: '247px' }}
            className="ml-6 w-full text-sm font-inter font-normal"
          >
            Select up to two categories that are relevant to your Event.
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralEventSettings;
