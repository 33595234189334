import { IBase } from '..';
import { ICreator } from './';

export default interface IUser extends IBase {
  displayName?: string;
  username?: string;
  email: string;
  tag: string;
  tagId: string;
  fullTag: string;
  avatar: string;
  applicationRoles?: ApplicationRoles;
  firstName?: string;
  lastName?: string;
  phoneNumber: string;
  creator: ICreator;
  roles: string[];
  dateOfBirth?: string;
  stripeCustomerId?: string;
  bio?: string;
}

export enum ApplicationRoles {
  User = 1,
  Manager = 2,
  Administrator = 4,
  BindyAdmin = User | Manager | Administrator
}
