import ICreateBusinessUser from 'Colugo/interfaces/identity/createUser/ICreateUser';
import { AuthOrigin } from 'Colugo/interfaces/identity/IAuthOrigin';
import ky, { Options } from 'ky';
import { BUDGIE_API_URL, localToken } from 'utility/constants/constants';
import localStore from 'utility/general/localStore';

const authOptions: Options = {
  prefixUrl: BUDGIE_API_URL
};
export const authHttpClient = ky.create(authOptions);

const signInAsync = async (
  email: string,
  password: string,
  setErrors: (errors: any) => void,
  setToken: (token: any) => void
) => {
  let token: string;
  try {
    token = await authHttpClient
      .post(`auth/handle?loginOrigin=${AuthOrigin.Puffin}`, {
        json: { email, password }
      })
      .json();

    localStore.setItem('token', token);
    setToken(window.localStorage.token);
  } catch (err) {
    handleAuthErrorAsync(err, setErrors);
  }
};

const forgotPasswordAsync = async (
  setErrors: (errors: any) => void,
  email: string
) => {
  try {
    await authHttpClient.post(
      `auth/forgotPassword?authOrigin=${AuthOrigin.Puffin}`,
      {
        json: { email }
      }
    );
  } catch (err) {
    handleAuthErrorAsync(err, setErrors);
  }
};

const resetPasswordAsync = async (
  setErrors: (errors: any) => void,
  newPassword: string,
  oobCode: string
) => {
  try {
    await authHttpClient.post('auth/confirmResetPassword', {
      json: { newPassword, oobCode }
    });
    return true;
  } catch (err) {
    handleAuthErrorAsync(err, setErrors);
    return false;
  }
};

const signOutAsync = (
  setErrors: (errors: any) => void,
  setToken: (token: any) => void,
  messageFromSystem?: string
) => {
  //TODO: Signout call on api
  try {
    localStore.removeItem(localToken);
    setToken(null);
    if (messageFromSystem) {
      setErrors(() => [messageFromSystem]);
    }
    return true;
  } catch (err) {
    handleAuthErrorAsync(err, setErrors);
  }
};

const signUpAsync = async (
  createUser: ICreateBusinessUser,
  setErrors: (errors: any) => void
) => {
  try {
    await authHttpClient.post('auth/create?loginOrigin=Puffin', {
      json: createUser
    });
    return true;
  } catch (err) {
    await handleAuthErrorAsync(err, setErrors);
    return false;
  }
};

const getToken = () => localStore.getItem('token');

const handleAuthErrorAsync = async (
  err: any,
  setErrors: (errors: any) => void
) => {
  const response: Response = err.response;

  let body;
  try {
    body = await response.json();
  } catch (err) {
    console.error(err);
  }

  if (response && response.status === 403) {
    setErrors(() => [body.message || 'Email or password is incorrect.']);
  } else if (response && response.status < 500) {
    const msg = body.message || 'Something went wrong, please try again later.';
    setErrors(() => [msg]);
  } else {
    setErrors(() => ['Ember is unavailable. Please try again later.']);
  }
};

const authMethods = {
  signInAsync,
  signOutAsync,
  signUpAsync,
  getToken,
  forgotPasswordAsync,
  resetPasswordAsync
};

export default authMethods;
