import {
  Button,
  Checkbox,
  Form,
  Input
} from '@bindystreet/bindystreet.kit.react';
import { InputFormEvent } from '@bindystreet/bindystreet.kit.react/dist/components/Input';
import ICreateUserIdp from 'Colugo/interfaces/identity/createUser/ICreateUserIdp';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import AuthOperations from 'Colugo/operations/identity/AuthOperations';
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from 'provider/auth/authProvider';
import { useContext, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { container } from 'tsyringe';
import Validator from 'utility/general/RegexValidator';
import { useErrorToast } from 'utility/hooks/useErrorToast';
import { UserOperations } from 'Colugo/operations/identity';
import { UserContext } from 'provider/user/userProvider';
import { IGoogleUser } from 'Colugo/interfaces/identity/IGoogleUser';

const authOperations = container.resolve(AuthOperations);
const userOperations = container.resolve(UserOperations);

export interface FormError {
  field: string;
  message: string;
}

function SignUp() {
  const {
    handleSignUpAsync,
    inputs,
    resetState,
    setInputs,
    errors,
    setToken,
    isLoading,
    setIsLoading
  } = useContext(AuthContext);
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean>(true);
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { errorToast } = useErrorToast();

  const oAuthIdToken = searchParams.get('idToken');

  function decodeToken(token: string) {
    if (!token) {
      return;
    }
    const decodedToken: IGoogleUser = jwtDecode(token);
    return decodedToken;
  }

  useEffect(() => {
    if (oAuthIdToken) {
      const decodedToken = decodeToken(oAuthIdToken);

      if (
        decodedToken &&
        (decodedToken.given_name ||
          decodedToken.family_name ||
          decodedToken.email)
      ) {
        setInputs((prev) => ({
          ...prev,
          firstName: decodedToken.given_name || '',
          lastName: decodedToken.family_name || '',
          email: decodedToken.email || ''
        }));
      }
    }
  }, [setInputs, oAuthIdToken]);

  function validateFields() {
    let isSubmitValid = true;
    let errors: FormError[] = [];

    if (!Validator.isEmailValid(inputs.email)) {
      errors.push({
        field: 'email',
        message: 'Please provide a valid email address'
      });
      isSubmitValid = false;
    }

    if (!Validator.isPhoneNumberValid(inputs.phoneNumber)) {
      errors.push({
        field: 'contact',
        message: 'Please provide a valid contact number'
      });
      isSubmitValid = false;
    }

    if (!inputs.firstName) {
      errors.push({
        field: 'firstName',
        message: 'Please provide your first name'
      });
      isSubmitValid = false;
    }

    if (!inputs.lastName) {
      errors.push({
        field: 'lastName',
        message: 'Please provide your last name'
      });
      isSubmitValid = false;
    }

    if (!inputs.password || !Validator.isPasswordValid(inputs.password)) {
      errors.push({
        field: 'password',
        message: 'Please provide a password'
      });
      isSubmitValid = false;
    }

    if (!inputs.confirmPassword) {
      errors.push({
        field: 'confirmPassword',
        message: 'Please retype your password'
      });
      isSubmitValid = false;
    }

    if (!isTermsAccepted) {
      errors.push({
        field: 'terms',
        message: `Please agree to Ember's Terms & Conditions`
      });
      isSubmitValid = false;
    }

    setFormErrors(errors);
    return isSubmitValid;
  }

  async function handleSubmitAsync() {
    const isSubmitValid = validateFields();
    if (!isSubmitValid) {
      return;
    }

    if (oAuthIdToken) {
      const createBusinessUser: ICreateUserIdp = {
        firstName: inputs.firstName,
        lastName: inputs.lastName,
        phoneNumber: inputs.phoneNumber
      };

      setIsLoading(true);
      const { data, error } = await authOperations.signUpWithIdpAsync(
        createBusinessUser,
        oAuthIdToken
      );

      if (!data || error) {
        setIsLoading(false);
        errorToast("Sorry, we couldn't sign you up. Please try again later.");
        return;
      }

      const { data: user, error: userError } =
        await userOperations.getUserAsync();

      if (!user || userError) {
        setIsLoading(false);
        errorToast('Error fetching user, please try again');
        return;
      }

      setIsLoading(false);
      setToken(data);
      setUser(user);
    } else {
      const didSignUpSucceed = await handleSignUpAsync();
      if (didSignUpSucceed) {
        resetState();
        navigate(PuffinRoutes.AccountCreated);
      }
    }
  }

  function handleChange(e: InputFormEvent) {
    const { name, value } = e.target as any;
    setInputs((prev: any) => ({ ...prev, [name]: value }));
  }

  function handleChangePassword(e: InputFormEvent) {
    const { name, value } = e.target as any;
    setInputs((prev: any) => ({ ...prev, [name]: value }));
    setIsPasswordMatch(value === inputs.confirmPassword);
  }

  function handleConfirmPassword(e: InputFormEvent) {
    const { name, value } = e.target as any;
    setInputs((prev: any) => ({ ...prev, [name]: value }));
    setIsPasswordMatch(inputs.password === value);
  }

  function handleChangeNameFields(value: string, fieldName: string) {
    const isNameValid = Validator.isAlphaNumericRegex(value);
    if (!(isNameValid || value === '')) {
      return;
    }

    setInputs((prev) => ({
      ...prev,
      [fieldName]: value
    }));
  }

  function renderError(fieldName: string, formErrors: FormError[]) {
    const errorMessage = formErrors.find(
      (fe) => fe.field === fieldName
    )?.message;
    return errorMessage ?? false;
  }

  function displayError(field: string) {
    const errorMessage = formErrors.find((fe) => fe.field === field)?.message;
    return (
      <div className="text-sm -mt-1 mb-1 text-red heading-semibold-sm">
        {errorMessage}
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-pageColour h-screen max-h-screen overflow-y-auto">
      <div
        className="w-full bg-white flex justify-between items-center px-8"
        style={{
          height: '72px',
          borderBottomWidth: '1px',
          borderColor: 'outline'
        }}
      >
        <MdClose
          size={16}
          className="cursor-pointer"
          onClick={() => navigate('/')}
        />
        <h2 className="leading-44 heading-semibold-l text-primaryDark">
          Create Account
        </h2>
        <Button
          size="lg"
          type="submit"
          isLoading={isLoading}
          skin={'transparent'}
          onClick={async () => await handleSubmitAsync()}
          className="flex flex-row justify-center font-nunito items-center w-auto px-6 py-3 mr-auto bg-primary cursor-pointer"
        >
          <label
            className="heading-semibold-av text-white cursor-pointer"
            style={{ lineHeight: '20px' }}
          >
            Create
          </label>
        </Button>
      </div>
      <div className="flex-col px-36 w-1/2 items-center justify-center ml-auto mr-auto">
        <div
          className="mt-14 text-lg font-inter font-normal text-onSurfaceVariant"
          style={{ lineHeight: '22px' }}
        >
          <div>
            Welcome to Ember Business, where you can manage your business
            profile. Please note, each company is only allowed one account.
          </div>
          <div className="mt-2">
            Please provide some additional details to finish setting up your
            account. These details may also be used for future correspondence if
            needed
          </div>
        </div>
        <div className="mt-5" style={{ width: '100%' }}>
          <Form>
            <Input
              label="Email Address"
              labelColor="text-onSurface"
              placeholder="bindystreet@outlook.com"
              error={
                !Validator.isEmailValid(inputs.email) &&
                renderError('email', formErrors)
              }
              onChange={handleChange}
              name="email"
              value={inputs.email}
            />

            <Input
              label="Contact Number"
              labelColor="text-onSurface"
              placeholder="Enter Number"
              onChange={handleChange}
              error={
                !Validator.isPhoneNumberValid(inputs.phoneNumber) &&
                renderError('contact', formErrors)
              }
              name="phoneNumber"
              value={inputs.phoneNumber}
            />

            <div className="flex flex-row justify-between">
              <div style={{ width: '48%' }}>
                <Input
                  label="First Name"
                  labelColor="text-onSurface"
                  placeholder="Enter First Name"
                  onChange={(e) =>
                    handleChangeNameFields(e.currentTarget.value, 'firstName')
                  }
                  error={
                    !inputs.firstName && renderError('firstName', formErrors)
                  }
                  name="firstName"
                  value={inputs.firstName}
                />
              </div>
              <div style={{ width: '48%' }}>
                <Input
                  label="Last Name"
                  labelColor="text-onSurface"
                  placeholder="Enter Last Name"
                  onChange={(e) =>
                    handleChangeNameFields(e.currentTarget.value, 'lastName')
                  }
                  name="lastName"
                  error={
                    !inputs.lastName && renderError('lastName', formErrors)
                  }
                  value={inputs.lastName}
                />
              </div>
            </div>

            <Input
              label="Password"
              labelColor="text-onSurface"
              labelHint="At least 8 characters, with at least 1 number and 1 special character"
              placeholder="Type Password"
              onChange={handleChangePassword}
              error={
                (inputs.password.length > 0 &&
                  !Validator.isPasswordValid(inputs.password) &&
                  `Your password doesn't meet our requirements`) ||
                (!inputs.password &&
                  !Validator.isPasswordValid(inputs.password) &&
                  renderError('password', formErrors))
              }
              showErrorText={false}
              name="password"
              type="password"
              value={inputs.password}
            />

            {!inputs.password && (
              <div>
                {!Validator.isPasswordValid(inputs.password) && (
                  <div>
                    {formErrors.some((error) => error.field === 'password') && (
                      <div className="text-sm text-red">
                        {displayError('password')}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div>
              {!Validator.isPasswordValid(inputs.password) &&
                inputs.password && (
                  <div>
                    <h2 className=" text-red heading-semibold-sm">
                      Your password doesn't meet our requirements
                    </h2>
                  </div>
                )}
            </div>

            <Input
              label="Retype Password"
              labelColor="text-onSurface"
              placeholder="Retype Password"
              onChange={handleConfirmPassword}
              error={
                !inputs.confirmPassword &&
                renderError('confirmPassword', formErrors)
              }
              name="confirmPassword"
              type="password"
              value={inputs.confirmPassword}
            />

            {!isPasswordMatch &&
              inputs.confirmPassword &&
              inputs.confirmPassword.length > 0 &&
              Validator.isPasswordValid(inputs.password) && (
                <div>
                  <h2 className="text-red heading-semibold-sm">
                    Passwords do not match, please try again
                  </h2>
                </div>
              )}

            <div className="flex flex-col">
              <div className="text-sm mt-3 flex flex-row items-center text-primaryCharcoal font-nunito">
                <Checkbox
                  onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                  unselectedBorderColour={
                    !isTermsAccepted &&
                    formErrors.some((error) => error.field === 'terms')
                      ? 'red'
                      : 'onSurfaceVariant'
                  }
                  selectedBorderColour="onSurfaceVariant"
                  checked={isTermsAccepted}
                  selectedBackgroundColour="onSurfaceVariant"
                  selectedColour="white"
                />
                <span className="ml-2 text-onSurface heading-regular-sm">
                  Terms of Service & Privacy Policy
                </span>
              </div>

              <div>
                <span className="ml-9 mt-1 text-onSurfaceVariant utility-regular-errorMessage">
                  I have read and accepted ember's{' '}
                  <a
                    href="https://www.ember.london/terms-of-service"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary text-sm font-bold font-nunito underline cursor-pointer"
                  >
                    Terms of service{' '}
                  </a>
                  and{' '}
                  <a
                    href="https://www.ember.london/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary text-sm font-bold font-nunito underline cursor-pointer"
                  >
                    Privacy policy
                  </a>
                </span>
              </div>
            </div>

            {!isTermsAccepted && (
              <div className="mt-3">
                {formErrors.some((error) => error.field === 'terms') && (
                  <div className="error-container">{displayError('terms')}</div>
                )}
              </div>
            )}

            {errors?.length > 0
              ? errors.map((error: string, i: number) => (
                  <p key={i} className="heading-semibold-sm text-red mt-2">
                    {error}
                  </p>
                ))
              : null}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
