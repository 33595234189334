import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { APP_ENV } from './constants/constants';

export function getFirebaseConfig() {
  let firebaseConfig: FirebaseOptions;
  switch (APP_ENV) {
    case 'DEVELOPMENT':
      firebaseConfig = {
        apiKey: 'AIzaSyC7keAV5b4E_bPC-5rK63P2wX9fEshFHeE',
        authDomain: 'puffin-dev-68473.firebaseapp.com',
        projectId: 'puffin-dev-68473',
        storageBucket: 'puffin-dev-68473.appspot.com',
        messagingSenderId: '789101344599',
        appId: '1:789101344599:web:d73a01ea239805e1193012',
        measurementId: 'G-T7SP77EE89'
      };
      break;
    case 'STAGING':
      firebaseConfig = {
        apiKey: 'AIzaSyBfl4OxukC8S9c3t3U7OKGqacE2JT7d-4Y',
        authDomain: 'puffinstaging.firebaseapp.com',
        projectId: 'puffinstaging',
        storageBucket: 'puffinstaging.appspot.com',
        messagingSenderId: '686699122389',
        appId: '1:686699122389:web:5e7390acceec8e7034dc5c',
        measurementId: 'G-23BGZYS3GW'
      };
      return firebaseConfig;
    case 'PRODUCTION':
      firebaseConfig = {
        apiKey: 'AIzaSyBsGoElfzftH2g0rJIYNh-s5xzyQERTcys',
        authDomain: 'puffin-production.firebaseapp.com',
        projectId: 'puffin-production',
        storageBucket: 'puffin-production.appspot.com',
        messagingSenderId: '34575775610',
        appId: '1:34575775610:web:439682135e69568b9888c3',
        measurementId: 'G-B0RERSLZCN'
      };
      return firebaseConfig;
    default:
      throw new Error(
        `Unknown APP_ENV: ${APP_ENV}. Please provide a valid environment.`
      );
  }
  return firebaseConfig;
}

const firebaseApp = initializeApp(getFirebaseConfig());
const firebaseAuth = getAuth(firebaseApp);

export { firebaseApp, firebaseAuth };
