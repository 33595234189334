import { IUser } from 'Colugo/interfaces/identity';
import { PuffinRoutes } from 'Colugo/interfaces/routes/puffin';
import { useReqGetUser } from 'Colugo/operations/identity';
import DashboardHeader from 'components/shared/DashboardHeader';
import { AuthContext } from 'provider/auth/authProvider';
import { UserContext } from 'provider/user/userProvider';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import * as firebaseAnalytics from 'utility/analytics/firebaseAnalytics';
import BusinessSidebar from './dashboard/BusinessSidebar';

const hideSidebarScreens = [
  PuffinRoutes.SubmitClaim,
  PuffinRoutes.SubmitBusinessRequest,
  PuffinRoutes.ManagerCreateEvent,
  PuffinRoutes.PaymentSuccess,
  PuffinRoutes.PaymentCanceled
];

function Layout() {
  const { handleSignout, token, setToken } = useContext(AuthContext);
  const { setUser } = useContext(UserContext);
  const { data: currentUser } = useReqGetUser(!!token);
  const navigate = useNavigate();
  const location = useLocation();

  const [signOut, setSignout] = useState<{
    toSignOut: boolean;
    message: string | undefined;
  }>({ toSignOut: false, message: undefined });
  const { isError } = useReqGetUser(!!token);

  useEffect(() => {
    if (signOut.toSignOut) {
      handleSignout(signOut.message);
    }
    if (!token) {
      navigate('/signin');
    }
  }, [handleSignout, signOut, navigate, token]);

  if (!token) {
    navigate('/signin');
  }

  if (isError) {
    const message =
      'Unable to sign you in due to error retrieving your profile.';
    console.info('Refresh token has been wiped, resetting status');

    !signOut.toSignOut &&
      setSignout({
        toSignOut: true,
        message
      });
    return <></>;
  }

  async function handleOnSignOutAsync(currentUser: IUser) {
    const isSignOutSuccessful = handleSignout();
    if (isSignOutSuccessful) {
      setUser(undefined);
      setToken(null);
      firebaseAnalytics.signOutEvent(currentUser.email);
      navigate('/');
    }
  }

  if (
    hideSidebarScreens.includes(location.pathname as PuffinRoutes) ||
    location.pathname.includes('/manage-events/event') ||
    location.pathname.includes('/update-business-plan') ||
    location.pathname.includes('/plan-payment-type') ||
    location.pathname.includes('/plan-order-summary') ||
    location.pathname.includes('/payment-success') ||
    location.pathname.includes('/payment-canceled')
  ) {
    return <Outlet />;
  }

  return (
    <div
      className="flex flex-row bg-theme1 h-screen overflow-hidden"
      style={{ minHeight: '100vh' }}
    >
      <BusinessSidebar
        onClickSignout={async () => handleOnSignOutAsync(currentUser!)}
      />
      <DashboardHeader />
      <div
        className="flex justify-center w-full h-auto"
        style={{ marginTop: '58px' }}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
