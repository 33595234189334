import { IAnalyticField } from 'Colugo/interfaces/common/IAnalyticField';
import { AnalyticMetricSkeleton } from 'components/business/dashboard/AnalyticMetricSkeleton';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { LuRefreshCw } from 'react-icons/lu';

export const dateRangeFlyoutMenuItems = [
  { label: '7 Days', value: '7', xSpaceBetween: 892 },
  { label: '14 Days', value: '14', xSpaceBetween: 836 },
  { label: '30 Days', value: '30', xSpaceBetween: 806 },
  { label: '2 Months', value: '60', xSpaceBetween: 793 },
  { label: '6 Months', value: '183', xSpaceBetween: 784 }
];

export function getUpdateDiff(timeComputed: number | undefined) {
  const fetchDate = new Date(timeComputed || '');

  const now = new Date();
  const diffInMilliseconds = now.getTime() - fetchDate.getTime();
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  if (diffInMinutes > 1440) {
    const diffInDays = Math.floor(diffInMinutes / 1440);
    return !isNaN(diffInDays)
      ? `Updated ${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`
      : '';
  }
  if (diffInMinutes > 59) {
    const diffInHours = Math.floor(diffInMinutes / 60);
    return !isNaN(diffInHours)
      ? `Updated ${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`
      : '';
  }
  return !isNaN(diffInMinutes)
    ? `Updated ${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`
    : '';
}

export function getFromDateString(nOfDays: number) {
  return new Date(
    Date.now() - nOfDays * 24 * 60 * 60 * 1000
  ).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
}

export function renderDelta(delta: number | undefined) {
  delta = delta || 0;
  const deltaString = delta === 0 ? 'N/A' : formatNumber(100 * delta) + '%';
  return (
    <div
      className={`flex flex-row ${delta > 0 && 'text-success'} ${
        delta < 0 && 'text-error1'
      }`}
    >
      {delta > 0 && <BiSolidUpArrow size={10} className="mt-1.5" />}
      {delta < 0 && <BiSolidDownArrow size={10} className="mt-1.5" />}
      <span
        className={`ml-1 text-sm font-bold ${
          delta === 0 ? 'text-onSurfaceVariant' : ''
        } `}
      >
        {deltaString}
      </span>
    </div>
  );
}

export function formatNumber(value: number): number | string {
  return Number.isInteger(value) ? value : value.toFixed(1);
}

export function renderMetric(
  name: string,
  analyticField: IAnalyticField | undefined,
  nOfDays: number,
  width: string,
  isPercentage: boolean = false,
  isLoading: boolean = false
) {
  if (isLoading) {
    return <AnalyticMetricSkeleton width={130} className={'p-2'} />;
  }
  return (
    <div className="flex flex-col px-4 font-inter" style={{ width: width }}>
      <span className="text-onSurfaceVariant font-semibold text-lg">
        {name}
      </span>

      <div className="flex flex-row mt-12">
        <span className="text-4xl font-bold">
          {(analyticField?.count ? analyticField.count.toString() : '0') +
            (isPercentage ? '%' : '')}
        </span>
        <div className="flex-grow" />
        {!!analyticField?.delta ? (
          <div className="flex flex-col">
            {renderDelta(analyticField?.delta)}
            <span className="font-normal text-sm text-onSurfaceVariant">
              From {getFromDateString(nOfDays)}
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

const verticalDivider = <div className="border-r border-grayBorder my-2" />;

export const analyticsErrorComponent = (
  <div className="flex flex-row bg-white border rounded-xl border-grayBorder mt-6 px-6 py-8">
    <div className="flex flex-row">
      <div className="px-6 py-8" style={{ width: '190px' }} />
      {verticalDivider}
      <div className="px-6 py-8" style={{ width: '190px' }} />
      {verticalDivider}
      <div className="px-6 py-8" style={{ width: '190px' }} />
      {verticalDivider}
      <div className="px-6 py-8" style={{ width: '190px' }} />
    </div>
  </div>
);

export const analyticsSnapshotSkeleton = (
  <div className="flex flex-row bg-white border rounded-xl border-grayBorder mt-6">
    <div className="flex flex-row">
      <AnalyticMetricSkeleton width={190} className="px-6 py-8" />
      {verticalDivider}
      <AnalyticMetricSkeleton width={190} className="px-6 py-8" />
      {verticalDivider}
      <AnalyticMetricSkeleton width={190} className="px-6 py-8" />
      {verticalDivider}
      <AnalyticMetricSkeleton width={190} className="px-6 py-8" />
    </div>
  </div>
);

export function renderRetryAnalyticsComponent(
  revalidateAnalyticsAsync: () => Promise<boolean>
) {
  return (
    <div className="flex flex-row gap-2 items-center">
      <span className="text-onSurfaceVariant heading-regular-sm">•</span>
      <div
        className="text-onSurface text-sm font-normal leading-16 flex flex-row items-center gap-1 cursor-pointer"
        onClick={async () => await revalidateAnalyticsAsync()}
      >
        Refresh
        <LuRefreshCw size={12} />
      </div>
    </div>
  );
}
