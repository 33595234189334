import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploaderPlaceholder } from 'images/shared/uploaderPlaceholder.svg';
import { ProgressBar } from '@bindystreet/bindystreet.kit.react';
import { IVideo } from 'Colugo/interfaces/video/IVideo';
import { FaEraser } from 'react-icons/fa';
import { ReactComponent as DownloadIcon } from 'images/dashboard/download.svg';
import { useErrorToast } from 'utility/hooks/useErrorToast';

interface VideoFile {
  name: string;
  preview: string;
}

type Props = {
  onUploadComplete: (video: File) => void;
  isLoading: boolean;
  video: IVideo | undefined;
  videoName: string;
  progress: number;
  onClickDelete: () => void;
};
function VideoUploaderInput(props: Props) {
  const {
    onUploadComplete,
    progress,
    isLoading,
    video,
    onClickDelete,
    videoName
  } = props;
  const { errorToast } = useErrorToast();
  const [selectedVideoFile, setSelectedVideoFile] = useState<VideoFile | null>(
    null
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const videoFile = acceptedFiles.find((file) =>
        file.type.startsWith('video/')
      );
      if (videoFile) {
        const newFileName = (
          videoFile.name.endsWith('.MP4')
            ? videoFile.name.replace('.MP4', '.mp4')
            : videoFile.name
        ).replace(' ', '');

        const updatedVideoFile = new File([videoFile], newFileName, {
          type: videoFile.type,
          lastModified: videoFile.lastModified
        });
        setSelectedVideoFile({
          name: newFileName,
          preview: URL.createObjectURL(updatedVideoFile)
        });

        onUploadComplete(updatedVideoFile);
      }
    },
    [onUploadComplete]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected(fileRejections) {
      if (fileRejections[0].file.type !== 'video/mp4') {
        errorToast('Only MP4 files are accepted.');
      }
    },
    accept: ['.mp4'],
    multiple: false
  });

  async function downloadVideo() {
    if (!video?.url) {
      return;
    }

    const videoFileName = videoName + '.mp4';
    try {
      const response = await fetch(video.url);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = videoFileName.replace(/\s+/g, '-');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      errorToast('Failed to download video, please try again');
    }
  }
  return (
    <div>
      {!!video && video.url ? (
        <div>
          <div
            className="flex relative rounded-lg bg-blue"
            style={{
              width: '192px',
              height: '342px',
              backgroundColor: 'rgba(230, 230, 230, 1)'
            }}
          >
            <video
              src={selectedVideoFile?.preview ?? video.url}
              controls
              className="rounded-lg"
              style={{
                width: '192px',
                height: '342px',
                backgroundColor: 'rgba(230, 230, 230, 1)'
              }}
            />
            <div
              className="absolute top-2 right-2 border border-outline  bg-white rounded-md shadow-lg cursor-pointer h-9 w-9 flex justify-center items-center"
              onClick={onClickDelete}
            >
              <FaEraser className="flex-grow text-primary mx-auto my-auto h-5 w-5" />
            </div>

            <div
              className="absolute top-14 right-2 bg-white h-9 w-9 flex justify-center items-center rounded-md cursor-pointer"
              onClick={async () => await downloadVideo()}
            >
              <DownloadIcon />
            </div>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`flex flex-col items-center justify-center rounded-lg border-2 ${
            isDragActive ? 'border-primaryTeal' : 'border-onSurface '
          }`}
          style={{
            width: '192px',
            height: '342px',
            backgroundColor: 'rgba(230, 230, 230, 1)'
          }}
        >
          <input {...getInputProps()} />

          {isDragActive ? (
            <p className="text-primaryTeal">Drop the video here...</p>
          ) : (
            <div className="flex flex-col items-center justify-center w-full">
              <div className="w-full">
                {isLoading && progress ? (
                  <div className="flex flex-col items-center justify-center mx-3">
                    <ProgressBar percent={progress / 100} />
                    <div>Uploading...</div>
                  </div>
                ) : (
                  <div className="flex-col flex w-full justify-center items-center">
                    <UploaderPlaceholder />
                    <p
                      className="font-inter text-xs font-bold leading-44"
                      style={{ color: '#000' }}
                    >
                      Upload Video
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default VideoUploaderInput;
