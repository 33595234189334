import { IListing } from '../listing/IListing';

export interface ISubscription {
  subscriptionId?: string;
  listing: IListing;
  subscriptionType?: SubscriptionType;
  paymentProvider?: PaymentProvider;
  isActive?: boolean;
  customerId?: string;
  priceId?: string;
}

export enum SubscriptionType {
  Essential = 'Essential',
  Plus = 'Plus',
  Premium = 'Premium'
}

enum PaymentProvider {
  None,
  Stripe
}
