import { ICreateListingsForCsvUpload } from 'Colugo/interfaces/listing/ICreateListingsForCsvUpload';
import {
  IListing,
  IListingFromSearch
} from 'Colugo/interfaces/listing/IListing';
import { NameLocationResult } from 'Colugo/interfaces/NameLocationResult';
import { requestResponse } from 'Colugo/provider/HttpClient';
import { Lifecycle, scoped } from 'tsyringe';
import {
  swrRequestResponse,
  useGetHttpClient
} from '../../provider/useGetHttpClient';
import AbstractOperations from '../AbstractOperations';
import { stripVideoEntities } from 'Colugo/operations/utilities/operationsUtils';

@scoped(Lifecycle.ContainerScoped)
class ListingOperations extends AbstractOperations {
  protected basePath: string = 'listing';

  public async createAsync(listing: IListing): requestResponse<IListing> {
    return await this.executePost(``, listing);
  }

  public async createForCsvUploadAsync(listings: IListing[]): requestResponse {
    const createEventsForCsvUpload: ICreateListingsForCsvUpload = {
      listings: listings
    };
    return await this.executePost(`csvUpload`, createEventsForCsvUpload);
  }

  public async getAsync(listingId: string): requestResponse<IListing> {
    const response = await this.executeGet(`${listingId}?isActive=false`);
    if (response.data) {
      stripVideoEntities(response.data as IListing);
    }
    return response;
  }

  public async updateAsync(listing: IListing): requestResponse {
    return await this.executePut(`${listing.id}`, listing, {}, true);
  }

  public async activateAsync(listingId: string): requestResponse {
    return await this.executePost(`activate/${listingId}`);
  }

  public async inactivateAsync(listingId: string): requestResponse {
    return await this.executeDelete(`inactivate/${listingId}`);
  }

  public async reindexAllAsync(secretValue: string): requestResponse {
    return await this.executePost(`reindexAll?code=${secretValue}`);
  }

  public async updateTagPriorityAsync(
    listingId: string,
    tagId: string,
    isPriority: boolean
  ): requestResponse {
    return await this.executePut(
      `${listingId}/tag/${tagId}?isPriority=${isPriority}`
    );
  }

  public async addCategoryAsync(
    listingId: string,
    categoryId: string
  ): requestResponse {
    return await this.executePost(`${listingId}/category/${categoryId}`);
  }

  public async removeCategoryAsync(
    listingId: string,
    categoryId: string
  ): requestResponse {
    return await this.executeDelete(`${listingId}/category/${categoryId}`);
  }

  public async checkNameLocationAsync(
    listing: IListing
  ): requestResponse<NameLocationResult> {
    return await this.executePost(`checkNameLocation`, listing);
  }

  public async searchListingsAsync(
    query?: string,
    take?: number
  ): requestResponse<IListingFromSearch[]> {
    const escapedQuery = encodeURIComponent(query || '');
    return await this.executeGet(
      `search?query=${escapedQuery}&take=${take ?? 10}`
    );
  }

  public async addTagAsync(listingId: string, tagId: string): requestResponse {
    return await this.executePost(`${listingId}/tag/${tagId}`);
  }

  public async removeTagAsync(
    listingId: string,
    tagId: string
  ): requestResponse {
    return await this.executeDelete(`${listingId}/tag/${tagId}`);
  }

  public async deleteAsync(listingId: string): requestResponse {
    return await this.executeDelete(`${listingId}`);
  }
}

const useReqListListingsAsync = (): swrRequestResponse<IListing[]> => {
  return useGetHttpClient(
    'listing/recentlyUpdated',
    {
      revalidateOnFocus: true
    },
    false
  );
};

const useReqListListingsForUserAsync = (
  userId?: string
): swrRequestResponse<IListing[]> => {
  return useGetHttpClient(
    userId ? `listing/listForUser/${userId}` : null,
    {
      revalidateOnFocus: true
    },
    false
  );
};

const useReqGetListingAsync = (
  listingId?: string
): swrRequestResponse<IListing> => {
  const response = useGetHttpClient(
    listingId ? `listing/${listingId}` : null,
    {
      revalidateOnFocus: true
    },
    false
  );
  if (response.data) {
    stripVideoEntities(response.data);
  }
  return response;
};

export default ListingOperations;
export {
  useReqGetListingAsync,
  useReqListListingsAsync,
  useReqListListingsForUserAsync
};
