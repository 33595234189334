import { ErrorPage, Spinner } from '@bindystreet/bindystreet.kit.react';
import {
  ClaimRequestStatus,
  IBaseListingRequest
} from 'Colugo/interfaces/business/IBaseListingRequest';
import AcceptedRejectedCard from 'components/shared/AcceptedRejectedCard';
import EmptyCard from 'components/shared/EmptyCard';
import { useState } from 'react';
import { defaultDate } from 'utility/constants/constants';
import { ManageAccountSection } from './ListingClaimsOrRequests';
import ProfileModal from './ProfileModal';
import { IUser } from 'Colugo/interfaces/identity';

type Props<T> = {
  manageAccountSection?: ManageAccountSection;
  requests: T[] | undefined;
  isLoadingRequests?: boolean;
  isError?: boolean | undefined;
};

function AcceptedRejectedRequests<T extends IBaseListingRequest>(
  props: Props<T>
) {
  const { manageAccountSection, requests, isLoadingRequests, isError } = props;
  const [businessUser, setBusinessUser] = useState<IUser | undefined>(
    undefined
  );

  if (isLoadingRequests) {
    return (
      <div className="flex w-full h-screen flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <ErrorPage>
        <span>{`Unable to load  ${
          manageAccountSection === ManageAccountSection.BusinessClaims
            ? 'business claims'
            : 'business requests'
        } from server.`}</span>
      </ErrorPage>
    );
  }

  const sortedRequests = requests?.sort((a, b) => {
    const dateA = new Date(a.createdAt ?? defaultDate.toISOString());
    const dateB = new Date(b.createdAt ?? defaultDate.toISOString());
    return dateA.getTime() - dateB.getTime();
  });

  return (
    <>
      {sortedRequests ? (
        sortedRequests.map((sortedRequest, i) => (
          <div className="flex flex-col w-full mb-4" key={sortedRequest.id}>
            <AcceptedRejectedCard
              isAccepted={sortedRequest.status === ClaimRequestStatus.Approved}
              request={sortedRequest}
              onClickBusinessAccount={(request) => {
                setBusinessUser(request.user);
              }}
            />
          </div>
        ))
      ) : (
        <EmptyCard
          text={
            manageAccountSection === ManageAccountSection.BusinessClaims
              ? 'No Business Claims'
              : 'No Business Requests'
          }
        />
      )}
      <div>
        {!!businessUser && (
          <ProfileModal
            businessUser={businessUser}
            onCloseModal={() => setBusinessUser(undefined)}
          />
        )}
      </div>
    </>
  );
}

export default AcceptedRejectedRequests;
