import { IVideo } from 'Colugo/interfaces/video/IVideo';

export function stripVideoEntities(data: any): void {
  if ('video' in data) {
    const video = data.video as IVideo;
    if (video && video.entities) {
      delete video.entities;
    }
  }
}
