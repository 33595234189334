import { SubscriptionType } from 'Colugo/interfaces/subscriptions/ISubscription';
import { EmberPlanDetails } from 'components/business/dashboard/UpdateBusinessPlan';
import { getEmailLink } from 'utility/constants/constants';

type Props = {
  plan: EmberPlanDetails;
  isSelected?: boolean;
  subscriptionType: SubscriptionType;
  onClickButton: (key: SubscriptionType) => void;
};

function EmberPlanBox({
  plan,
  isSelected,
  subscriptionType,
  onClickButton
}: Props) {
  const { title, price, attributes, key } = plan;
  let titleColor: string;
  let btnPlanTxt: string;
  let btnClassname: string;
  const selectedBtnClass = 'bg-surfaceContainerLow border border-outline';

  function onOpenEmail() {
    window.location.href = getEmailLink('');
  }

  switch (key) {
    case SubscriptionType.Essential:
      titleColor = 'text-onSurfaceVariant';
      btnClassname = isSelected
        ? selectedBtnClass
        : 'bg-onSurfaceVariant text-white';
      btnPlanTxt = 'Essential';
      break;
    case SubscriptionType.Plus:
      titleColor = 'text-blue';
      btnClassname = isSelected ? selectedBtnClass : 'bg-blue text-white';
      btnPlanTxt = 'Plus';
      break;
    case SubscriptionType.Premium:
      titleColor = 'text-navyLight';
      btnClassname = isSelected ? selectedBtnClass : 'bg-navyLight text-white';
      btnPlanTxt = 'Premium';
      break;
    default:
      titleColor = 'text-onSurfaceVariant';
      btnClassname = isSelected
        ? selectedBtnClass
        : 'bg-onSurfaceVariant text-white';
      btnPlanTxt = 'Essential';
      break;
  }

  function getButtonText() {
    if (isSelected) {
      return 'Currently Selected';
    }

    if (
      subscriptionType !== SubscriptionType.Essential &&
      key !== subscriptionType
    ) {
      return 'Contact us';
    }

    if (key > subscriptionType) {
      return 'Upgrade';
    }
    if (key < subscriptionType) {
      return `Downgrade to ${btnPlanTxt}`;
    }
  }

  function onClickUpgradeOrDowngradeButton(key: SubscriptionType) {
    if (
      subscriptionType !== SubscriptionType.Essential &&
      key !== subscriptionType
    ) {
      onOpenEmail();
    } else {
      onClickButton(key);
    }
  }

  return (
    <div
      className="p-8 border border-outline rounded-3xl bg-white flex-1 relative "
      style={{ width: '431px', height: '770px' }}
    >
      <div
        style={{ height: '190px' }}
        className="border-b border-outline flex-col text-center"
      >
        <div>
          <p
            className={`font-semibold ${titleColor}`}
            style={{ fontSize: '28px' }}
          >
            {title}
          </p>
        </div>
        <div className="flex flex-row justify-center mt-6">
          <p className="text-onSurface text-5xl font-semibold">{price}</p>
          {key !== SubscriptionType.Essential && (
            <p
              className="text-onSurface font-semibold ml-2"
              style={{ fontSize: '32px' }}
            >
              / mo
            </p>
          )}
        </div>
        {key !== SubscriptionType.Essential && (
          <div className="mt-3">
            {<p className="text-2xl">Billed Montly</p>}
          </div>
        )}
      </div>
      <div
        className="flex flex-col justify-between h-full relative"
        style={{ height: '525px' }}
      >
        <div className="mt-10 gap-4 flex flex-col" style={{ height: '344px' }}>
          {attributes.map((at, i) => (
            <div className="flex flex-row items-start gap-4" key={i}>
              <span className="h-4 w-4 rounded-full border border-outline bg-transparent mt-1 flex-shrink-0" />
              <p
                className="text-lg text-onSurface font-normal"
                style={{ lineHeight: '24px' }}
              >
                {at}
              </p>
            </div>
          ))}
        </div>
        <div>
          <button
            className={`${btnClassname} rounded-lg w-full ${
              subscriptionType !== SubscriptionType.Essential && isSelected
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            style={{ height: '52px' }}
            onClick={() => onClickUpgradeOrDowngradeButton(key)}
            disabled={isSelected}
          >
            {getButtonText()}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmberPlanBox;
