import DashboardHeader from 'components/shared/DashboardHeader';
import { ReactComponent as PaymentSuccessIcon } from 'images/paymentSuccess.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';

function PaymentSuccess() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const subscriptionType = searchParams.get('subscriptionType');

  return (
    <div className="bg-theme1 h-screen w-screen pt-44">
      <DashboardHeader isDropdownVisible={false} />
      <div className="flex-col justify-center items-center mx-auto text-center max-w-max">
        <PaymentSuccessIcon className="w-64 mx-auto" />
        <h3 className="font-inter font-bold text-lg">Payment Successful!</h3>
        <p className="text-onSurfaceVariant font-inter font-semibold text-lg mt-2 mx-auto">
          Congratulations! You've upgraded to Ember {subscriptionType}.
        </p>
        <button
          className="bg-primary text-white rounded-lg px-8 py-2 text-base mt-4"
          onClick={() => navigate('/')}
        >
          Return home
        </button>
      </div>
    </div>
  );
}

export default PaymentSuccess;
